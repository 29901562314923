import { Button, Descriptions, Select } from "antd";
import { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { API } from "../../api-types";
import { AuthContextLoggedIn, Authenticated } from "../../auth";
import { formatDateLocal, usePromiseExtended, useQuery } from "../../util";
import { SectionCreator } from "../misc/section-creator";
import { StudentSearch } from "../misc/student-search";
import { useModals } from "../modal/modals-provider";
import TextArea from "antd/lib/input/TextArea";

export function NewEnrollmentPage(props: { auth: AuthContextLoggedIn }) {
	const history = useHistory();
	const query = useQuery();
	const modals = useModals();
	const [enrollmentId, setEnrollmentId] = useState<string | null>(null);
	const [paymentId, setPaymentId] = useState<string>();
	const [startDate, setStartDate] = useState<string>("");
	const [remark, setRemark] = useState<string>("");

	const sectionid = query.get("section");
	const studentid = query.get("student");
	const credits = query.get("credits");

	const [section, sectionR] = usePromiseExtended(
		useCallback(async () => {
			if (sectionid === null) return undefined;

			const got = await API.Branch.Section.Get(props.auth, { uuid: sectionid });
			const startdate = got.section_sessions.find(x => new Date(x.datetime) > new Date());

			setStartDate(
				startdate
					? startdate.datetime
					: got.section_sessions.length > 0
					? got.section_sessions[0].datetime
					: new Date().toISOString(),
			);
			return got;
		}, [sectionid, props.auth]),
	);

	const [student, studentR] = usePromiseExtended<{ name: string; uuid: string; phone: string }>(
		useCallback(async () => {
			if (studentid == null) return;

			return await API.Student.GetBasicInfo(props.auth, { uuid: studentid });
		}, [studentid, props.auth]),
	);

	const enrollSessions = section && section.section_sessions.filter(x => new Date(x.datetime) >= new Date(startDate));

	return (
		<Authenticated as="reception">
			<div className="content-flex-horizontal">
				<StudentSearch onSelect={e => studentR.set(e)} disabled={enrollmentId !== null} auth={props.auth} />
				{student && (
					<div className="subcontent-flex-vertical" style={{ width: 320 }}>
						<div>Selected student: {student.name}</div>
						{!section && (
							<SectionCreator
								auth={props.auth}
								creditsAvailable={credits !== null ? +credits : undefined}
								onCreateSection={s => {
									sectionR.set(s);
									setStartDate(
										s.section_sessions.length > 0
											? s.section_sessions[0].datetime
											: new Date().toISOString(),
									);
								}}
							/>
						)}
						{section && (
							<>
								<div style={{ border: "1px solid black", padding: 16 }}>
									<Descriptions title="Section Information" bordered column={1} size="small">
										<Descriptions.Item label="Course">{section.course.name}</Descriptions.Item>
										<Descriptions.Item label="Classroom">
											{section.classroom.name}
										</Descriptions.Item>
										<Descriptions.Item label="Duration">
											{new Date(section.start_datetime).toLocaleDateString()}-
											{new Date(section.end_datetime).toLocaleDateString()}
										</Descriptions.Item>
									</Descriptions>
									<br />
									{new Date() > new Date(section.start_datetime) && (
										<span style={{ color: "red" }}>Warning: this section has already started.</span>
									)}
								</div>
								<div>
									Enrollment starting date:
									<Select
										style={{ width: "100%" }}
										value={startDate}
										options={section.section_sessions.map(x => ({
											label: formatDateLocal(new Date(x.datetime)),
											value: new Date(x.datetime).toISOString(),
										}))}
										onChange={e => setStartDate(e)}
									/>
								</div>
								<div>
									Enrollment notes to teacher
									<TextArea rows={3} value={remark} onChange={e => setRemark(e.target.value)} />
								</div>
								<Button
									type="primary"
									disabled={enrollmentId !== null}
									onClick={async () => {
										if (!enrollSessions) return;

										const created = await API.Student.Enrollment.Create(props.auth, {
											student_uuid: student.uuid,
											section_uuid: section.uuid,
											session_uuids: enrollSessions.map(x => x.uuid),
											branch_uuid: props.auth.currentBranch.uuid,
											comment: remark,
										});
										setEnrollmentId(created.uuid);

										modals.payment({
											auth: props.auth,
											params: {
												quantity: created.student_sessions.length,
												unitPrice: section.course.session_price,
												itemName: `Payment for ${section.course.name}`,
												branch_uuid: section.classroom.branch.uuid,
												student_uuid: student.uuid,
												enrollment_uuid: created.uuid,
												creditUnitValue: Math.round(section.session_duration / 30) / 2,
											},
											onCreated(id: string) {
												setPaymentId(id);
											},
											onCancel() {
												history.push(`/`);
											},
										});
									}}
								>
									Enroll {student.name} in {enrollSessions!.length} sessions
								</Button>
							</>
						)}
					</div>
				)}
			</div>
		</Authenticated>
	);
}
