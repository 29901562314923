import Button from "antd/lib/button";
import { usePromiseExtended } from "../../util";
import { AuthContextLoggedIn } from "../../auth";
import { useCallback, useState } from "react";
import { API } from "../../api-types";
import { EditProgressModal } from "../modal/edit-progress-modal";
import List from "antd/lib/list";
interface Option {
	value: string;
	label: string;
	children?: Option[];
}

export const ProgressItems: Option[] = [
	{
		value: "CCC",
		label: "CCC",
		children: [...Array(30)].map((_, i) => ({
			value: `${1996 + i}`,
			label: `${1996 + i}`,
			children: ["J1", "J2", "J3", "J4", "J5", "S1", "S2", "S3", "S4", "S5"].map(j => ({
				value: j,
				label: j,
			})),
		})),
	},
];

export function ProgressEntriesReport(props: {
	auth: AuthContextLoggedIn;
	session: { uuid: string };
	student: { uuid: string };
	active: boolean;
}) {
	const [entries, entriesR] = usePromiseExtended(
		useCallback(async () => {
			return await API.Student.GetProgress(props.auth, {
				uuid: props.student.uuid,
				student_session_uuid: props.session.uuid,
			});
		}, [props.auth, props.session.uuid, props.student.uuid]),
	);

	const [modalTarget, setModalTarget] = useState<Parameters<typeof EditProgressModal>[0]["target"]>();

	return (
		<>
			<div>
				<Button
					onClick={() => {
						setModalTarget({
							category: "",
							bank: "",
							problem: "",
							student_uuid: props.student.uuid,
							createNew: true,
						});
					}}
				>
					Add
				</Button>
				<span style={{ marginLeft: 20 }}>(This section saves automatically)</span>
			</div>
			<List
				itemLayout="vertical"
				size="small"
				dataSource={entries}
				renderItem={(x, i) => (
					<List.Item
						key={i}
						className="hover-list-item"
						style={{ cursor: "pointer" }}
						onClick={() => {
							setModalTarget({
								category: x.category,
								bank: x.bank,
								problem: x.problem,
								student_uuid: props.student.uuid,
								comment: x.comment,
								passed: x.passed,
								createNew: false,
							});
						}}
					>
						<div>
							{x.category} {x.bank} {x.problem} {x.passed ? "Passed" : "Failed"}{" "}
							{x.comment !== "" && "(+Comment)"}{" "}
						</div>
					</List.Item>
				)}
			/>
			<EditProgressModal
				target={modalTarget}
				onSave={async value => {
					if (modalTarget === undefined) return;

					if (modalTarget.createNew) {
						await API.Student.UpdateProgress(props.auth, {
							uuid: props.student.uuid,
							create: [
								{
									student_session_uuid: props.session.uuid,
									category: value.category,
									bank: value.bank,
									problem: value.problem,
									comment: value.comment,
									passed: value.passed,
								},
							],
						});
					} else {
						await API.Student.UpdateProgress(props.auth, {
							uuid: props.student.uuid,
							update: [
								{
									student_session_uuid: props.session.uuid,
									category: modalTarget.category,
									bank: modalTarget.bank,
									problem: modalTarget.problem,
									new_category: value.category,
									new_bank: value.bank,
									new_problem: value.problem,
									comment: value.comment,
									passed: value.passed,
								},
							],
						});
					}

					entriesR.refresh();
					setModalTarget(undefined);
				}}
				onDelete={async value => {
					if (modalTarget === undefined) return;

					await API.Student.UpdateProgress(props.auth, {
						uuid: props.student.uuid,
						delete: [
							{
								category: value.category,
								bank: value.bank,
								problem: value.problem,
							},
						],
					});

					entriesR.refresh();
					setModalTarget(undefined);
				}}
				onCancel={() => {
					setModalTarget(undefined);
				}}
			/>
		</>
	);
}
