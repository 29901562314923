import { Button, Cascader, Modal } from "antd";
import { useEffect, useState } from "react";
import { ProgressItems } from "../misc/session-report-progress";
import TextArea from "antd/lib/input/TextArea";
import Input from "antd/lib/input/Input";
import Checkbox from "antd/lib/checkbox/Checkbox";

export function EditProgressModal(props: {
	target:
		| {
				student_uuid: string;
				category: string;
				bank: string;
				problem: string;
				comment?: string;
				passed?: boolean;
				createNew: boolean;
		  }
		| undefined;
	onSave: (value: {
		student_uuid: string;
		category: string;
		bank: string;
		problem: string;
		comment: string;
		passed: boolean;
	}) => void;
	onDelete: (value: { student_uuid: string; category: string; bank: string; problem: string }) => void;
	onCancel: () => void;
}) {
	const [values, setValues] = useState(["", "", ""]);
	const [comment, setComment] = useState("");
	const [passed, setPassed] = useState(false);

	useEffect(() => {
		if (!props.target) return;

		setValues([props.target.category, props.target.bank, props.target.problem]);
		setComment(props.target.comment ?? "");
		setPassed(props.target.passed ?? false);
	}, [props.target]);

	const validated =
		ProgressItems.find(x => x.value === values[0])
			?.children?.find(x => x.value === values[1])
			?.children?.find(x => x.value === values[2]) !== undefined;

	return (
		<Modal
			maskClosable={false}
			destroyOnClose
			open={!!props.target}
			footer={
				<>
					{props?.target?.createNew === false && (
						<Button
							onClick={() => {
								Modal.confirm({
									title: "Are you sure you want to delete this entry?",
									async onOk() {
										if (!props.target) return;
										props.onDelete(props.target);
									},
								});
							}}
						>
							Delete
						</Button>
					)}
					<Button
						onClick={() => {
							Modal.confirm({
								title: "Navigating away, your unsaved work will be lost.",
								onOk() {
									props.onCancel();
								},
							});
						}}
					>
						Cancel
					</Button>
					<Button
						type="primary"
						disabled={!values.every(x => x !== "")}
						onClick={async () => {
							if (!props.target) return;
							props.onSave({
								student_uuid: props.target.student_uuid,
								category: values[0],
								bank: values[1],
								problem: values[2],
								comment: comment,
								passed: passed,
							});
						}}
					>
						Save
					</Button>
				</>
			}
		>
			<div>
				<div>
					<Cascader options={ProgressItems} value={values} onChange={v => setValues(v as string[])}>
						<Button>Choose problem</Button>
					</Cascader>
				</div>
				<div>
					<Input
						value={values[0]}
						style={{ width: "30%", marginRight: "5%" }}
						onChange={v => setValues([v.target.value, values[1], values[2]])}
						placeholder="Category"
					/>
					<Input
						value={values[1]}
						style={{ width: "30%", marginRight: "5%" }}
						onChange={v => setValues([values[0], v.target.value, values[2]])}
						placeholder="Bank"
					/>
					<Input
						value={values[2]}
						style={{ width: "30%" }}
						onChange={v => setValues([values[0], values[1], v.target.value])}
						placeholder="Problem"
					/>
				</div>
				{!validated && <div style={{ color: "red" }}>Problem not found in database</div>}
				Comments
				<TextArea
					style={{ width: "100%" }}
					value={comment}
					rows={2}
					onChange={e => {
						setComment(e.target.value);
					}}
				/>
				<Checkbox onChange={e => setPassed(e.target.checked)} checked={passed}>
					Passed
				</Checkbox>
			</div>
		</Modal>
	);
}
