import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import { usePromise, useQuery } from "../../util";

export function OauthPage(props: { auth: AuthContextLoggedIn }) {
	const query = useQuery();
	const branchid = query.get("state");
	const token = query.get("code");

	const history = useHistory();

	// TODO this is dangerous because scripts running on this HTML page can read the code - prefer to have the server directly handle this endpoint /oauth2/...
	usePromise(
		useCallback(async () => {
			if (branchid === null || token === null) return;
			await API.Auth.SetOauthToken(props.auth, { branch_uuid: branchid, token });
			history.push("/branch");
		}, [branchid, token, props.auth, history]),
	);

	return <></>;
}
