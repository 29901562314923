import { SettingOutlined } from "@ant-design/icons";
import { Layout, List, Menu } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { ReportHandler } from "web-vitals";
import { API } from "./api-types";
import { AuthContextLoggedIn, currentRoleAtLeast, LoginComponent, ProvideAuth, useAuth } from "./auth";
import "./layout.css";
import { ModalsProvider, useModals } from "./ui/modal/modals-provider";
import { AdminPage } from "./ui/page/admin-page";
import { BackupPage } from "./ui/page/backup-page";
import { BranchPage } from "./ui/page/branch-page";
import { CalendarPage } from "./ui/page/calendar-page";
import { ClassroomAssignmentsPage } from "./ui/page/classroom-assignments-page";
import { ClassroomsPage } from "./ui/page/classrooms-page";
import { CoursesPage } from "./ui/page/courses-page";
import { FinancePage } from "./ui/page/finance-page";
import { HolidaysPage } from "./ui/page/holidays-page";
import { MailingListPage } from "./ui/page/mailing-list-page";
import { NewEnrollmentPage } from "./ui/page/new-enrollment-page";
import { OauthPage } from "./ui/page/oauth-page";
import { RemapCoursesPage } from "./ui/page/remap-courses-page";
import { SectionPage } from "./ui/page/section-page";
import { SMSPage } from "./ui/page/sms-page";
import { StudentsPage } from "./ui/page/students-page";
import { SystemMessagePage } from "./ui/page/system-message-page";
import { TemplatesPage } from "./ui/page/templates-page";
import { UsersPage } from "./ui/page/users-page";
import { usePromiseExtended, sessionFormatter } from "./util";

export function needsBackup(auth: AuthContextLoggedIn) {
	if (!currentRoleAtLeast(auth, "reception")) return;

	const backupreminder = localStorage.getItem("backup_reminder");
	return (
		backupreminder === null || new Date().getTime() - new Date(backupreminder).getTime() > 1000 * 60 * 60 * 24 * 3
	);
}

function App() {
	const history = useHistory();
	const auth = useAuth();
	const location = useLocation();
	const modals = useModals();

	const [siderCollapsed, setSiderCollapsed] = useState(false);
	const [rightSiderCollapsed, setRightSiderCollapsed] = useState(false);

	const [recentTickets, recentTicketsR] = usePromiseExtended(
		useCallback(async () => {
			if (!auth.loggedIn) return undefined;

			return await API.Ticket.GetNotifications(auth, {
				branch_uuid: auth.currentBranch.uuid,
				limit: 200,
				offset: 0,
			});
		}, [auth]),
		[],
	);

	useEffect(() => {
		const interval = setInterval(() => {
			if (!auth.loggedIn) return;

			if (needsBackup(auth)) {
				window.alert(
					"Reminder to backup the data. This message will reappear every 30 seconds until a backup is downloaded.",
				);
			}
		}, 30000);

		return () => clearInterval(interval);
	}, [auth]);

	return (
		<Layout style={{ height: "100%", overflow: "hidden" }}>
			<Layout.Sider
				collapsible
				collapsedWidth={50}
				collapsed={siderCollapsed}
				onCollapse={collapsed => {
					setSiderCollapsed(collapsed);
				}}
				theme="dark"
				className="site-layout-background"
				width={256}
				style={{ overflow: "auto", height: "100%", position: "fixed", left: 0 }}
			>
				<div style={{ padding: 16, backgroundColor: "#c4eaed", border: "2px solid #001529" }}>
					{siderCollapsed ? <></> : <LoginComponent />}
				</div>
				<Menu
					theme="dark"
					onClick={e => {
						history.push(e.key.toString());
					}}
					selectedKeys={[location.pathname]}
					defaultOpenKeys={[]}
					mode="inline"
				>
					<Menu.Item key="/">Calendar</Menu.Item>
					{currentRoleAtLeast(auth, "reception") && (
						<>
							<Menu.Item key="/students">Students</Menu.Item>
							<Menu.SubMenu key="sub0" icon={<SettingOutlined />} title="Settings">
								{currentRoleAtLeast(auth, "admin") && (
									<>
										<Menu.Item key="/branch">Branch settings</Menu.Item>
										<Menu.Item key="/classrooms">Classrooms</Menu.Item>
										<Menu.Item key="/users">Users</Menu.Item>
										<Menu.Item key="/holidays">Holidays</Menu.Item>
										<Menu.Item key="/remap-courses">Course name setup</Menu.Item>
										<Menu.Item key="/mailing-list">Mailing list</Menu.Item>
									</>
								)}
								<Menu.Item key="/classroom-assignment">Teacher assignment</Menu.Item>
							</Menu.SubMenu>
						</>
					)}
					{currentRoleAtLeast(auth, "root") && (
						<Menu.SubMenu key="sub1" title="Admin Settings">
							<Menu.Item key="/admin">Delete or create branch</Menu.Item>
							<Menu.Item key="/backup">Backup data</Menu.Item>
							<Menu.Item key="/templates">Edit templates</Menu.Item>
							<Menu.Item key="/system-message">System message</Menu.Item>
						</Menu.SubMenu>
					)}
					{currentRoleAtLeast(auth, "reception") && <Menu.Item key="/courses">Courses</Menu.Item>}
					{currentRoleAtLeast(auth, "admin") && <Menu.Item key="/finance">Payments</Menu.Item>}
				</Menu>
			</Layout.Sider>
			<Layout
				style={{
					marginLeft: siderCollapsed ? 50 : 256,
					marginRight: rightSiderCollapsed ? 50 : 180,
					padding: 16,
					height: "100%",
					overflowY: "auto",
				}}
				className="main-layout"
			>
				{auth.loggedIn && authenticatedRouter(auth)}
			</Layout>
			<Layout.Sider
				collapsible
				collapsedWidth={50}
				collapsed={rightSiderCollapsed}
				onCollapse={collapsed => {
					setRightSiderCollapsed(collapsed);
				}}
				reverseArrow
				width={180}
				style={{ overflow: "auto", height: "100%", position: "fixed", right: 0 }}
			>
				<List
					dataSource={recentTickets}
					size="small"
					itemLayout="vertical"
					renderItem={item => {
						return (
							<List.Item
								onClick={() => {
									if (!auth.loggedIn) return;

									modals.viewTicket({
										id: item.uuid,
										onCancel: async () => {
											await recentTicketsR.refresh();
										},
										auth,
									});
								}}
								style={{
									minHeight: 24,
									cursor: "pointer",
									borderBottom: "1px solid #001529",
									backgroundColor:
										item.closed !== null ? "PaleGreen" : item.viewed ? "LemonChiffon" : "LightPink",
								}}
							>
								{rightSiderCollapsed ? (
									<div style={{ textAlign: "center" }}>
										{item.closed !== null ? "✔️" : item.viewed ? "❔" : "❗"}
									</div>
								) : (
									<>
										<div>{item.title}</div>
										<div style={{ textAlign: "right", fontSize: "0.8em" }}>
											{new Date(item.created).toLocaleString([], sessionFormatter)}
										</div>
									</>
								)}
							</List.Item>
						);
					}}
				/>
			</Layout.Sider>
		</Layout>
	);
}

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<ProvideAuth>
				<ModalsProvider>
					<App />
				</ModalsProvider>
			</ProvideAuth>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
const reportWebVitals = (onPerfEntry?: ReportHandler) => {
	if (onPerfEntry && onPerfEntry instanceof Function) {
		void import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
			getCLS(onPerfEntry);
			getFID(onPerfEntry);
			getFCP(onPerfEntry);
			getLCP(onPerfEntry);
			getTTFB(onPerfEntry);
		});
	}
};
reportWebVitals();

function authenticatedRouter(auth: AuthContextLoggedIn) {
	return (
		<Switch>
			<Route path="/templates">
				<TemplatesPage auth={auth} />
			</Route>
			<Route path="/system-message">
				<SystemMessagePage auth={auth} />
			</Route>
			<Route path="/admin">
				<AdminPage auth={auth} />
			</Route>
			<Route path="/backup">
				<BackupPage auth={auth} />
			</Route>
			<Route path="/oauth2">
				<OauthPage auth={auth} />
			</Route>
			<Route path="/users">
				<UsersPage auth={auth} />
			</Route>
			<Route path="/remap-courses">
				<RemapCoursesPage auth={auth} />
			</Route>
			<Route path="/finance">
				<FinancePage auth={auth} />
			</Route>
			<Route path="/holidays">
				<HolidaysPage auth={auth} />
			</Route>
			<Route path="/courses">
				<CoursesPage auth={auth} />
			</Route>
			<Route path="/classroom-assignment">
				<ClassroomAssignmentsPage auth={auth} />
			</Route>
			<Route path="/classrooms">
				<ClassroomsPage auth={auth} />
			</Route>
			<Route path="/branch">
				<BranchPage auth={auth} />
			</Route>
			<Route path="/new-enrollment">
				<NewEnrollmentPage auth={auth} />
			</Route>
			<Route path="/section/:id">
				<SectionPage auth={auth} />
			</Route>
			<Route path="/sms">
				<SMSPage auth={auth} />
			</Route>
			<Route path="/students/:id?">
				<StudentsPage auth={auth} />
			</Route>
			<Route path="/mailing-list">
				<MailingListPage auth={auth} />
			</Route>
			<Route path="/">
				<CalendarPage auth={auth} />
			</Route>
		</Switch>
	);
}
