import { useEffect, useRef, useState } from "react";
import { ReportResponse } from "../modal/session-report-editor-modal";
import TextArea from "antd/lib/input/TextArea";
import { Form } from "antd";

const assessmentCategories = [
	{
		section: "Robotics",
	},
	{
		section: "Coding",
	},
	{
		section: "Math",
	},
	{
		section: "Independence",
	},
	{
		section: "Behavior",
	},
	{
		section: "Comments",
	},
	{
		section: "Suggested program",
	},
];

export function AssessmentReport(props: {
	active: boolean;
	report: ReportResponse | undefined;
	attendance: boolean | "late" | null;
	onUpdateValues: (valid: boolean, x: Record<string, unknown>) => void;
}) {
	const [sections, setSections] = useState<string[]>([]);
	const [comments, setComments] = useState<string[]>([]);

	useEffect(() => {
		const prevEntries: [string, string][] | undefined = (props.report?.data as any)?.assessment;

		if (prevEntries !== undefined && prevEntries instanceof Array) {
			const prevSections: string[] = [];
			const prevComments: string[] = [];

			prevEntries.forEach((x, i) => {
				prevSections.push(x[0]);
				prevComments.push(x[1]);
			});

			setSections(prevSections);
			setComments(prevComments);
		} else {
			setSections(assessmentCategories.map(x => x.section));
			setComments(assessmentCategories.map(_ => ""));
		}
	}, [props.report]);

	const propsRef = useRef(props);
	propsRef.current = props;

	useEffect(() => {
		if (propsRef.current.active) {
			propsRef.current.onUpdateValues(true, {
				assessment: sections.map((x, i) => [x, comments[i]]),
			});
		}
	}, [sections, comments]);

	return (
		<>
			{sections.map((x, i) => (
				<Form.Item label={x} key={i} style={{ marginBottom: 5 }}>
					<TextArea
						value={comments[i]}
						rows={2}
						onChange={e => {
							const newCats = [...comments];
							newCats[i] = e.target.value;
							setComments(newCats);
						}}
					/>
				</Form.Item>
			))}
		</>
	);
}
