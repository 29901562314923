import Button from "antd/lib/button";
import download from "downloadjs";
import { useState } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn, Authenticated } from "../../auth";

export function BackupPage(props: { auth: AuthContextLoggedIn }) {
	const [working, setWorking] = useState(false);
	return (
		<Authenticated as="root">
			<div className="content-flex-vertical" style={{ width: 400 }}>
				<div>
					Backup data
					<br />
					<Button
						onClick={async () => {
							try {
								setWorking(true);
								const blob = await API.Admin.DownloadBackup(props.auth, {});
								download(blob, "pgdump.zip");
							} finally {
								setWorking(false);
							}
						}}
						loading={working}
					>
						download
					</Button>
				</div>
			</div>
		</Authenticated>
	);
}
