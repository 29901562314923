import { Button } from "antd";
import { useCallback, useState } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn, Authenticated } from "../../auth";
import { usePromiseExtended } from "../../util";
import { UserForm } from "../form/user-form";

export function UsersPage(props: { auth: AuthContextLoggedIn }) {
	const [usersList, usersListR] = usePromiseExtended(
		useCallback(() => API.User.GetAll(props.auth, { branch_uuid: props.auth.currentBranch.uuid }), [props.auth]),
	);

	// null = new, undefined = none
	const [user, setUser] = useState<string | null>();
	const [userData, userDataR] = usePromiseExtended(
		useCallback(async () => {
			if (user === undefined || user === null) {
				return undefined;
			}

			try {
				return await API.User.Get(props.auth, { uuid: user });
			} catch (e) {
				return undefined;
			}
		}, [user, props.auth]),
	);

	return (
		<Authenticated as="admin">
			<div className="content-flex-horizontal">
				{usersList && (
					<div className="subcontent-flex-vertical" style={{ width: 300 }}>
						<div>
							{usersList.map(x => (
								<div
									key={x.uuid}
									className={"hover-list-item" + (user === x.uuid ? " selected" : "")}
									onClick={() => setUser(x.uuid)}
								>
									{x.username} ({x.name})
								</div>
							))}
						</div>
						<Button type="primary" onClick={() => setUser(null)}>
							Create new user
						</Button>
					</div>
				)}

				{user !== undefined && (user === null || userData) && (
					<>
						<div className="subcontent-flex-vertical" style={{ width: 300 }}>
							<UserForm
								auth={props.auth}
								user={user !== null ? userData! : null}
								userRole={
									user !== null
										? userData!.roles.find(x => x.branch.uuid === props.auth.currentBranch.uuid)
												?.role
										: undefined
								}
								onUpdated={() => userDataR.refresh()}
								onCreated={async id => {
									setUser(id);
									await usersListR.refresh();
									await userDataR.refresh();
								}}
								onDeleted={async () => {
									setUser(undefined);
									await usersListR.refresh();
									await userDataR.refresh();
								}}
							/>
						</div>
					</>
				)}
			</div>
		</Authenticated>
	);
}
