import { Button, InputNumber, Select, Tabs } from "antd";
import { useState } from "react";
import { AuthContextLoggedIn, Authenticated } from "../../auth";
import download from "downloadjs";
import { API } from "../../api-types";

export function MailingListPage(props: { auth: AuthContextLoggedIn }) {
	const [months, setMonths] = useState(6);
	const [option, setOption] = useState<string>("active");

	return (
		<Authenticated as="admin">
			<div className="content-flex-vertical" style={{ width: 400 }}>
				<div>Export mailing list</div>
				<Tabs
					defaultActiveKey="active"
					style={{ width: 800 }}
					onChange={x => {
						setOption(x);
					}}
					activeKey={option}
					items={[
						{
							key: "active",
							label: "Active",
							children: (
								<div>
									Select only students who have enrollments in the most recent{" "}
									<InputNumber precision={0} value={months} onChange={v => setMonths(v ?? 0)} />{" "}
									months
								</div>
							),
						},
						{
							key: "inactive",
							label: "Inactive",
							children: (
								<div>
									Select only students who have not had enrollments for{" "}
									<InputNumber precision={0} value={months} onChange={v => setMonths(v ?? 0)} />{" "}
									months
								</div>
							),
						},
						{
							key: "all",
							label: "All",
							children: <div>Select all students</div>,
						},
					]}
				/>

				<Button
					type="primary"
					onClick={async () => {
						const cutoff = new Date();
						cutoff.setMonth(cutoff.getMonth() - months);

						let list: string[] = [];
						if (option === "all") {
							list = await API.Student.GetMailingList(props.auth, {
								branch_uuid: props.auth.currentBranch.uuid,
								all: true,
							});
						} else if (option === "active") {
							list = await API.Student.GetMailingList(props.auth, {
								branch_uuid: props.auth.currentBranch.uuid,
								active_since: cutoff.toISOString(),
							});
						} else if (option === "inactive") {
							list = await API.Student.GetMailingList(props.auth, {
								branch_uuid: props.auth.currentBranch.uuid,
								inactive_since: cutoff.toISOString(),
							});
						}

						download(list.join(","), "emails.txt", "text/plain");
					}}
				>
					Download
				</Button>
			</div>
		</Authenticated>
	);
}
