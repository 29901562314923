import { Auth, AuthContextLoggedIn } from "./auth";

const apiRoot = process.env.REACT_APP_API_ROOT ?? "http://localhost:5000/api";

function makeInit<T>(
	method: string,
	auth: { loggedIn: false } | { loggedIn: true; auth: Auth },
	body?: T,
): RequestInit {
	const headers: Record<string, string> = {
		"Content-Type": "application/json",
	};

	if (auth.loggedIn) {
		headers.Authorization = `Bearer ${auth.auth.token}`;
	}

	const init: RequestInit = {
		method,
		headers,
		body: JSON.stringify(body),
	};

	return init;
}

async function parseError(res: Response, auth?: AuthContextLoggedIn) {
	try {
		if (res.status !== 200) {
			const json = await res.json();
			if (json.error !== undefined) {
				if (
					json.error === "TokenExpired" ||
					json.error === "InvalidToken" ||
					json.error === "NotAuthenticated"
				) {
					auth?.signout();
					return true;
				}
			}

			console.log(json);
			throw new Error(JSON.stringify(json));
		}
	} catch (e: any) {
		if (e instanceof TypeError) {
			alert("Could not reach server");
			throw e;
		}

		try {
			if ("message" in e) {
				const obj = JSON.parse(e.message as string);
				if ("error" in obj) {
					if ("desc" in obj) {
						alert(`${obj.error.toString() as string}\n${obj.desc.toString() as string}`);
					} else {
						alert(`${obj.error.toString() as string}`);
					}
				} else {
					alert(obj.toString());
				}
			} else {
				alert(e);
			}
		} catch (e2) {
			alert(e);
		}
		throw e;
	}
}

export function register<TParam, TReturn>(path: string) {
	return async (auth: AuthContextLoggedIn, params: TParam): Promise<TReturn> => {
		const res = await fetch(`${apiRoot}`, makeInit("post", auth, { ...params, PATH: path }));
		if (await parseError(res, auth)) throw Error();
		return await res.json();
	};
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function registerBlob<TParam, _>(path: string) {
	return async (auth: AuthContextLoggedIn, params: TParam): Promise<Blob> => {
		const res = await fetch(`${apiRoot}`, makeInit("post", auth, { ...params, PATH: path }));
		if (await parseError(res, auth)) throw Error();
		return await res.blob();
	};
}

export function registerAuthless<TParam, TReturn>(path: string) {
	return async (params: TParam): Promise<TReturn> => {
		const res = await fetch(`${apiRoot}`, makeInit("post", { loggedIn: false }, { ...params, PATH: path }));
		await parseError(res);
		return await res.json();
	};
}

export function registerUpload<TReturn>(path: string) {
	return async (auth: AuthContextLoggedIn, file: File): Promise<TReturn> => {
		const init = makeInit("post", auth, {});
		init.headers = { ...init.headers, "Content-Type": "application/octet-stream" };
		init.body = await file.arrayBuffer();
		const res = await fetch(`${apiRoot}${path}`, init);
		if (await parseError(res, auth)) throw Error();

		return await res.json();
	};
}
