import { API } from "../../api-types";
import { useCallback, useMemo } from "react";
import { ReturnPromiseType, usePromise } from "../../util";
import { AuthContextLoggedIn } from "../../auth";
import { Select } from "antd";

export type ClassroomSelection = ReturnPromiseType<typeof API.Course.GetClassrooms>[number];

export function ClassroomSelector(props: {
	disabled?: boolean;
	courseId?: string;
	showAllBranches?: boolean;
	value?: string;
	onChange: (value?: ClassroomSelection) => void;
	auth: AuthContextLoggedIn;
}) {
	const classrooms = usePromise(
		useCallback(
			() =>
				API.Course.GetClassrooms(props.auth, {
					branch_uuid: props.showAllBranches === true ? undefined : props.auth.currentBranch.uuid,
					course_uuid: props.courseId,
				}),
			[props.courseId, props.auth, props.showAllBranches],
		),
	);

	const options = useMemo(() => {
		if (!classrooms) return;
		if (props.showAllBranches === true) {
			return classrooms.map(x => ({ value: x.uuid, label: `${x.branch.name} - ${x.name}` }));
		} else {
			return classrooms.map(x => ({ value: x.uuid, label: x.name }));
		}
	}, [classrooms, props.showAllBranches]);

	return (
		<>
			{options && (
				<div>
					<div>Select classroom</div>
					<Select
						style={{ width: "100%" }}
						value={props.value}
						onSelect={(x: string) => classrooms && props.onChange(classrooms.find(y => x === y.uuid))}
						options={options}
						disabled={props.disabled}
					/>
				</div>
			)}
		</>
	);
}
