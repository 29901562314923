import { Button, Modal, Select, TimePicker } from "antd";
import { useEffect, useState } from "react";
import moment, { Moment } from "moment";

export function EditClassroomHoursModal(props: {
	target: { index: number; dow: number; begin: Moment; end: Moment } | undefined;
	onSave: (index: number, dow: number, begin: Moment, end: Moment) => void;
	onDelete: (index: number) => void;
	onCancel: () => void;
}) {
	const [dow, setDow] = useState(0);
	const [begin, setBegin] = useState(moment().hour(15).minute(0));
	const [end, setEnd] = useState(moment().hour(15).minute(0));

	useEffect(() => {
		if (!props.target) return;

		setDow(props.target.dow);
		setBegin(props.target.begin);
		setEnd(props.target.end);
	}, [props.target]);

	return (
		<Modal
			destroyOnClose
			open={!!props.target}
			onOk={() => {
				if (!props.target) return;
				props.onSave(props.target.index, dow, begin, end);
			}}
			onCancel={() => props.onCancel()}
		>
			<span>Select day of week</span>
			<Select
				value={dow}
				options={[
					{ value: 0, label: "Sunday" },
					{ value: 1, label: "Monday" },
					{ value: 2, label: "Tuesday" },
					{ value: 3, label: "Wednesday" },
					{ value: 4, label: "Thursday" },
					{ value: 5, label: "Friday" },
					{ value: 6, label: "Saturday" },
				]}
				onChange={k => setDow(k)}
			/>
			<div>
				<TimePicker use12Hours value={begin} minuteStep={30} format={"HH:mm"} onSelect={x => setBegin(x)} /> to
				<TimePicker use12Hours value={end} minuteStep={30} format={"HH:mm"} onSelect={x => setEnd(x)} />
			</div>
			{props.target && (
				<Button type="primary" onClick={() => props.target && props.onDelete(props.target.index)}>
					Delete entry
				</Button>
			)}
		</Modal>
	);
}
