import { Button, Checkbox, Col, DatePicker, Divider, List, Modal, Popover, Row } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useContextMenu } from "react-contexify";
import QRCode from "react-qr-code";
import { API } from "../../api-types";
import { AuthContextLoggedIn, Authenticated, currentRoleAtLeast } from "../../auth";
import { ReturnPromiseType, formatDateYMD, usePromise, usePromiseExtended, useQuery } from "../../util";
import {
	EventsCalendar,
	ExtendedPropsSectionSession,
	ExtendedPropsTask,
	ExtendedPropsTicket,
	isDarkBg,
	StudentSessionDataWithExtras,
} from "../misc/events-calendar";
import {
	DatetimeContextMenu,
	SectionAndSessionContextMenu,
	TaskContextMenu,
	TicketContextMenu,
} from "../misc/events-calendar-context-menu";
import { useModals } from "../modal/modals-provider";
import "./calendar.css";
import moment from "moment";
import download from "downloadjs";
import { needsBackup } from "../..";
import { AttendanceGrid } from "../misc/attendance-grid";

export function CalendarPage(props: { auth: AuthContextLoggedIn }) {
	const ctx = useContextMenu();
	const [taskContextTarget, setTaskContextTarget] = useState<ExtendedPropsTask["task"]>();
	const [sessionContextTarget, setSessionContextTarget] = useState<StudentSessionDataWithExtras>();
	const [ticketContextTarget, setTicketContextTarget] = useState<ExtendedPropsTicket["ticket"]>();
	const [sectionContextTarget, setSectionContextTarget] = useState<ExtendedPropsSectionSession["section_session"]>();
	const [datetimeContextTarget, setDatetimeContextTarget] = useState<{
		dateStr: string;
		date: Date;
		allDay: boolean;
	}>();
	const [refresher, setRefresher] = useState(0);

	const today = new Date();
	const thisWeekSunday = today.getDate() - today.getDay();
	const _start = new Date(today);
	_start.setDate(thisWeekSunday + 7);
	const _end = new Date(today);
	_end.setDate(thisWeekSunday + 13);
	const [backupStartDate, setBackupStartDate] = useState(_start);
	const [backupEndDate, setBackupEndDate] = useState(_end);

	const _start1 = new Date(today);
	_start1.setDate(thisWeekSunday);
	const _end1 = new Date(today);
	_end1.setDate(thisWeekSunday + 6);
	const [backupStartDate1, setBackupStartDate1] = useState(_start1);
	const [backupEndDate1, setBackupEndDate1] = useState(_end1);

	const classrooms = usePromise(
		useCallback(async () => {
			let classroomsN = await API.Branch.Classroom.GetNames(props.auth, {
				branch_uuid: props.auth.currentBranch.uuid,
			});

			if (!currentRoleAtLeast(props.auth, "reception")) {
				const teacherclassrooms = await API.Branch.Classroom.GetClassroomsForTeacher(props.auth, {
					branch_uuid: props.auth.currentBranch.uuid,
					user_uuid: props.auth.auth.uuid,
				});
				classroomsN = classroomsN.filter(x => teacherclassrooms.find(y => y.uuid === x.uuid) !== undefined);
			}

			let setting: string[] = [];
			try {
				const val = localStorage.getItem("classrooms");
				if (val !== null) {
					setting = JSON.parse(val);
				}

				setClassroomUuidSelectionOnline(
					classroomsN
						.filter(x => x.is_online)
						.map(x => x.uuid)
						.filter(x => setting.includes(x)),
				);
				setClassroomUuidSelectionOffline(
					classroomsN
						.filter(x => !x.is_online)
						.map(x => x.uuid)
						.filter(x => setting.includes(x)),
				);
			} catch {
				// ignore error
			}

			return classroomsN;
		}, [props.auth]),
		[],
	);

	const [classroomUuidSelectionOffline, setClassroomUuidSelectionOffline] = useState<string[]>([]);
	const [classroomUuidSelectionOnline, setClassroomUuidSelectionOnline] = useState<string[]>([]);
	const [curRange, setCurRange] = useState<[Date, Date] | null>(null);
	const [groupClassrooms, setGroupClassrooms] = useState(true);

	const [sessions, sessionsR] = usePromiseExtended(
		useCallback(async () => {
			if (!curRange) return [];
			const [start, end] = curRange;
			return await API.Branch.Schedule.Get(props.auth, {
				branch_uuid: props.auth.currentBranch.uuid,
				start: start.toISOString(),
				end: end.toISOString(),
			});
		}, [curRange, props.auth]),
		[],
	);

	const [enrData, setEnrData] = useState<ReturnPromiseType<typeof API.Student.Enrollment.GetRemindersMany>>({});
	const [enrDataEntries, setEnrDataEntries] = useState<Set<string>>(new Set<string>());

	useEffect(() => {
		for (const key in enrData) {
			if (!enrDataEntries.has(key)) {
				setEnrDataEntries(prev => new Set([...prev, ...Object.keys(enrData)]));
				return;
			}
		}
	}, [enrData, enrDataEntries]);

	useEffect(() => {
		void (async () => {
			if (currentRoleAtLeast(props.auth, "teacher")) {
				const newReminders = await API.Student.Enrollment.GetRemindersMany(props.auth, {
					uuids: sessions.map(x => x.enrollment.uuid).filter(x => !enrDataEntries.has(x)),
				});

				setEnrData(oldData => ({
					...oldData,
					...newReminders,
				}));
			}
		})();
	}, [sessions, props.auth, enrDataEntries]);

	const [tickets, ticketsR] = usePromiseExtended(
		useCallback(async () => {
			if (!curRange) return [];
			const [start, end] = curRange;
			return await API.Ticket.GetAll(props.auth, {
				branch_uuid: props.auth.currentBranch.uuid,
				start_date: start.toISOString(),
				end_date: end.toISOString(),
				no_hidden: true,
				no_reminders: true,
			});
		}, [curRange, props.auth]),
		[],
	);

	const [tasks, tasksR] = usePromiseExtended(
		useCallback(async () => {
			if (!curRange) return [];
			const [start, end] = curRange;
			return await API.Ticket.Task.GetAll(props.auth, {
				branch_uuid: props.auth.currentBranch.uuid,
				start: start.toISOString(),
				end: end.toISOString(),
			});
		}, [curRange, props.auth]),
		[],
	);

	useEffect(() => {
		const id = setInterval(() => {
			void ticketsR.refresh();
			void sessionsR.refresh();
			void tasksR.refresh();
		}, 1000 * 60);
		return () => {
			clearInterval(id);
		};
	}, [sessionsR, tasksR, ticketsR]);

	const query = useQuery();
	const initDate = query.get("start");

	const modals = useModals();

	const onlineClassrooms = classrooms.filter(x => x.is_online).map(x => x.uuid);
	const offlineClassrooms = classrooms.filter(x => !x.is_online).map(x => x.uuid);

	const [qrCodeDisplay, setQrCodeDisplay] = useState<string>();

	return (
		<Authenticated>
			<div
				className="subcontent-flex-vertical"
				style={{ userSelect: "none", overflow: "hidden", height: "100%" }}
			>
				<Row gutter={[16, 16]}>
					<Col span={8}>
						<Checkbox
							onChange={checked => {
								if (checked.target.checked) {
									setClassroomUuidSelectionOffline(offlineClassrooms);
									localStorage.setItem(
										"classrooms",
										JSON.stringify([...offlineClassrooms, ...classroomUuidSelectionOnline]),
									);
								} else {
									setClassroomUuidSelectionOffline([]);
									localStorage.setItem("classrooms", JSON.stringify(classroomUuidSelectionOnline));
								}
							}}
							checked={classroomUuidSelectionOffline.length > 0}
							indeterminate={
								classroomUuidSelectionOffline.length > 0 &&
								classroomUuidSelectionOffline.length < offlineClassrooms.length
							}
						>
							Offline classrooms
						</Checkbox>
						<Divider style={{ margin: "8px 0" }} />
						<div>
							<Checkbox.Group
								options={classrooms
									.filter(x => !x.is_online)
									.map(x => ({
										label: x.name,
										value: x.uuid,
										style: {
											margin: 2,
											paddingLeft: 4,
											paddingTop: 1,
											paddingBottom: 1,
											borderRadius: 3,
											backgroundColor: x.header_color,
											color: isDarkBg(x.header_color) ? "white" : "black",
										},
									}))}
								value={classroomUuidSelectionOffline}
								onChange={x => {
									setClassroomUuidSelectionOffline(x as string[]);
									localStorage.setItem(
										"classrooms",
										JSON.stringify([...classroomUuidSelectionOnline, ...x]),
									);
								}}
							/>
						</div>
					</Col>
					<Col span={6}>
						<Checkbox
							onChange={checked => {
								if (checked.target.checked) {
									setClassroomUuidSelectionOnline(onlineClassrooms);
									localStorage.setItem(
										"classrooms",
										JSON.stringify([...onlineClassrooms, ...classroomUuidSelectionOffline]),
									);
								} else {
									setClassroomUuidSelectionOnline([]);
									localStorage.setItem("classrooms", JSON.stringify(classroomUuidSelectionOffline));
								}
							}}
							checked={classroomUuidSelectionOnline.length > 0}
							indeterminate={
								classroomUuidSelectionOnline.length > 0 &&
								classroomUuidSelectionOnline.length < onlineClassrooms.length
							}
						>
							Online classrooms
						</Checkbox>
						<Divider style={{ margin: "8px 0" }} />
						<div>
							<Checkbox.Group
								value={classroomUuidSelectionOnline}
								onChange={x => {
									setClassroomUuidSelectionOnline(x as string[]);
									localStorage.setItem(
										"classrooms",
										JSON.stringify([...classroomUuidSelectionOffline, ...x]),
									);
								}}
								options={classrooms
									.filter(x => x.is_online)
									.map(x => ({
										label: x.name,
										value: x.uuid,
										style: {
											margin: 2,
											paddingLeft: 4,
											paddingTop: 1,
											paddingBottom: 1,
											borderRadius: 3,
											backgroundColor: x.header_color,
											color: isDarkBg(x.header_color) ? "white" : "black",
										},
									}))}
							/>
						</div>
					</Col>
					<Col span={3}>
						<Popover
							trigger="click"
							content={
								<List
									dataSource={classrooms.filter(x => x.curriculum_link)}
									renderItem={item => (
										<>
											<a target="_blank" rel="noreferrer" href={item.curriculum_link}>
												<Button>{item.name}</Button>
											</a>
										</>
									)}
								/>
							}
						>
							<Button>Curricula</Button>
						</Popover>
						<Checkbox
							onChange={checked => setGroupClassrooms(checked.target.checked)}
							checked={groupClassrooms}
						>
							Group classrooms
						</Checkbox>
					</Col>
					<Col span={3}>
						{currentRoleAtLeast(props.auth, "reception") && (
							<>
								{needsBackup(props.auth) && <div style={{ color: "red" }}>Please backup!</div>}
								<Popover
									trigger="click"
									content={
										<>
											<DatePicker.RangePicker
												value={[moment(backupStartDate), moment(backupEndDate)]}
												onChange={range => {
													if (!range) return;
													setBackupStartDate(range[0]?.toDate() ?? new Date());
													setBackupEndDate(range[1]?.toDate() ?? new Date());
												}}
											/>
											<Button
												onClick={async () => {
													const blob = await API.Branch.Schedule.ExportAsXlsx(props.auth, {
														branch_uuid: props.auth.currentBranch.uuid,
														start: formatDateYMD(backupStartDate),
														end: formatDateYMD(backupEndDate),
													});
													download(blob, "schedule.xlsx");
													localStorage.setItem("backup_reminder", new Date().toISOString());

													// force rerender
													setRefresher(refresher + 1);
												}}
											>
												Download
											</Button>
										</>
									}
								>
									<Button>Backup schedule</Button>
								</Popover>
							</>
						)}
						{currentRoleAtLeast(props.auth, "reception") && (
							<>
								<Popover
									trigger="click"
									content={
										<>
											<DatePicker.RangePicker
												value={[moment(backupStartDate1), moment(backupEndDate1)]}
												onChange={range => {
													if (!range) return;
													setBackupStartDate1(range[0]?.toDate() ?? new Date());
													setBackupEndDate1(range[1]?.toDate() ?? new Date());
												}}
											/>
											<Button
												onClick={async () => {
													const blob = await API.Branch.Schedule.ExportAsXlsx(props.auth, {
														branch_uuid: props.auth.currentBranch.uuid,
														start: formatDateYMD(backupStartDate1),
														end: formatDateYMD(backupEndDate1),
													});
													download(blob, "schedule.xlsx");
												}}
											>
												Download
											</Button>
										</>
									}
								>
									<Button>This week's summary</Button>
								</Popover>
							</>
						)}
					</Col>
				</Row>
				<div style={{ flexGrow: 1, overflow: "hidden" }}>
					<EventsCalendar
						groupByClassroom={groupClassrooms}
						tickets={tickets}
						studentSessions={sessions}
						enrollmentData={enrData}
						tasks={tasks}
						auth={props.auth}
						initialDate={initDate}
						classroomUuidSelection={[...classroomUuidSelectionOnline, ...classroomUuidSelectionOffline]}
						onTaskReschedule={async (id, time) => {
							await API.Ticket.Task.Update(props.auth, {
								uuid: id,
								duration: time.duration,
								datetime: time.datetime?.toISOString(),
							});
							tasksR.set([]);
							await tasksR.refresh();
						}}
						onSessionClick={(s, e) => {
							setSessionContextTarget(s);
							ctx.show(e, { id: "session" });
						}}
						onSectionClick={(s, e) => {
							setSectionContextTarget(s);
							ctx.show(e, { id: "section" });
						}}
						onTicketClick={(s, e) => {
							setTicketContextTarget(s);
							ctx.show(e, { id: "ticket" });
						}}
						onTaskClick={(s, e) => {
							setTaskContextTarget(s);
							ctx.show(e, { id: "task" });
						}}
						onDatetimeClick={(s, e) => {
							setDatetimeContextTarget(s);
							ctx.show(e, { id: "datetime" });
						}}
						onAlldayClick={(s, e) => {
							modals.createTicket({
								datetime: s.date,
								taskDatetime: s.date,
								initialType: "message",
								onCreated: async () => {
									await ticketsR.refresh();
									await tasksR.refresh();
								},
								auth: props.auth,
							});
						}}
						onDateRangeChange={range => {
							setCurRange(range);
						}}
					/>
				</div>

				<SectionAndSessionContextMenu
					section_menu_id="section"
					section_target={sectionContextTarget}
					session_menu_id="session"
					session_target={sessionContextTarget}
					auth={props.auth}
					onReportModalOpen={t => {
						modals.editStudentSessionReport({
							target: { uuid: t.uuid, auth: props.auth, enrollment: t.enrollment },
							onUpdated: async () => {
								await sessionsR.refresh();
								await ticketsR.refresh();
							},
						});

						setSectionContextTarget(undefined);
						setSessionContextTarget(undefined);
					}}
					onEnrollmentCommentModalOpen={t => {
						modals.display({
							contents: <div>{t.enrollment.comment}</div>,
						});
					}}
					onEnrollmentReportModalOpen={async t => {
						const report = await API.Student.Enrollment.GetReport(props.auth, {
							uuid: t.enrollment.uuid,
						});

						modals.editEnrollmentReport({
							target: {
								...t.enrollment,
								auth: props.auth,
								report: report.report,
							},
							async onUpdated() {
								await ticketsR.refresh();
							},
						});

						setSectionContextTarget(undefined);
						setSessionContextTarget(undefined);
					}}
					onEnrollmentGridModalOpen={async t => {
						modals.display({
							args: {
								width: 800,
								title: `Attendance grid ${t.enrollment.section.course.name}`,
							},
							contents: <AttendanceGrid auth={props.auth} enrollmentUuid={t.enrollment.uuid} />,
						});
					}}
					onCreateTicketModalOpen={t => {
						modals.createTicket({
							initialType: "message",
							datetime: new Date(t.datetime),
							section_session_uuid: t.uuid,
							section_uuid: t.section.uuid,
							onCreated: async id => {
								await ticketsR.refresh();
							},
							auth: props.auth,
						});

						setSectionContextTarget(undefined);
					}}
					onManagerSMSModalOpen={t => {
						modals.managerSMS({
							target: {
								section_session: t.section_session,
								enrollment: t.enrollment,
								auth: props.auth,
							},
						});

						setSectionContextTarget(undefined);
					}}
					onCreateStudentTicketModalOpen={t => {
						modals.createTicket({
							initialType: "message",
							datetime: new Date(t.section_session.datetime),
							student_session_uuid: t.uuid,
							enrollment_uuid: t.enrollment.uuid,
							student_uuid: t.enrollment.student.uuid,
							onCreated: async () => {
								await ticketsR.refresh();
							},
							auth: props.auth,
						});

						setSectionContextTarget(undefined);
						setSessionContextTarget(undefined);
					}}
					onQrCodeModalOpen={async t => {
						const student = await API.Student.GetBasicInfo(props.auth, {
							uuid: t.enrollment.student.uuid,
						});
						const hash = await API.Student.Enrollment.Session.GetQRHash(props.auth, {
							uuid: t.uuid,
						});

						const qr: any = {
							name: student.name,
							email: student.email,
							uuid: t.uuid,
							class: t.enrollment.section.course.name,
							hash: hash.hash,
						};

						setSectionContextTarget(undefined);
						setSessionContextTarget(undefined);

						setQrCodeDisplay(JSON.stringify(qr));
					}}
					onDismiss={() => {
						setSectionContextTarget(undefined);
						setSessionContextTarget(undefined);
					}}
					onRefresh={async () => {
						await sessionsR.refresh();
					}}
				/>
			</div>

			<DatetimeContextMenu
				id="datetime"
				auth={props.auth}
				target={datetimeContextTarget}
				onDismiss={() => {
					setDatetimeContextTarget(undefined);
				}}
				onCreateTicketModalOpen={s => {
					modals.createTicket({
						initialType: "message",
						datetime: s.date,
						showTaskCreator: !s.allDay,
						taskDatetime: s.date,
						onCreated: async () => {
							await ticketsR.refresh();
							await tasksR.refresh();
						},
						auth: props.auth,
					});
					setDatetimeContextTarget(undefined);
				}}
			/>

			<TicketContextMenu
				id="ticket"
				target={ticketContextTarget}
				onDismiss={() => {
					setTicketContextTarget(undefined);
				}}
				onReplyModalOpen={() => {
					if (!ticketContextTarget) return;

					modals.viewTicket({
						id: ticketContextTarget.uuid,
						onCancel: async () => {
							await ticketsR.refresh();
						},
						auth: props.auth,
					});
					setTicketContextTarget(undefined);
				}}
			/>

			<TaskContextMenu
				id="task"
				target={taskContextTarget}
				onDismiss={() => {
					setTaskContextTarget(undefined);
				}}
				onReplyModalOpen={() => {
					if (!taskContextTarget) return;

					modals.viewTicket({
						id: taskContextTarget.uuid,
						onCancel: async () => {
							await ticketsR.refresh();
						},
						auth: props.auth,
					});
					setTaskContextTarget(undefined);
				}}
			/>

			<Modal
				destroyOnClose
				open={qrCodeDisplay !== undefined}
				onOk={() => setQrCodeDisplay(undefined)}
				onCancel={() => setQrCodeDisplay(undefined)}
			>
				{qrCodeDisplay !== undefined && <QRCode value={qrCodeDisplay} />}
			</Modal>
		</Authenticated>
	);
}
