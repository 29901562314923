import { Input, Modal } from "antd";
import { useEffect, useState } from "react";

export function PromptModal(props: {
	title?: string;
	lengthAtLeast?: number;
	isInteger?: boolean;
	initialValue?: string;
	visible: boolean;
	onOk: (message: string) => Promise<void>;
	onCancel: () => Promise<void>;
}) {
	const [value, setValue] = useState<string>("");

	useEffect(() => {
		setValue(props.initialValue ?? "");
	}, [props.visible, props.initialValue]);

	const validate = () => {
		if (value.length < (props.lengthAtLeast ?? 0)) return false;
		if (props.isInteger === true && isNaN(parseInt(value))) return false;
		return true;
	};

	return (
		<Modal
			destroyOnClose
			open={props.visible}
			okButtonProps={{ disabled: !validate() }}
			onOk={async () => {
				if (!validate()) return;
				await props.onOk(value);
			}}
			onCancel={props.onCancel}
		>
			<div>{props.title}</div>
			<Input style={{ width: 400 }} value={value} onChange={x => setValue(x.target.value)} />
		</Modal>
	);
}
