import { Input } from "antd";
import { useCallback, useState } from "react";
import * as Yup from "yup";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import { usePromise, useQuery } from "../../util";
import { FormLoader, getSubField, LabelledField, SubFieldType, TextAreaField, TextField, useMyFormik } from "./form";
import { CourseSelectorTree } from "../misc/course-selector-tree";
import { TemplateSelectorTree } from "../misc/template-selector-tree";
import { useAsync } from "react-use";

const schema = Yup.object({
	subject: Yup.string().required(),
	html: Yup.string().min(3),
	text: Yup.string().min(3),
});

function formatMailHtml(html: string | undefined, parameters: Record<string, string>) {
	if (html === undefined) return undefined;
	let newhtml = html;
	for (const [key, val] of Object.entries(parameters)) {
		newhtml = newhtml.replaceAll(`[PARAMETER_${key}]`, val);
	}
	return newhtml;
}

export function MailForm(props: {
	studentUuid: string;
	initialTemplate: string;
	invoice?: { payment_uuid: string };
	report_card?: { enrollment_uuid: string };
	onSent: () => Promise<void>;
	auth: AuthContextLoggedIn;
}) {
	const query = useQuery();

	const [sent, setSent] = useState(false);
	const lastSentMail = usePromise(
		useCallback(
			() => API.Student.GetLastSentMail(props.auth, { uuid: props.studentUuid }),
			[props.studentUuid, props.auth],
		),
	);
	const studentName = usePromise(
		useCallback(
			async () => (await API.Student.GetName(props.auth, { uuid: props.studentUuid })).name,
			[props.studentUuid, props.auth],
		),
	);

	const templateList = usePromise(
		useCallback(
			() => API.Mail.GetTemplateList(props.auth, { branch_uuid: props.auth.currentBranch.uuid }),
			[props.auth],
		),
	);

	const [selectedTemplate, setSelectedTemplate] = useState<string>(props.initialTemplate);

	const formik = useMyFormik({
		schema,
		additionalTests: [
			{
				name: "bodycontent",
				test: values => {
					const valid = values.html !== undefined || (values.text !== undefined && values.text !== "");
					if (valid) return;
					return { path: "subject", message: "you must input a body" };
				},
			},
			{
				name: "parameters",
				test: values => {
					if (Object.entries(values.parameters).some(([k, v]) => v === "" || v === undefined)) {
						return { path: "subject", message: "you must fill in all the parameters below" };
					}
					return undefined;
				},
			},
		],
		initialValues: {
			subject: "",
			text: "" as string,
			html: undefined as string | undefined,
			parameters: {} as Record<string, string>,
		},
		onCreate: async values => {
			await API.Mail.Send(props.auth, {
				student_uuid: props.studentUuid,
				text: values.text,
				html: formatMailHtml(values.html, values.parameters),
				branch_uuid: props.auth.currentBranch.uuid,
				subject: values.subject,
				template: selectedTemplate !== "" ? selectedTemplate : undefined,
				invoice: props.invoice,
				report_card: props.report_card,
			});
			await API.Ticket.Create(props.auth, {
				branch_uuid: props.auth.currentBranch.uuid,
				student_uuid: props.studentUuid,
				closed: true,
				hidden: true,
				recipient_roles: ["reception"],
				recipients: [],
				type: "note",
				title: `Email sent (${selectedTemplate || "no template"})`,
				text: `Parameters: (${Object.entries(values.parameters)
					.map(([k, v]) => `${k}: ${v}`)
					.join(", ")})`,
			});

			setSent(true);
			await props.onSent();
		},
	});

	const sfv = formik.setFieldValue;

	// useEffect(() => {
	// 	if (props.initialTemplate !== undefined) {
	// 		setSelectedTemplate(props.initialTemplate);
	// 	}
	// }, [props.initialTemplate]);

	useAsync(async () => {
		if (selectedTemplate === "") {
			void sfv("html", undefined);
			void sfv("parameters", {});
			void sfv("text", "");
		} else {
			void sfv("text", "");
			const template = await API.Mail.GetTemplate(props.auth, {
				file: selectedTemplate,
				student_uuid: props.studentUuid,
				branch_uuid: props.auth.currentBranch.uuid,
			});
			void sfv("html", template.html);
			void sfv("subject", template.subject ?? "");

			void sfv(
				"parameters",
				Object.fromEntries(
					Array.from(template.html.matchAll(/\[PARAMETER_(.*?)\]/gu)).map(x => [
						x[1],
						query.get(`param_${x[1]}`) ?? "",
					]),
				),
			);
		}
	}, [props.auth, sfv, props.studentUuid, query, selectedTemplate]);

	const lastSentTemplate =
		lastSentMail?.last_sent_email_template && Object.entries(lastSentMail.last_sent_email_template);

	return (
		<FormLoader formik={formik} disabled={sent} saveButtonLabel={sent ? "Email successfully sent" : "Send"}>
			<span>{studentName}</span>
			{lastSentTemplate && templateList && (
				<>
					<span>
						Last sent mail:
						{lastSentTemplate.length === 0 && " never"}
					</span>
					{lastSentTemplate
						.map(pair => {
							return { found: templateList?.find(x => x.file === pair[0]), time: pair[1] };
						})
						.filter(item => item.found)
						.map(item => (
							<div key={item.found?.file}>{`${item.found?.name ?? "undefined"}: ${new Date(
								item.time,
							).toLocaleString()}`}</div>
						))}
				</>
			)}
			<LabelledField label="Template">
				<TemplateSelectorTree
					disabled={sent}
					templates={templateList}
					selectedTemplate={selectedTemplate}
					onChange={e => setSelectedTemplate(e ?? "")}
				/>
			</LabelledField>
			<TextField field={getSubField(formik, "subject")} label="Subject" disabled={sent} />
			{Object.entries(formik.values.parameters).map(([k, v]) => {
				if (k.toLowerCase().endsWith("course")) {
					return (
						<>
							{k}
							<CourseSelectorTree
								auth={props.auth}
								onChange={async val => {
									const newval = { ...formik.values.parameters };
									newval[k] = val?.name ?? "";
									await formik.setFieldValue("parameters", newval);
								}}
								selectedName={v}
							/>
						</>
					);
				}
				return (
					<div>
						<span>{k}</span>
						<Input
							onChange={async val => {
								const newval = { ...formik.values.parameters };

								if (k.toLowerCase().includes("money")) {
									newval[k] = val.target.value.replace(/^\$*/u, "$");
								} else {
									newval[k] = val.target.value;
								}

								await formik.setFieldValue("parameters", newval);
							}}
							value={v}
						/>
					</div>
				);
			})}
			{formik.values.html !== undefined && (
				<>
					<span>HTML Preview</span>
					<iframe
						title="template"
						srcDoc={formatMailHtml(formik.values.html, formik.values.parameters)}
						height={400}
					/>
				</>
			)}
			{selectedTemplate === "" && (
				<TextAreaField
					field={getSubField(formik, "text") as SubFieldType<string>}
					label="Body"
					disabled={sent}
					placeholder="Warning: the message typed here will be lost when you switch students or templates."
				/>
			)}
			{props.invoice !== undefined && <>Attachment: invoice</>}
			{props.report_card !== undefined && <>Attachment: report card</>}
		</FormLoader>
	);
}
