import { Menu, Item, Submenu } from "react-contexify";
import { useHistory } from "react-router-dom";
import { API } from "../../api-types";
import { currentRoleAtLeast, AuthContextLoggedIn } from "../../auth";
import { useQuery } from "../../util";
import { ModalsContext, useModals } from "../modal/modals-provider";
import { cancelAndMakeup } from "./enrollment-data";
import {
	ExtendedPropsSectionSession,
	getSessionStatus,
	ExtendedPropsTask,
	ExtendedPropsTicket,
	StudentSessionDataWithExtras,
} from "./events-calendar";

export function DatetimeContextMenu(props: {
	id: string;
	target: { dateStr: string; date: Date; allDay: boolean } | undefined;
	onCreateTicketModalOpen: (t: NonNullable<typeof props.target>) => void;
	onDismiss?: () => void;
	auth: AuthContextLoggedIn;
}) {
	const history = useHistory();
	const query = useQuery();

	return (
		<Menu id={props.id} onHidden={props.onDismiss}>
			{props.target && (
				<>
					{currentRoleAtLeast(props.auth, "reception") && (
						<>
							<Item
								onClick={() => {
									if (!props.target) return;
									const student = query.get("enroll-student");

									if (student !== null) {
										history.push(`/new-enrollment?date=${props.target.dateStr}&student=${student}`);
									} else {
										history.push(`/new-enrollment?date=${props.target.dateStr}`);
									}
								}}
							>
								Create course
							</Item>
							<Item
								onClick={() => {
									if (!props.target) return;
									props.onCreateTicketModalOpen(props.target);
								}}
							>
								Create task
							</Item>
						</>
					)}
				</>
			)}
		</Menu>
	);
}

export function makeStudentContextItems(props: {
	status: string[];
	modals: ModalsContext;
	auth: AuthContextLoggedIn;
	target: StudentSessionDataWithExtras;
	history: ReturnType<typeof useHistory>;
	onReportModalOpen: (t: NonNullable<typeof props.target>) => void;
	onEnrollmentReportModalOpen: (t: NonNullable<typeof props.target>) => void;
	onCreateTicketModalOpen: (t: NonNullable<typeof props.target>) => void;
	onManagerSMSModalOpen: (t: NonNullable<typeof props.target>) => void;
	onQrCodeModalOpen: (t: NonNullable<typeof props.target>) => void;
	onEnrollmentCommentModalOpen: (t: NonNullable<typeof props.target>) => void;
	onEnrollmentGridModalOpen: (t: NonNullable<typeof props.target>) => void;
	onRefresh?: () => void;
}) {
	return (
		<>
			{currentRoleAtLeast(props.auth, "reception") && (
				<>
					<Item
						onClick={() => {
							props.history.push(
								`/students/${props.target.enrollment.student.uuid}?enrollment=${props.target.enrollment.uuid}`,
							);
						}}
					>
						View student
					</Item>
					<Item
						onClick={async () => {
							await cancelAndMakeup(
								props.auth,
								props.modals,
								props.target,
								props.target.enrollment,
								() => {
									props.onRefresh?.();
								},
							);
						}}
					>
						{props.target.is_cancelled ? "Undo absence" : "Absence and makeup"}
					</Item>
				</>
			)}
			<Item
				onClick={() => {
					props.onReportModalOpen(props.target);
				}}
			>
				Attendance
			</Item>
			<Item
				onClick={() => {
					props.onQrCodeModalOpen(props.target);
				}}
			>
				View QR code
			</Item>
			<Item
				onClick={() => {
					props.onCreateTicketModalOpen(props.target);
				}}
			>
				Create internal message
			</Item>
			<Item
				onClick={() => {
					props.onManagerSMSModalOpen(props.target);
				}}
			>
				Send SMS to manager
			</Item>
			<Item
				onClick={() => {
					props.onEnrollmentReportModalOpen(props.target);
				}}
			>
				Create term report
			</Item>
			<Item
				onClick={() => {
					props.onEnrollmentCommentModalOpen(props.target);
				}}
			>
				View special notes
			</Item>
			<Item
				onClick={() => {
					props.onEnrollmentGridModalOpen(props.target);
				}}
			>
				View comments grid
			</Item>
		</>
	);
}

export function SectionAndSessionContextMenu(props: {
	section_menu_id: string;
	session_menu_id: string;
	section_target: ExtendedPropsSectionSession["section_session"] | undefined;
	session_target: StudentSessionDataWithExtras | undefined;

	onCreateTicketModalOpen: (t: ExtendedPropsSectionSession["section_session"]) => void;

	onManagerSMSModalOpen: (t: StudentSessionDataWithExtras) => void;
	onReportModalOpen: (t: StudentSessionDataWithExtras) => void;
	onEnrollmentReportModalOpen: (t: StudentSessionDataWithExtras) => void;
	onCreateStudentTicketModalOpen: (t: StudentSessionDataWithExtras) => void;
	onQrCodeModalOpen: (t: StudentSessionDataWithExtras) => void;
	onEnrollmentCommentModalOpen: (t: StudentSessionDataWithExtras) => void;
	onEnrollmentGridModalOpen: (t: StudentSessionDataWithExtras) => void;

	onDismiss?: () => void;
	onRefresh?: () => void;
	auth: AuthContextLoggedIn;
}) {
	const modals = useModals();
	const history = useHistory();
	const query = useQuery();

	const section_target = props.section_target;
	const session_target = props.session_target;

	return (
		<>
			<Menu id={props.section_menu_id} onHidden={props.onDismiss}>
				{section_target && (
					<>
						<Item
							onClick={() => {
								props.onCreateTicketModalOpen(section_target);
							}}
						>
							Create internal message
						</Item>
						<Item
							onClick={async () => {
								const cls = await API.Branch.Classroom.Get(props.auth, {
									uuid: section_target.section.classroom.uuid,
								});
								window.open(
									cls.daily_links.find(
										x => x.day_of_week === new Date(section_target.datetime).getDay(),
									)?.link,
								);
							}}
						>
							Go to course material
						</Item>
						{section_target.student_sessions.map(x => {
							const status = getSessionStatus(x, props.auth);

							return (
								<Submenu key={x.uuid} label={x.enrollment.student.name}>
									{makeStudentContextItems({
										...props,
										status,
										modals,
										onCreateTicketModalOpen: props.onCreateStudentTicketModalOpen,
										target: x,
										history,
									})}
								</Submenu>
							);
						})}
						{currentRoleAtLeast(props.auth, "reception") && (
							<>
								<Item
									onClick={() => {
										history.push(`/section/${section_target.section.uuid}`);
									}}
								>
									View section
								</Item>
								<Item
									onClick={() => {
										const student = query.get("enroll-student");
										if (student !== null) {
											history.push(
												`/new-enrollment?section=${section_target.section.uuid}&student=${student}`,
											);
										} else {
											history.push(`/new-enrollment?section=${section_target.section.uuid}`);
										}
									}}
								>
									Enroll student in section
								</Item>
							</>
						)}
					</>
				)}
			</Menu>
			<Menu id={props.session_menu_id}>
				{session_target &&
					makeStudentContextItems({
						...props,
						modals,
						history,
						target: session_target,
						status: getSessionStatus(session_target, props.auth),
						onCreateTicketModalOpen: props.onCreateStudentTicketModalOpen,
					})}
			</Menu>
		</>
	);
}

export function TaskContextMenu(props: {
	id: string;
	target: ExtendedPropsTask["task"] | undefined;
	onReplyModalOpen: (t: NonNullable<typeof props.target>) => void;
	onDismiss?: () => void;
}) {
	const target = props.target;
	return (
		<Menu id={props.id} onHidden={props.onDismiss}>
			{target && (
				<>
					<Item
						onClick={() => {
							props.onReplyModalOpen(target);
						}}
					>
						View/Reply
					</Item>
				</>
			)}
		</Menu>
	);
}

export function TicketContextMenu(props: {
	id: string;
	target: ExtendedPropsTicket["ticket"] | undefined;

	onReplyModalOpen: (t: NonNullable<typeof props.target>) => void;
	onDismiss?: () => void;
}) {
	const target = props.target;

	return (
		<Menu id={props.id} onHidden={props.onDismiss}>
			{target && (
				<>
					<Item
						onClick={() => {
							props.onReplyModalOpen(target);
						}}
					>
						View/Reply
					</Item>
				</>
			)}
		</Menu>
	);
}
