import { useEffect, useState } from "react";
import { DatePicker, Modal } from "antd";
import moment from "moment";

export type SessionListEditorChanges = {
	create: { datetime: Date; duration: number }[];
	delete: string[];
	update: { datetime: Date; uuid: string; duration: number }[];
};
type EditorSession = {
	datetime: Date;
	create: boolean;
	delete: boolean;
	update: boolean;
	uuid: string;
	duration: number;
};

export function SessionListEditor(props: {
	open: boolean;
	duration: number;
	onCommit: (x: SessionListEditorChanges) => void;
	onCancel: () => void;
	sessionList: { uuid: string; datetime: string; duration: number }[];
}) {
	const [sessions, setSessions] = useState<EditorSession[]>([]);
	const list = props.sessionList;

	useEffect(() => {
		if (!props.open) return;

		const createInitSessions = (x: typeof list) => {
			return x.map(s => ({
				datetime: new Date(s.datetime),
				create: false,
				delete: false,
				update: false,
				duration: s.duration,
				uuid: s.uuid,
			}));
		};

		setSessions(createInitSessions(list));
	}, [list, props.open]);

	const divs = sessions.map((s, index) => (
		<div key={s.uuid}>
			<DatePicker
				showTime={{ format: "HH" }}
				value={moment(s.datetime)}
				onSelect={date => {
					if (!sessions[index].delete) {
						const copy = sessions.slice();
						copy[index].datetime = date.toDate();
						if (!copy[index].create) {
							copy[index].update = true;
						}

						setSessions(copy);
					}
				}}
			/>
			{s.update ? "(WILL BE CHANGED)" : null}
			{s.delete ? "(WILL BE DELETED)" : null}
			{s.create ? "(WILL BE CREATED)" : null}
			<button
				onClick={() => {
					if (!sessions[index].create) {
						const copy = sessions.slice();
						copy[index].delete = true;
						setSessions(copy);
					} else {
						const copy = sessions.slice();
						copy.splice(index, 1);
						setSessions(copy);
					}
				}}
			>
				DELETE THIS
			</button>
		</div>
	));

	return (
		<Modal
			destroyOnClose
			open={props.open}
			okText="Save"
			onOk={() => {
				const changes: SessionListEditorChanges = { update: [], delete: [], create: [] };
				for (const val of sessions) {
					if (val.create) {
						changes.create.push({ datetime: val.datetime, duration: val.duration });
					} else if (val.delete) {
						changes.delete.push(val.uuid);
					} else if (val.update) {
						changes.update.push({ datetime: val.datetime, uuid: val.uuid, duration: val.duration });
					}
				}
				props.onCommit(changes);
			}}
			onCancel={props.onCancel}
		>
			<div>{divs}</div>
			<div>
				<button
					onClick={() => {
						setSessions([
							...sessions,
							{
								datetime: new Date(),
								create: true,
								delete: false,
								update: false,
								duration: props.duration,
								uuid: "",
							},
						]);
					}}
				>
					add new session
				</button>
			</div>
		</Modal>
	);
}
