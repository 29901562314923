import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

type NullPart<T> = T & (null | undefined);

// Ensures unnecessary checks aren't performed - only a valid call if
// value could be nullable *and* could be non-nullable
type MustBeAmbiguouslyNullable<T> = NullPart<T> extends never ? never : NonNullable<T> extends never ? never : T;

export function hasValue<T>(value: MustBeAmbiguouslyNullable<T>): value is NonNullable<MustBeAmbiguouslyNullable<T>> {
	return (value as unknown) !== undefined && (value as unknown) !== null;
}

export function hasValueFn<T, A>(
	value: MustBeAmbiguouslyNullable<T>,
	thenFn: (value: NonNullable<T>) => A,
): A | undefined {
	// Undefined matches .? syntax result
	return hasValue(value) ? thenFn(value) : undefined;
}

export function bind<T, A>(value: T, fn: (x: NonNullable<T>) => A): A | (T & (undefined | null)) {
	if (value !== undefined && value !== null) {
		return fn(value);
	}
	return value as never;
}

export const sessionFormatter: Intl.DateTimeFormatOptions = {
	weekday: "short",
	year: "numeric",
	month: "short",
	day: "numeric",
	hour: "numeric",
	minute: "2-digit",
	timeZoneName: "short",
};

export function groupBy<T, K>(things: T[], criterion: (x: T) => K) {
	return [
		...things.reduce((a, c) => {
			const crit = criterion(c);

			if (a.has(crit)) {
				a.get(crit)?.push(c);
			} else {
				a.set(crit, [c]);
			}

			return a;
		}, new Map<K, T[]>()),
	];
}

export function useQuery() {
	const location = useLocation();
	return useMemo(() => new URLSearchParams(location.search), [location.search]);
}

export function parseDateString(date: string) {
	const utcdate = new Date(date);
	const r = new Date(utcdate.getUTCFullYear(), utcdate.getUTCMonth(), utcdate.getUTCDate());
	return startOfDay(r);
}

export function formatDateLocal(d: Date) {
	return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d
		.getDate()
		.toString()
		.padStart(2, "0")}`;
}

export type ReturnPromiseType<T extends (...args: never) => unknown> = ReturnType<T> extends Promise<infer U>
	? U
	: never;

export function stripPhone(s: string) {
	return s.replaceAll(/[^0-9]+/gu, "");
}

export function formatDateYMD(date: Date) {
	const d = date.getDate();
	const m = date.getMonth() + 1;
	const y = date.getFullYear();
	return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
}

export function usePromise(x: () => Promise<void>): void;
export function usePromise<T>(x: () => Promise<T | undefined>): T | undefined;
export function usePromise<T>(x: () => Promise<T>, defaultValue: T): T;
export function usePromise<T>(x: () => Promise<T>, defaultValue: T | undefined = undefined): T {
	const [val, setVal] = useState<T>(defaultValue as T);

	useEffect(() => {
		void (async () => {
			setVal(await x());
		})();
	}, [x]);

	return val;
}

type UPE<T> = [T, { refresh: () => Promise<void>; set: (x: T) => void }];
type UPEWithUpdater<T> = [T, { refresh: () => Promise<void>; set: (x: T) => void; update: (x: T) => Promise<void> }];

export function usePromiseExtended<T>(x: () => Promise<T | undefined>): UPE<T | undefined>;
export function usePromiseExtended<T>(x: () => Promise<T>, defaultValue: T): UPE<T>;
export function usePromiseExtended<T>(
	x: () => Promise<T>,
	defaultValue: T,
	updater: (x: T) => Promise<void>,
): UPEWithUpdater<T>;
export function usePromiseExtended<T, TUpdater extends ((x: T) => Promise<void>) | undefined = undefined>(
	x: () => Promise<T>,
	defaultValue?: T,
	updater?: TUpdater,
): TUpdater extends undefined ? UPE<T> : UPEWithUpdater<T> {
	const [val, set] = useState<T>(defaultValue as T);
	const refresh = useCallback(async () => {
		set(await x());
	}, [x]);
	const update = useCallback(
		async (newVal: T) => {
			set(newVal);
			await updater?.(newVal);
			await refresh();
		},
		[updater, refresh],
	);

	useEffect(() => {
		void refresh();
	}, [refresh]);

	return (updater ? [val, { refresh, set, update }] : [val, { refresh, set }]) as never;
}

export function unformatMoney(x: string) {
	return x.replace(/[^0-9.-]/gu, "");
}

export const strippedStringSchema = Yup.string()
	.test("trimmed", "Please remove spaces from the ends", x => x === x?.trim())
	.default("");

export function endOfDay(date: Date) {
	const ret = new Date(date);
	ret.setHours(23);
	ret.setMinutes(59);
	ret.setSeconds(59);
	ret.setMilliseconds(999);
	return ret;
}

export function startOfDay(date: Date) {
	const ret = new Date(date);
	ret.setHours(0);
	ret.setMinutes(0);
	ret.setSeconds(0);
	ret.setMilliseconds(0);
	return ret;
}

export function getTotalMinutes(date: Date) {
	return date.getHours() * 60 + date.getMinutes();
}
export function setTotalMinutes(date: Date, minutes: number) {
	const ret = startOfDay(date);
	ret.setMinutes(minutes);

	return ret;
}
export function addMinutes(date: Date, minutes: number) {
	const ret = new Date(date);
	ret.setMinutes(ret.getMinutes() + minutes);
	return ret;
}

export function convertTime(time: number) {
	const r = new Date();
	r.setHours(0);
	r.setSeconds(0);
	r.setMinutes(time);
	return r;
}

export function formatTime(time: number) {
	const r = new Date();
	r.setHours(0);
	r.setSeconds(0);
	r.setMinutes(time);
	return r.toLocaleTimeString([], { timeStyle: "short" });
}

export type SectionScheduleType = "WEEKLY" | "DAILY" | "BIWEEKLY";

export function generateSessions(
	start: Date,
	sessionCount: number,
	sessionDuration: number,
	holidays: { start_datetime: string; end_datetime: string }[],
	scheduleType: SectionScheduleType,
	options?: {
		ignoreHolidays?: boolean;
	},
) {
	let sessionInterval = 7;
	if (scheduleType === "DAILY") {
		sessionInterval = 1;
	} else if (scheduleType === "BIWEEKLY") {
		sessionInterval = 14;
	}

	const sessions = [];
	const curDate = new Date(start);
	const nholidays = holidays.map(x => ({
		start: new Date(x.start_datetime),
		end: new Date(x.end_datetime),
	}));

	let startDateShifted = false;

	while (sessions.length < sessionCount) {
		let overlap = false;

		if (options?.ignoreHolidays !== true) {
			for (const hol of nholidays) {
				if (curDate >= hol.start && curDate <= hol.end) {
					overlap = true;
					break;
				}
			}
		}

		if (overlap) {
			curDate.setDate(curDate.getDate() + sessionInterval);

			if (sessions.length === 0) {
				startDateShifted = true;
			}
			continue;
		}

		sessions.push({
			datetime: setTotalMinutes(curDate, getTotalMinutes(start)).toISOString(),
			duration: sessionDuration,
		});

		curDate.setDate(curDate.getDate() + sessionInterval);
	}

	return { sessions, startDateShifted };
}

export function regularTimeDescriptor(datetime: Date, schedule_type: SectionScheduleType) {
	if (schedule_type === "WEEKLY") {
		return `${formatDOW(datetime.getDay())}s at ${datetime.toLocaleTimeString([], { timeStyle: "short" })}`;
	} else if (schedule_type === "BIWEEKLY") {
		return `${formatDOW(datetime.getDay())}s at ${datetime.toLocaleTimeString([], {
			timeStyle: "short",
		})} (Biweekly)`;
	} else if (schedule_type === "DAILY") {
		return `Daily at ${datetime.toLocaleTimeString([], { timeStyle: "short" })}`;
	}

	return "Error";
}

export function formatDOW(dow: number) {
	return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][dow];
}
