import { useEffect, useRef, useState } from "react";
import { ReportResponse } from "../modal/session-report-editor-modal";
import TextArea from "antd/lib/input/TextArea";
import { Form, Tooltip } from "antd";

const consultingCategories = [
	{
		section: "Course selection",
		reminder: `Take challenging classes - Competitive colleges like those in the Ivy League
			like to see that students take the most difficult classes they can while in
			college. Students should choose courses that will challenge them and help
			them prepare for college courses covering similar subjects.`,
	},
	{
		section: "GPA management",
		reminder: `Book grades, estimate grades, seek help on challenging subjects`,
	},
	{
		section: "Leadership",
		reminder: `Join sports, clubs, or other student organizations`,
	},
	{
		section: "Seeking help at school",
		reminder: `Introduce yourself to your school counselor.
			Build relationships with your teachers.
			Identify who will write your letters of recommendation.
			Stay on track by meeting with your school counselor.
			Discuss goals with your school counselor.
			Ask teachers and school counselor for letters of recommendation.`,
	},
	{
		section: "Volunteering",
		reminder: `Introduce yourself to your community center/library.
			Search for volunteering job`,
	},
	{
		section: "Standardized tests",
		reminder: `Take mock test (G8)PSAT, (G9)SAT, (G10)ACT,AP.
			Book test.
			Take the SAT and ACT tests.
			Take AP courses.
			Retake SAT and ACT tests.
			Take AP® Tests`,
	},
	{
		section: "Interests, values and passion",
		reminder: `Take skill assessments.
			Start exploring potential career paths.
			Start building your college list.
			Have a serious talk with your parents about how you'll pay for college.`,
	},
	{
		section: "Extracurriculars",
		reminder: `Stay engaged with your extracurriculars.
			Pursue leadership roles in your extracurriculars.
			Beaver contest, Robosports robotics contest, CCL Python competition,
			CCC programming competition, Modo 3D competition, Rarespark Sciencefaire Competition, GVRSF Sciencefaire`,
	},
	{
		section: "Resume and essay",
		reminder: `Start building your college resume.
			Start drafting for common app essay questions.
			Volunteer, work, and continue to add depth to your resume.`,
	},
	{
		section: "Summer",
		reminder: `Attend summer prep classes for the PSAT, ACT®, and SAT® tests.
			Volunteer.
			Go on college visits (virtual or in person).
			Take SAT® Subject Tests.
			Starting thinking about what your major will be.
			Attend a college summer course or camp.
			Get a head start on your college applications.
			Start writing your college application essays.
			Finalize your college list.
			Find a job or internship related to your field of study.`,
	},
	{
		section: "G12 last call",
		reminder: `Decide if you'll apply Early Decision or Early Action.
			Take challenging classes.
			Stay ahead of your deadlines.
			Tell your school where to send your transcripts.
			Edit and perfect your application essays.
			Apply for scholarships.
			Finalize and submit your college applications..
			Schedule and prepare for college interviews (if applicable).
			Follow any additional instructions you receive from colleges.
			Check the mail for acceptance letters and scholarship offers.
			Review scholarship offers and financial aid awards.
			Make your final college decision.
			Complete next steps to finalize your acceptance.
			Accept financial aid awards and make decisions about housing.
			Graduate from high school.
			Celebrate this major milestone!`,
	},
	{
		section: "Thank everyone who helped you along the way!",
		reminder: "",
	},
];

export function ConsultingReport(props: {
	active: boolean;
	report: ReportResponse | undefined;
	attendance: boolean | "late" | null;
	onUpdateValues: (valid: boolean, x: Record<string, unknown>) => void;
}) {
	const [sections, setSections] = useState<string[]>([]);
	const [comments, setComments] = useState<string[]>([]);

	useEffect(() => {
		const prevEntries: [string, string][] | undefined = (props.report?.data as any)?.entries;

		if (prevEntries !== undefined && prevEntries instanceof Array) {
			const prevSections: string[] = [];
			const prevComments: string[] = [];

			prevEntries.forEach((x, i) => {
				prevSections.push(x[0]);
				prevComments.push(x[1]);
			});

			setSections(prevSections);
			setComments(prevComments);
		} else {
			setSections(consultingCategories.map(x => x.section));
			setComments(consultingCategories.map(_ => "Update: \nTo-do: "));
		}
	}, [props.report]);

	const propsRef = useRef(props);
	propsRef.current = props;

	useEffect(() => {
		if (propsRef.current.active) {
			propsRef.current.onUpdateValues(true, {
				entries: sections.map((x, i) => [x, comments[i]]),
			});
		}
	}, [sections, comments]);

	return (
		<>
			{sections.map((x, i) => (
				<Form.Item
					label={
						<Tooltip
							title={consultingCategories.find(y => y.section === x)?.reminder ?? ""}
							destroyTooltipOnHide
							placement="left"
						>
							{x}
						</Tooltip>
					}
					key={i}
					style={{ marginBottom: 5 }}
				>
					<TextArea
						value={comments[i]}
						rows={2}
						onChange={e => {
							const newCats = [...comments];
							newCats[i] = e.target.value;
							setComments(newCats);
						}}
					/>
				</Form.Item>
			))}
		</>
	);
}
