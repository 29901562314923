import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, Select } from "antd";
import { useState } from "react";
import { registerUpload } from "../../api";
import { API } from "../../api-types";
import { AuthContextLoggedIn, Authenticated } from "../../auth";

const UploadData = registerUpload<unknown>("upload/backup");
const { confirm } = Modal;

export function AdminPage(props: { auth: AuthContextLoggedIn }) {
	const [file, setFile] = useState<File>();
	const [branch, setBranch] = useState<string>();

	return (
		<Authenticated as="root">
			<div className="content-flex-vertical" style={{ width: 400 }}>
				<div>
					Upload new branch data
					<input
						type="file"
						onChange={e => setFile((e.target.files ? e.target.files[0] : undefined) ?? undefined)}
					/>
					<button
						onClick={async () => {
							if (!props.auth.loggedIn) return;
							await UploadData(props.auth, file!);
						}}
					>
						upload
					</button>
					<br />
					Note: you need to relog in to see new branch appear.
					<br />
					Note: Please wait two minutes between any uploads.
				</div>
				<div>
					Delete a branch
					<div>
						<Select
							options={
								props.auth.loggedIn
									? props.auth.auth.branches.map(x => ({ label: x.name, value: x.uuid }))
									: []
							}
							value={branch}
							style={{ width: 300 }}
							onChange={e => setBranch(e)}
						/>
					</div>
					<button
						onClick={() => {
							if (!props.auth.loggedIn) return;
							if (branch === undefined) return;

							confirm({
								title: "Do you really want to delete?",
								icon: <ExclamationCircleOutlined />,
								content: "This is not reversible",
								async onOk() {
									await API.Admin.DeleteBranch(props.auth, { uuid: branch });
								},
								onCancel() {},
							});
						}}
					>
						delete
					</button>
					Note: it might take ~1 min to delete a branch. You need to relog in to see the branch gone.
				</div>
			</div>
		</Authenticated>
	);
}
