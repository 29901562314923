import { TreeSelect, Tree } from "antd";
import { useCallback, useMemo } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import { ReturnPromiseType, usePromise } from "../../util";

interface TreeData {
	title: string;
	key: string;
	value: string;
	selectable?: boolean;
	children?: TreeData[];
	isLeaf: boolean;
}

function makeTree(
	courses: ReturnPromiseType<typeof API.Course.GetAll> | undefined,
	selectedCourses: string[],
	branchesSelectable: boolean,
): TreeData[] {
	if (!courses) {
		return selectedCourses.map(x => ({
			title: x,
			value: x,
			key: x,
			selectable: true,
			isLeaf: true,
		}));
	}

	const rootOnline: TreeData = {
		title: "Online",
		value: "NODEROOT1",
		key: "NODEROOT1",
		children: [],
		selectable: branchesSelectable,
		isLeaf: false,
	};
	const rootInperson: TreeData = {
		title: "In-Person",
		value: "NODEROOT2",
		key: "NODEROOT2",
		isLeaf: false,
		children: [],
		selectable: branchesSelectable,
	};
	const rootDeleted: TreeData = {
		title: "Deleted",
		value: "NODEROOT3",
		key: "NODEROOT3",
		isLeaf: false,
		children: selectedCourses
			.filter(x => !courses.some(y => y.uuid === x))
			.map(x => ({
				title: x,
				value: x,
				key: x,
				selectable: true,
				isLeaf: true,
			})),
		selectable: branchesSelectable,
	};

	let value = 0;
	courses.forEach(x => {
		let split = x.name.split(" ");

		let current: TreeData;
		if (split[0].toLowerCase() === "online") {
			current = rootOnline;
			split = split.slice(1);
		} else {
			current = rootInperson;
		}

		split.forEach((y, i, a) => {
			const children = current.children;
			if (i < a.length - 1) {
				const found = children!.find((z: TreeData) => z.title === y && z.children);

				if (!found) {
					current = {
						title: y,
						value: `NODE${value}`,
						key: `NODE${value}`,
						children: [],
						selectable: branchesSelectable,
						isLeaf: false,
					};
					value += 1;
					children!.push(current);
				} else {
					current = found;
				}
			} else {
				children!.push({
					key: x.uuid,
					isLeaf: true,
					title: x.name,
					value: x.uuid,
				});
			}
		});
	});

	return [
		...(function* () {
			if (rootInperson.children!.length > 0) {
				yield rootInperson;
			}
			if (rootOnline.children!.length > 0) {
				yield rootOnline;
			}
			if (rootDeleted.children!.length > 0) {
				yield rootDeleted;
			}
		})(),
	];
}

export function CourseSelectorTree(props: {
	value?: string;
	selectedName?: string;
	defaultValue?: string;
	disabled?: boolean;
	onChange: (selected: ReturnPromiseType<typeof API.Course.GetAll>[number] | undefined) => void;
	auth: AuthContextLoggedIn;
}) {
	const courses = usePromise(useCallback(() => API.Course.GetAll(props.auth, {}), [props.auth]));

	const tree = useMemo(
		() => makeTree(courses, props.value !== undefined ? [props.value] : [], false),
		[courses, props.value],
	);

	return (
		<>
			{courses && (
				<TreeSelect
					virtual={false}
					style={{ width: "100%" }}
					treeData={tree}
					allowClear
					showSearch
					treeNodeFilterProp="title"
					defaultValue={props.defaultValue}
					value={
						props.value ??
						(props.selectedName !== undefined
							? courses.find(x => x.name === props.selectedName)?.uuid
							: undefined)
					}
					disabled={props.disabled}
					onChange={selected => {
						props.onChange(selected !== undefined ? courses.find(c => c.uuid === selected) : undefined);
					}}
				/>
			)}
		</>
	);
}

export function CourseSelectorDirectoryTree(props: {
	value?: string;
	onChange: (selected: string | undefined) => void;
	courses: ReturnPromiseType<typeof API.Course.GetAll>;
}) {
	const tree = useMemo(
		() => makeTree(props.courses, props.value !== undefined ? [props.value] : [], false),
		[props.courses, props.value],
	);

	return (
		<Tree.DirectoryTree
			autoExpandParent
			expandAction="click"
			style={{ width: "100%" }}
			treeData={tree}
			selectedKeys={props.value !== undefined && props.value !== "new" ? [props.value] : []}
			onSelect={selected => {
				if (selected.length < 1) return;
				props.onChange(selected[0] as string);
			}}
		/>
	);
}

export function CourseSelectorTreeMulti(props: {
	values: string[];
	disabled?: boolean;
	onChange: (selectedNodes: string[]) => void;
	auth: AuthContextLoggedIn;
}) {
	const courses = usePromise(useCallback(() => API.Course.GetAll(props.auth, {}), [props.auth]));

	const tree = useMemo(() => makeTree(courses, props.values, true), [courses, props.values]);

	return (
		<div>
			<TreeSelect
				virtual={false}
				maxTagCount={8}
				style={{ width: "100%" }}
				treeCheckable
				treeData={tree}
				value={props.values}
				allowClear
				treeNodeFilterProp="title"
				disabled={props.disabled}
				onChange={selected => {
					props.onChange(selected);
				}}
			/>
		</div>
	);
}
