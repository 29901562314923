import { DatePicker, Button, Checkbox, Modal, Select, InputNumber, Input, AutoComplete } from "antd";
import { SketchPicker } from "react-color";
import { FormikConfig, FormikValues, useFormik } from "formik";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import * as Yup from "yup";
import { bind, formatDateLocal } from "../../util";

class FormikHookPropsHelper<V extends FormikValues> {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	useFormik = (args: FormikConfig<V>) => useFormik<V>(args);
}
export type FormikHookProps<V extends FormikValues> = ReturnType<FormikHookPropsHelper<V>["useFormik"]>;

export function useSubFields<TForm extends FormikValues, TKey extends readonly (keyof TForm & string)[]>(
	formik: FormikHookProps<TForm>,
	...fieldNames: [...TKey]
): { [K in keyof TKey]: TKey[K] extends keyof TForm ? SubFieldType<TForm[TKey[K]]> : never } {
	const entry: unknown[] = [];
	for (const fieldName of fieldNames) {
		const field = formik.getFieldProps(fieldName);
		const meta = formik.getFieldMeta(fieldName);

		entry.push({
			// eslint-disable-next-line react-hooks/rules-of-hooks
			onChange: useCallback(
				(x: unknown) => field.onChange({ target: { name: fieldName, value: x } }),
				[field, fieldName],
			),
			// eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/rules-of-hooks
			onBlur: useCallback((x: unknown) => field.onBlur({ target: { name: fieldName, value: x } }), []),
			// eslint-disable-next-line react-hooks/rules-of-hooks
			onFocus: useCallback(() => {}, []),
			value: field.value,
			error: meta.error,
			name: field.name,
		});
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useMemo(() => fieldNames.map((_x, i) => entry[i]), []) as never;
}

export function getSubField<TForm extends FormikValues, TKey extends keyof TForm & string>(
	formik: FormikHookProps<TForm>,
	fieldName: TKey,
	onChange?: (x: TForm[TKey]) => Promise<void>,
): SubFieldType<TForm[TKey]> {
	const field = formik.getFieldProps(fieldName);
	const meta = formik.getFieldMeta(fieldName);

	return {
		onChange: async (x: TForm[TKey]) => {
			field.onChange({ target: { name: fieldName, value: x } });
			await onChange?.(x);
		},
		onBlur: (x: TForm[TKey]) => field.onBlur({ target: { name: fieldName, value: x } }),
		onFocus: () => {},
		value: field.value,
		error: meta.error,
		name: field.name,
	};
}

export interface SubFieldType<T> {
	onChange: (x: T) => void;
	onBlur: (x: T) => void;
	onFocus: (x: T) => void;
	value: T;
	error: string | undefined;
	name: string;
}

export function LabelledField(props: { children: React.ReactElement; label?: string; error?: string }) {
	const err = props.error !== undefined && props.error !== "";
	return (
		<div>
			{props.label !== undefined && <span>{props.label}</span>}
			<div className={err ? "field-error" : ""} style={{ width: "100%" }}>
				{props.children}
			</div>
			<span style={{ color: "red", fontSize: "smaller", height: 14, marginTop: -2, display: "block" }}>
				{props.error}
			</span>
		</div>
	);
}

export const TextField = (props: {
	field: SubFieldType<string>;
	label: string;
	disabled?: boolean;
	placeholder?: string;
}) => {
	return (
		<LabelledField label={props.label} error={props.field.error}>
			<Input
				onChange={e => props.field.onChange(e.target.value)}
				onBlur={e => props.field.onBlur(e.target.value)}
				onFocus={e => props.field.onFocus(e.target.value)}
				value={props.field.value}
				disabled={props.disabled}
				placeholder={props.placeholder}
			/>
		</LabelledField>
	);
};

export const AutoCompleteTextField = (props: {
	field: SubFieldType<string>;
	label: string;
	disabled?: boolean;
	placeholder?: string;
	options: string[];
}) => {
	return (
		<LabelledField label={props.label} error={props.field.error}>
			<AutoComplete
				style={{ width: "100%" }}
				options={props.options.map(x => ({ value: x }))}
				onSelect={(e: string) => props.field.onChange(e)}
			>
				<Input
					onChange={e => props.field.onChange(e.target.value)}
					onBlur={e => props.field.onBlur(e.target.value)}
					onFocus={e => props.field.onFocus(e.target.value)}
					value={props.field.value}
					disabled={props.disabled}
					placeholder={props.placeholder}
				/>
			</AutoComplete>
		</LabelledField>
	);
};

export const PasswordField = (props: { field: SubFieldType<string>; label: string; disabled?: boolean }) => {
	return (
		<LabelledField label={props.label} error={props.field.error}>
			<Input.Password
				onChange={e => props.field.onChange(e.target.value)}
				onBlur={e => props.field.onBlur(e.target.value)}
				onFocus={e => props.field.onFocus(e.target.value)}
				value={props.field.value}
				disabled={props.disabled}
			/>
		</LabelledField>
	);
};

export const TextAreaField = (props: {
	field: SubFieldType<string>;
	label: string;
	disabled?: boolean;
	placeholder?: string;
}) => {
	return (
		<LabelledField label={props.label} error={props.field.error}>
			<Input.TextArea
				onChange={e => props.field.onChange(e.target.value)}
				onBlur={e => props.field.onBlur(e.target.value)}
				onFocus={e => props.field.onFocus(e.target.value)}
				value={props.field.value}
				disabled={props.disabled}
				placeholder={props.placeholder}
				rows={4}
			/>
		</LabelledField>
	);
};

export const DiscountField = (props: {
	field: SubFieldType<string>;
	discountTypeField: SubFieldType<"pct" | "flat">;
	label: string;
	disabled?: boolean;
}) => {
	return (
		<LabelledField label={props.label} error={props.field.error}>
			<InputNumber
				style={{ width: "100%" }}
				addonAfter={
					<Select
						value={props.discountTypeField.value}
						style={{ width: 60 }}
						onChange={v => props.discountTypeField.onChange(v)}
					>
						<Select.Option value="pct">%</Select.Option>
						<Select.Option value="flat">$</Select.Option>
					</Select>
				}
				onChange={v => props.field.onChange(v ?? "0")}
				onBlur={e => props.field.onBlur(e.target.value)}
				onFocus={e => props.field.onFocus(e.target.value)}
				value={props.field.value}
				disabled={props.disabled}
			/>
		</LabelledField>
	);
};

export const BooleanField = (props: { field: SubFieldType<boolean>; label: string; disabled?: boolean }) => {
	return (
		<LabelledField error={props.field.error}>
			<Checkbox
				onChange={e => props.field.onChange(e.target.checked)}
				checked={props.field.value}
				disabled={props.disabled}
			>
				{props.label}
			</Checkbox>
		</LabelledField>
	);
};

export const ColorField = (props: { field: SubFieldType<string>; label: string; disabled?: boolean }) => {
	return (
		<LabelledField label={props.label} error={props.field.error}>
			<div
				onClick={e => {
					if (props.disabled === true) e.preventDefault();
				}}
			>
				<SketchPicker
					onChangeComplete={e => props.field.onChange(e.hex)}
					color={props.field.value}
					disableAlpha
				/>
			</div>
		</LabelledField>
	);
};

export const NumberField = (props: {
	field: SubFieldType<number>;
	label: string;
	disabled?: boolean;
	max?: number;
	min?: number;
	precision?: number;
}) => {
	return (
		<LabelledField label={props.label} error={props.field.error}>
			<InputNumber
				style={{ width: "100%" }}
				onChange={e => {
					props.field.onChange(e ?? 0);
				}}
				max={props.max}
				precision={props.precision}
				min={props.min}
				onBlur={e => props.field.onBlur(+e.target.value)}
				onFocus={e => props.field.onFocus(+e.target.value)}
				value={props.field.value ?? 0}
				disabled={props.disabled}
			/>
		</LabelledField>
	);
};

export const SelectField = <
	Nullable extends boolean,
	T extends Nullable extends true ? number | string | undefined : number | string,
>(props: {
	field: SubFieldType<T>;
	label: string;
	disabled?: boolean;
	nullable?: boolean;
	options: { label: string; value: T }[];
	onChange?: (x: T) => void;
}) => {
	const options = useMemo(() => {
		const val = props.options.slice() as any[];
		if (props.nullable === true) {
			val.push({ label: "None", value: undefined });
		}
		return val;
	}, [props.options, props.nullable]);

	return (
		<LabelledField label={props.label} error={props.field.error}>
			<Select
				style={{ width: "100%" }}
				options={options}
				value={props.field.value}
				onChange={e => {
					props.field.onChange(e);
					props.onChange?.(e);
				}}
				disabled={props.disabled}
			/>
		</LabelledField>
	);
};

export const DateField = (props: { field: SubFieldType<string | undefined>; label: string; disabled?: boolean }) => {
	return (
		<LabelledField label={props.label} error={props.field.error}>
			<DatePicker
				style={{ width: "100%" }}
				value={bind(props.field.value, x => moment(x)) ?? undefined}
				onChange={e => props.field.onChange(e ? formatDateLocal(e.toDate()) : undefined)}
				disabled={props.disabled}
			/>
		</LabelledField>
	);
};

// TODO show diff confirmation on save

export function FormLoader<TForm extends Record<string, unknown>>(props: {
	formik: FormikHookProps<TForm>;
	disabled?: boolean;
	deleted?: boolean;
	new?: boolean;
	showDelete?: boolean;
	showUndelete?: boolean;
	deleteConfirmation?: string;
	deleteButtonLabel?: string;
	saveButtonLabel?: string;
	children: React.ReactNode;

	onUndelete?: () => Promise<void>;
	onDelete?: () => Promise<void>;
}) {
	return (
		<div className="subcontent-flex-vertical" style={{ border: "2px gray solid", borderRadius: 5, padding: 24 }}>
			{props.deleted === true && props.new !== true && <>Deleted / Hidden</>}
			{props.new === true && <>Creating New</>}
			<div className="subcontent-flex-vertical" style={{ rowGap: 0 }}>
				{props.children}
			</div>
			{props.formik.status !== undefined && <>{props.formik.status}</>}
			<Button
				type="primary"
				onClick={async () => {
					if (props.formik.isSubmitting) return;
					if (props.disabled === true) return;
					await props.formik.submitForm();
				}}
				loading={props.formik.isSubmitting}
				disabled={!props.formik.isValid || props.formik.isSubmitting || props.disabled === true}
			>
				{props.saveButtonLabel ?? "Save"}
			</Button>
			{props.showUndelete === true && props.deleted === true && props.new !== true && (
				<Button
					type="primary"
					onClick={async () => {
						if (props.formik.isSubmitting) return;
						if (props.disabled === true) return;

						await props.onUndelete?.();
					}}
					loading={props.formik.isSubmitting}
					disabled={props.formik.isSubmitting || props.disabled === true}
				>
					Undelete
				</Button>
			)}
			{props.showDelete === true && props.new !== true && (
				<Button
					type="primary"
					onClick={async () => {
						if (props.formik.isSubmitting) return;
						if (props.disabled === true) return;
						if (props.deleteConfirmation !== "") {
							if (!window.confirm(props.deleteConfirmation ?? "Are you sure you want to delete?")) return;
						}

						await props.onDelete?.();
					}}
					loading={props.formik.isSubmitting}
					disabled={props.formik.isSubmitting || props.disabled === true}
				>
					{props.deleteButtonLabel ?? "Delete"}
				</Button>
			)}
		</div>
	);
}

export function FormLoaderModal<TForm extends Record<string, unknown>>(props: {
	formik: FormikHookProps<TForm>;
	saveButtonLabel?: string;
	open: boolean;
	children: React.ReactNode;
	maskClosable?: boolean;
	cancelButtonLabel?: string;

	onCancel: () => void;
}) {
	return (
		<Modal
			destroyOnClose
			maskClosable={props.maskClosable}
			open={props.open}
			confirmLoading={props.formik.isSubmitting}
			okText={props.saveButtonLabel ?? "Save"}
			onOk={async () => {
				if (props.formik.isSubmitting) return;
				await props.formik.submitForm();
			}}
			onCancel={props.onCancel}
			okButtonProps={{ disabled: !props.formik.isValid }}
			cancelText={props.cancelButtonLabel}
		>
			<div className="subcontent-flex-vertical" style={{ rowGap: 0 }}>
				{props.children}
			</div>
		</Modal>
	);
}

export function useMyFormik<
	TForm extends FormikValues,
	TSchema extends Record<string, Yup.AnySchema<unknown, unknown, unknown>>,
>(props: {
	id?: string;
	initialValues: TForm;
	schema: Yup.ObjectSchema<TSchema>;
	disableReinitialize?: boolean;
	onCreate?: (values: TForm) => Promise<void>;
	onUpdate?: (id: string, values: TForm) => Promise<void>;
	additionalTests?: { name: string; test: (values: TForm) => { message: string; path: string } | undefined }[];
}): FormikHookProps<TForm> {
	const { initialValues, disableReinitialize } = props;

	let schema = props.schema;
	if (props.additionalTests) {
		for (const test of props.additionalTests) {
			schema = schema.test({
				name: test.name,
				test(values) {
					const result = test.test(values as never);
					if (result === undefined) return true;
					return this.createError({
						path: result.path,
						message: result.message,
					});
				},
			});
		}
	}

	return useFormik<TForm>({
		initialValues,
		enableReinitialize: disableReinitialize !== true,
		validateOnMount: true,
		validationSchema: schema,
		onSubmit: async (values, helpers) => {
			try {
				if (props.id === undefined) {
					await props.onCreate?.(values);
				} else {
					await props.onUpdate?.(props.id, values);
				}
			} catch (e: any) {
				helpers.setStatus(e.toString());
			}
		},
	});
}
