import { Button, Input, List, Select } from "antd";
import { useState, useCallback } from "react";
import { useEffect } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn, Authenticated } from "../../auth";
import { ReturnPromiseType, usePromise } from "../../util";

export function ClassroomAssignmentsPage(props: { auth: AuthContextLoggedIn }) {
	const users = usePromise(
		useCallback(() => API.User.GetAll(props.auth, { branch_uuid: props.auth.currentBranch.uuid }), [props.auth]),
	);

	const classroomsList = usePromise(
		useCallback(
			() =>
				API.Branch.Classroom.GetNames(props.auth, {
					branch_uuid: props.auth.currentBranch.uuid,
				}),
			[props.auth],
		),
	);

	const [classroom, setClassroom] = useState<ReturnPromiseType<typeof API.Branch.Classroom.Get>>();
	const [selectedTeachers, setSelectedTeachers] = useState<string[]>([]);

	useEffect(() => {
		setSelectedTeachers(classroom?.teachers.map(x => x.user.uuid) ?? []);
	}, [classroom]);

	const [dailyLinks, setDailyLinks] = useState<string[]>();

	useEffect(() => {
		if (!classroom) {
			setDailyLinks(undefined);
			return;
		}
		const links = [];
		for (let i = 0; i < 7; i++) {
			links.push("");
		}
		for (const item of classroom.daily_links) {
			links[item.day_of_week] = item.link;
		}
		setDailyLinks(links);
	}, [classroom]);

	return (
		<Authenticated as="reception">
			<div className="content-flex-horizontal">
				{classroomsList && (
					<div className="subcontent-flex-vertical" style={{ width: 300 }}>
						<div>
							<h3>Classrooms</h3>
							<h3>In-Person</h3>
							{classroomsList
								.filter(x => !x.is_online)
								.map(x => (
									<div
										key={x.uuid}
										className={"hover-list-item" + (classroom?.uuid === x.uuid ? " selected" : "")}
										onClick={async () => {
											if (!props.auth.loggedIn) return;
											setClassroom(await API.Branch.Classroom.Get(props.auth, { uuid: x.uuid }));
										}}
									>
										{x.name}
									</div>
								))}
							<h3>Online</h3>
							{classroomsList
								.filter(x => x.is_online)
								.map(x => (
									<div
										key={x.uuid}
										className={"hover-list-item" + (classroom?.uuid === x.uuid ? " selected" : "")}
										onClick={async () => {
											if (!props.auth.loggedIn) return;
											setClassroom(await API.Branch.Classroom.Get(props.auth, { uuid: x.uuid }));
										}}
									>
										{x.name}
									</div>
								))}
						</div>
					</div>
				)}

				{classroom && users && dailyLinks && (
					<div className="subcontent-flex-vertical" style={{ width: 600 }}>
						<label>Select teachers for this classroom</label>
						<div>
							<Select
								mode="multiple"
								virtual={false}
								style={{ width: "100%" }}
								options={users.map(x => {
									return { label: x.name, value: x.uuid };
								})}
								value={selectedTeachers}
								allowClear
								onChange={selected => setSelectedTeachers(selected)}
							/>
						</div>

						<label>Set course material for this classroom. You must include the https://</label>
						<List
							dataSource={dailyLinks}
							bordered
							renderItem={(item, index) => {
								return (
									<List.Item>
										<div>
											{
												[
													"Sunday",
													"Monday",
													"Tuesday",
													"Wednesday",
													"Thursday",
													"Friday",
													"Saturday",
												][index]
											}
										</div>
										<Input
											style={{ width: 400 }}
											value={dailyLinks[index]}
											onChange={x => {
												const newLinks = dailyLinks.slice();
												newLinks[index] = x.target.value;
												setDailyLinks(newLinks);
											}}
										/>
									</List.Item>
								);
							}}
						></List>

						<Button
							type="primary"
							onClick={async () => {
								if (!props.auth.loggedIn) return;
								await API.Branch.Classroom.SetDailyLinks(props.auth, {
									links: dailyLinks
										.map((x, i) => {
											return { day_of_week: i, link: x };
										})
										.filter(x => x.link !== ""),
									uuid: classroom.uuid,
								});
								await API.Branch.Classroom.SetTeacher(props.auth, {
									uuid: classroom.uuid,
									teacher_uuids: selectedTeachers,
								});
								setClassroom(undefined);
							}}
						>
							Save
						</Button>
					</div>
				)}
			</div>
		</Authenticated>
	);
}
