import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useMemo, useState } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import { ReturnPromiseType } from "../../util";

export interface CancelEnrollmentModalProps {
	target: ReturnPromiseType<typeof API.Student.Enrollment.Get>;
	open: boolean;
	onCancel: () => void;
	onSaved: () => void;
	auth: AuthContextLoggedIn;
}

export function CancelEnrollmentModal(props: CancelEnrollmentModalProps) {
	const [startDatetime, setStartDateTime] = useState<Date>();

	const remainingSessions = useMemo(
		() =>
			props.target.student_sessions.filter(cur => {
				if (!startDatetime) return false;
				return new Date(cur.section_session.datetime) >= startDatetime;
			}),
		[startDatetime, props.target],
	);

	return (
		<Modal
			destroyOnClose
			open={props.open}
			okButtonProps={{ disabled: !startDatetime }}
			onOk={() => {
				const grant = (remainingSessions.length * Math.round(props.target.section.session_duration / 30)) / 2;

				Modal.confirm({
					title: "Are you sure you want to cancel the remaining sessions?",
					icon: <ExclamationCircleOutlined />,
					content: `${grant} credit will be granted`,
					okText: "Yes",
					okType: "danger",
					cancelText: "No",
					onOk: async () => {
						if (!props.auth.loggedIn) return;

						await API.Student.Enrollment.Update(props.auth, {
							uuid: props.target.uuid,
							sessions: { delete: remainingSessions.map(x => x.uuid) },
						});

						const newCredits = await API.Student.ModifyCredit(props.auth, {
							branch_uuid: props.auth.currentBranch.uuid,
							uuid: props.target.student.uuid,
							add: grant,
						});

						await API.Ticket.Create(props.auth, {
							branch_uuid: props.target.branch.uuid,
							student_uuid: props.target.student.uuid,
							enrollment_uuid: props.target.uuid,
							section_uuid: props.target.section.uuid,
							closed: true,
							recipient_roles: ["reception"],
							recipients: [],
							type: "note",
							title: `Long-term absence`,
							text: [
								`${remainingSessions.length} sessions cancelled
${remainingSessions.map(x => `- ${new Date(x.section_session.datetime).toLocaleDateString()}`).join("\n")}`,
								`${grant} credits granted; current balance: ${newCredits.credits}`,
							],
						});

						props.onSaved();

						window.open(
							`/students/${props.target.student.uuid}?${new URLSearchParams({
								mail: "1",
								mail_template: "long-term-absence-en",
							}).toString()}`,
							"_blank",
						);
					},
				});
			}}
			onCancel={() => {
				props.onCancel();
			}}
		>
			Starting from which session do you want to cancel?
			{props.target.student_sessions.map(x => (
				<div key={x.uuid}>
					<button
						onClick={() => {
							if (new Date(x.section_session.datetime) <= new Date()) {
								Modal.confirm({
									title: "Warning",
									icon: <ExclamationCircleOutlined />,
									content: `Warning: this selection includes dates in the past. Today is ${new Date().toLocaleDateString()}`,
									okType: "danger",
									onOk: () => {
										setStartDateTime(new Date(x.section_session.datetime));
									},
								});
							} else {
								setStartDateTime(new Date(x.section_session.datetime));
							}
						}}
						style={remainingSessions.some(y => y.uuid === x.uuid) ? { background: "pink" } : undefined}
					>
						{new Date(x.section_session.datetime).toLocaleString()}
					</button>
				</div>
			))}
		</Modal>
	);
}
