import * as Yup from "yup";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import { ReturnPromiseType } from "../../util";
import { FormLoader, getSubField, PasswordField, SelectField, TextField, useMyFormik } from "./form";

const schema = Yup.object({
	name: Yup.string().required(),
	email: Yup.string().email(),
	username: Yup.string().required(),
	password: Yup.string(),
	role: Yup.string().required(),
});

export function UserForm(props: {
	user: ReturnPromiseType<typeof API.User.Get> | null;
	userRole: string | undefined;
	onCreated: (id: string) => void;
	onUpdated: () => void;
	onDeleted: () => void;
	auth: AuthContextLoggedIn;
}) {
	const formik = useMyFormik({
		id: props.user?.uuid,
		schema,
		initialValues: props.user
			? {
					name: props.user.name,
					email: props.user.email,
					username: props.user.username,
					password: "*",
					role: props.userRole,
			  }
			: {
					name: "",
					email: "",
					username: "",
					password: "",
					role: undefined,
			  },
		onCreate: async values => {
			const result = await API.User.Create(props.auth, {
				name: values.name,
				email: values.email,
				username: values.username,
				password: values.password,
				role: values.role as never,
				branch_uuid: props.auth.currentBranch.uuid,
			});
			props.onCreated(result.uuid);
		},
		onUpdate: async (id, values) => {
			await API.User.Update(props.auth, {
				name: values.name,
				email: values.email,
				username: values.username,
				password: values.password,
				role: values.role as never,
				uuid: id,
			});
			props.onUpdated();
		},
	});

	return (
		<FormLoader
			formik={formik}
			onDelete={async () => {
				await API.User.Delete(props.auth, {
					uuid: props.user!.uuid,
				});
				props.onDeleted();
			}}
		>
			<TextField field={getSubField(formik, "name")} label="Display name" />
			<TextField field={getSubField(formik, "username")} label="Username (login name; must be unique)" />
			<TextField field={getSubField(formik, "email")} label="Email" />
			<PasswordField
				field={getSubField(formik, "password")}
				label="Password (Once set, this field will show up as a single star)"
			/>
			<SelectField
				field={getSubField(formik, "role")}
				label="Role"
				nullable
				options={[
					{ value: "teacher", label: "Instructor" },
					{ value: "reception", label: "Admin" },
					{ value: "admin", label: "Manager" },
				]}
			/>
		</FormLoader>
	);
}
