import * as Yup from "yup";
import { API } from "../../api-types";
import { AuthContextLoggedIn, currentRoleAtLeast } from "../../auth";
import { unformatMoney } from "../../util";
import { FormLoader, getSubField, NumberField, TextField, useMyFormik } from "./form";

const schema = Yup.object({
	name: Yup.string().required(),
	short_name: Yup.string().required(),
	description: Yup.string(),
	session_count: Yup.number().integer().positive().required(),
	session_duration: Yup.number().integer().positive().required(),
	session_price: Yup.number().positive().required(),
});

export function CourseForm(props: {
	course: Partial<{
		uuid: string;
		name: string;
		short_name: string;
		description: string;
		session_count: number;
		session_duration: number;
		session_price: string;
		deleted: string | null;
	}>;
	onCreated: (id: string) => void;
	onUpdated: () => void;
	auth: AuthContextLoggedIn;
}) {
	const formik = useMyFormik({
		id: props.course.uuid,
		schema,
		initialValues: {
			name: props.course.name ?? "",
			short_name: props.course.short_name ?? "",
			description: props.course.description ?? "",
			session_count: props.course.session_count ?? 12,
			session_duration: props.course.session_duration ?? 60,
			session_price: unformatMoney(props.course.session_price ?? "47.62"),
		},
		onCreate: async values => {
			const result = await API.Course.Create(props.auth, {
				...values,
				auto_create_section: true,
			});
			props.onCreated(result.uuid);
		},
		onUpdate: async (id, values) => {
			await API.Course.Update(props.auth, {
				...values,
				uuid: id,
			});
			props.onUpdated();
		},
	});

	return (
		<FormLoader
			formik={formik}
			new={props.course.uuid === undefined}
			disabled={!currentRoleAtLeast(props.auth, "root")}
			deleted={props.course.deleted != null}
			showDelete
			showUndelete
			deleteConfirmation={
				props.course.deleted !== null ? "Are you sure? This will PERMANENTLY delete this course." : ""
			}
			deleteButtonLabel={props.course?.deleted !== null ? "Delete" : "Hide"}
			onDelete={async () => {
				if (props.course.uuid === undefined) return;
				if (props.course.deleted === null) {
					await API.Course.Delete(props.auth, { uuid: props.course.uuid });
				} else {
					await API.Course.HardDelete(props.auth, { uuid: props.course.uuid });
				}
				props.onUpdated();
			}}
			onUndelete={async () => {
				if (props.course.uuid === undefined) return;
				await API.Course.Undelete(props.auth, { uuid: props.course.uuid });
				props.onUpdated();
			}}
		>
			<TextField field={getSubField(formik, "name")} label="Name" />
			<TextField field={getSubField(formik, "short_name")} label="Code" />
			<TextField field={getSubField(formik, "description")} label="Description" />
			<NumberField field={getSubField(formik, "session_count")} label="Number of sessions" />
			<NumberField field={getSubField(formik, "session_duration")} label="Session duration (minutes)" />
			<TextField field={getSubField(formik, "session_price")} label="Price per session" />
		</FormLoader>
	);
}
