import { Modal, ModalProps } from "antd";

export function DisplayModal(props: {
	contents?: React.ReactNode;
	visible: boolean;
	onCancel: () => Promise<void>;
	args?: Partial<ModalProps>;
}) {
	return (
		<Modal
			destroyOnClose
			open={props.visible}
			cancelButtonProps={{ hidden: true }}
			onOk={async () => {
				await props.onCancel();
			}}
			onCancel={async () => {
				await props.onCancel();
			}}
			{...props.args}
		>
			{props.contents}
		</Modal>
	);
}
