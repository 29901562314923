import { ClockCircleOutlined, StarOutlined } from "@ant-design/icons";
import { Button, Checkbox, List, Skeleton } from "antd";
import { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../../api-types";
import { AuthContextLoggedIn, Authenticated } from "../../auth";
import { ReturnPromiseType, usePromise, usePromiseExtended } from "../../util";
import { StudentData, StudentForm } from "../form/student-data";
import { StudentSearch } from "../misc/student-search";

export function StudentsPage(props: { auth: AuthContextLoggedIn }) {
	const params = useParams<{ id: string }>();
	const history = useHistory();

	const branches = usePromise(useCallback(() => API.Branch.GetAll({}), []));
	const [studentData, studentDataR] = usePromiseExtended(
		useCallback(async () => {
			if (params.id === "new") return;
			if (params.id === undefined) return;
			return await API.Student.Get(props.auth, { branch_uuid: props.auth.currentBranch.uuid, uuid: params.id });
		}, [params.id, props.auth]),
	);

	const [withDeleted, setWithDeleted] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [loading, setLoading] = useState(false);
	const [studentList, setStudentList] = useState<ReturnPromiseType<typeof API.Student.Search>>([]);

	const getMoreData = async () => {
		if (loading) {
			return;
		}
		setLoading(true);
		const results = await API.Student.Search(props.auth, {
			query: "",
			branch_uuid: props.auth.currentBranch.uuid,
			skip: studentList.length,
			take: 50,
			with_deleted: withDeleted,
		});
		if (results.length > 0) {
			setStudentList(x => [...x, ...results]);
		}
		if (results.length < 50) {
			setHasMore(false);
		}
		setLoading(false);
	};

	useEffect(() => {
		setStudentList([]);
	}, [withDeleted]);

	usePromise(
		useCallback(async () => {
			if (studentList.length === 0) {
				getMoreData();
			}
		}, [studentList]),
	);

	return (
		<Authenticated as="reception">
			<div className="content-flex-horizontal">
				<div className="subcontent-flex-vertical" style={{ width: 300, flexShrink: 0 }}>
					<StudentSearch
						auth={props.auth}
						onSelect={s => {
							history.push(`/students/${s.uuid}`);
						}}
						withDeleted={withDeleted}
					/>
					<Button
						type="primary"
						onClick={() => {
							history.push(`/students/new`);
						}}
					>
						Register new student
					</Button>
					<div>
						<span>
							<StarOutlined />: active student
						</span>
						<span style={{ marginLeft: 5 }}>
							<ClockCircleOutlined />: deleted student
						</span>
					</div>
					<div
						id="scrollableDiv"
						style={{
							overflow: "auto",
							padding: "0 16px",
							border: "1px solid rgba(140, 140, 140, 0.35)",
						}}
					>
						<InfiniteScroll
							dataLength={studentList.length}
							next={getMoreData}
							hasMore={hasMore}
							loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
							endMessage={<div>End of list</div>}
							scrollableTarget="scrollableDiv"
						>
							<List
								size="small"
								dataSource={studentList}
								renderItem={item => (
									<List.Item
										style={{ cursor: "pointer" }}
										key={item.uuid}
										onClick={() => {
											history.push(`/students/${item.uuid}`);
										}}
									>
										{item.enrollments.some(x => new Date(x.end_datetime) > new Date()) && (
											<div style={{ position: "absolute", left: 0 }}>
												<StarOutlined />
											</div>
										)}
										{item.deleted !== null && (
											<div style={{ position: "absolute", left: 0 }}>
												<ClockCircleOutlined />
											</div>
										)}
										<span>{item.name}</span>
										<span>{item.phone}</span>
									</List.Item>
								)}
							/>
						</InfiniteScroll>
					</div>
					<Checkbox
						checked={withDeleted}
						onChange={x => {
							setWithDeleted(x.target.checked);
						}}
					>
						Show deleted
					</Checkbox>
				</div>

				{(studentData || params.id === "new") && branches && (
					<div style={{ width: 320, overflowY: "auto", flexShrink: 0 }}>
						<StudentForm
							auth={props.auth}
							branches={branches}
							student={params.id === "new" ? undefined : studentData}
							onUpdated={async () => {
								await studentDataR.refresh();
							}}
							onCreated={id => {
								history.push(`/students/${id}`);
							}}
						/>
					</div>
				)}

				{studentData && branches && (
					<StudentData
						studentData={studentData}
						auth={props.auth}
						branches={branches}
						onRefreshData={async () => {
							await studentDataR.refresh();
						}}
						onDeleted={async () => {
							setStudentList([]);
						}}
					/>
				)}
			</div>
		</Authenticated>
	);
}
