import { Button } from "antd";
import { useCallback } from "react";
import * as Yup from "yup";
import { API } from "../../api-types";
import { AuthContextLoggedIn, Authenticated } from "../../auth";
import { ReturnPromiseType, strippedStringSchema, stripPhone, usePromiseExtended } from "../../util";
import { FormLoader, getSubField, TextField, useMyFormik } from "../form/form";

const schema = Yup.object({
	name: strippedStringSchema.required(),
	phone: strippedStringSchema,
	email: Yup.string().email(),
	gst_account: strippedStringSchema,
	tax_percent: Yup.number().lessThan(50).moreThan(-1),
	royalty_percent: Yup.number().lessThan(50).moreThan(-1),
	address: Yup.string(),
	city: strippedStringSchema,
	postal: strippedStringSchema,
	country: strippedStringSchema,
	languages: Yup.string(),
	timezone: Yup.string().matches(/.\/./u),
	wechatpay_account: Yup.string(),
	etransfer_account: Yup.string(),
	manager_phone: strippedStringSchema,
});

export function BranchForm(props: {
	branch: ReturnPromiseType<typeof API.Branch.Get>;
	onUpdate: () => void;
	auth: AuthContextLoggedIn;
}) {
	const formik = useMyFormik({
		id: props.branch.uuid,
		schema,
		initialValues: {
			name: props.branch.name,
			phone: props.branch.phone,
			email: props.branch.email,
			gst_account: props.branch.gst_account,
			royalty_percent: props.branch.royalty_percent.toString(),
			tax_percent: props.branch.tax_percent.toString(),
			address: props.branch.address,
			city: props.branch.city,
			postal: props.branch.postal,
			country: props.branch.country,
			languages: props.branch.languages,
			timezone: props.branch.timezone,
			etransfer_account: props.branch.etransfer_account,
			wechatpay_account: props.branch.wechatpay_account,
			manager_phone: props.branch.manager_phone,
		},
		onUpdate: async (id, values) => {
			await API.Branch.Update(props.auth, {
				...values,
				tax_percent: parseFloat(values.tax_percent),
				royalty_percent: parseFloat(values.royalty_percent),
				phone: stripPhone(values.phone),
				manager_phone: stripPhone(values.manager_phone),
				uuid: props.auth.currentBranch.uuid,
			});
			props.onUpdate();
		},
	});

	return (
		<>
			<FormLoader formik={formik}>
				<TextField field={getSubField(formik, "name")} label="Name" />
				<TextField field={getSubField(formik, "phone")} label="Phone" />
				<TextField field={getSubField(formik, "email")} label="Email" />
				<TextField field={getSubField(formik, "address")} label="Address" />
				<TextField field={getSubField(formik, "city")} label="City" />
				<TextField field={getSubField(formik, "postal")} label="Postal" />
				<TextField field={getSubField(formik, "country")} label="Country" />
				<TextField field={getSubField(formik, "languages")} label="Languages" />
				<TextField field={getSubField(formik, "gst_account")} label="GST account" />
				<TextField field={getSubField(formik, "timezone")} label="Timezone (example: America/Vancouver)" />
				<TextField field={getSubField(formik, "tax_percent")} label="Tax percent" />
				<TextField field={getSubField(formik, "royalty_percent")} label="Royalty percent" />
				<TextField field={getSubField(formik, "wechatpay_account")} label="WCP image link" />
				<TextField field={getSubField(formik, "etransfer_account")} label="E-transfer email" />
				<TextField field={getSubField(formik, "manager_phone")} label="Manager phone" />
			</FormLoader>
			{props.branch.oauth_token !== null && (
				<>
					<Button
						onClick={async () => {
							await API.Branch.UnlinkGoogle(props.auth, { uuid: props.auth.currentBranch.uuid });
							props.onUpdate();
						}}
					>
						Disconnect Google Calendar
					</Button>
				</>
			)}
			<div>
				The app is currently unverified. After clicking Connect you need to click Advanced then Go to RoboOffice
				4 (unsafe)
			</div>
			<Button
				onClick={async () => {
					window.location.href = (
						await API.Auth.CreateOauthUrl(props.auth, { branch_uuid: props.auth.currentBranch.uuid })
					).url;
				}}
				type={props.branch.oauth_token === null ? "primary" : "default"}
			>
				Connect Google Calendar
			</Button>
		</>
	);
}

export function BranchPage(props: { auth: AuthContextLoggedIn }) {
	const [branch, branchR] = usePromiseExtended(
		useCallback(() => API.Branch.Get(props.auth, { uuid: props.auth.currentBranch.uuid }), [props.auth]),
	);

	return (
		<Authenticated as="admin">
			<div style={{ width: 400 }}>
				{branch && <BranchForm branch={branch} onUpdate={() => branchR.refresh()} auth={props.auth} />}
			</div>
		</Authenticated>
	);
}
