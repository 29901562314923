import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { usePromise, usePromiseExtended } from "../../util";
import { API } from "../../api-types";
import { Button, Descriptions } from "antd";
import { AuthContextLoggedIn } from "../../auth";
import { TicketsListPopover } from "./tickets-list";

export function PaymentData(props: { uuid: string; auth: AuthContextLoggedIn }) {
	const [tickets, ticketsR] = usePromiseExtended(
		useCallback(() => API.Ticket.GetAll(props.auth, { payment_uuid: props.uuid }), [props.uuid, props.auth]),
	);

	const payment = usePromise(
		useCallback(() => API.Branch.Payment.Get(props.auth, { uuid: props.uuid }), [props.uuid, props.auth]),
	);

	const blob = usePromise(
		useCallback(() => API.Branch.Payment.GetInvoice(props.auth, { uuid: props.uuid }), [props.uuid, props.auth]),
	);

	const blobUrl = useMemo(() => {
		if (!blob) return undefined;
		return URL.createObjectURL(blob);
	}, [blob]);

	const history = useHistory();

	return (
		<div className="subcontent-flex-vertical">
			{payment && (
				<>
					{payment.deleted !== null && <div>This payment has been voided.</div>}
					<Descriptions bordered column={1} size="small">
						<Descriptions.Item label="Student">{payment.student?.name ?? "None"}</Descriptions.Item>
						<Descriptions.Item label="Unit Price">${payment.unit_price}</Descriptions.Item>
						<Descriptions.Item label="Count">{payment.quantity}</Descriptions.Item>
						<Descriptions.Item label="Discount">${(-+payment.surcharge).toFixed(2)}</Descriptions.Item>
						<Descriptions.Item label="Tax">${payment.tax}</Descriptions.Item>
						<Descriptions.Item label="Total">${payment.total}</Descriptions.Item>
						<Descriptions.Item label="Number">{payment.invoice_number}</Descriptions.Item>
						<Descriptions.Item label="Course">
							{payment.enrollment?.section?.course?.name ?? "None"}
						</Descriptions.Item>
						<Descriptions.Item label="Classroom">
							{payment.enrollment?.section?.classroom?.name ?? "None"}
						</Descriptions.Item>
						<Descriptions.Item label="Duration">
							{payment.enrollment
								? `${new Date(payment.enrollment.start_datetime).toLocaleDateString()}-${new Date(
										payment.enrollment.end_datetime,
								  ).toLocaleDateString()}`
								: "None"}
						</Descriptions.Item>
					</Descriptions>
					<TicketsListPopover
						auth={props.auth}
						tickets={tickets}
						onTicketDismissed={async () => {
							await ticketsR.refresh();
						}}
					/>
					{blobUrl !== undefined && (
						<>
							<embed src={blobUrl} type="application/pdf" style={{ width: "100%", aspectRatio: "1" }} />
							<Button
								onClick={() => {
									const win = window.open();
									if (!win) throw new Error("Could not open a new window to show pdf");
									win.location.href = blobUrl;
								}}
							>
								Open/print receipt
							</Button>
						</>
					)}
					<Button
						type="primary"
						disabled={payment.student === null}
						onClick={() => {
							if (payment.student !== null) {
								history.push(
									`/students/${payment.student.uuid}?mail=1&mail_payment_attach=${payment.uuid}&mail_template=email-receipt-en`,
								);
							}
						}}
					>
						Send receipt
					</Button>
				</>
			)}
		</div>
	);
}
