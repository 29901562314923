// DO NOT MODIFY THIS FILE. This file is autogenerated based on the server's API specification.
/* eslint-disable @typescript-eslint/no-unused-vars */
import { register, registerAuthless, registerBlob } from "./api";
import type { APIResponse } from "ro4-server/src/api/api";
import type { APIAdmin } from "ro4-server/src/api/admin";
import type { APIStudent } from "ro4-server/src/api/student";
import type { APIEnrollment } from "ro4-server/src/api/enrollment";
import type { APIStudentSession } from "ro4-server/src/api/student-session";
import type { APIAuth } from "ro4-server/src/api/auth";
import type { APIUser } from "ro4-server/src/api/user";
import type { APICourse } from "ro4-server/src/api/course";
import type { APITicket } from "ro4-server/src/api/ticket";
import type { APITask } from "ro4-server/src/api/task";
import type { APIBranch } from "ro4-server/src/api/branch";
import type { APISchedule } from "ro4-server/src/api/schedule";
import type { APIClassroom } from "ro4-server/src/api/classroom";
import type { APISection } from "ro4-server/src/api/section";
import type { APISectionSession } from "ro4-server/src/api/section-session";
import type { APIPayment } from "ro4-server/src/api/payment";
import type { APIMail } from "ro4-server/src/api/mail";
export const API = {
	Admin: {
		DownloadBackup: registerBlob<APIAdmin["DownloadBackup"] extends (auth: never, param: infer T) => unknown ? T : never, APIAdmin["DownloadBackup"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Admin.DownloadBackup"),
		DeleteBranch: register<APIAdmin["DeleteBranch"] extends (auth: never, param: infer T) => unknown ? T : never, APIAdmin["DeleteBranch"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Admin.DeleteBranch"),
	},
	Student: {
		Enrollment: {
			Session: {
				GetPreviousReport: register<APIStudentSession["GetPreviousReport"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudentSession["GetPreviousReport"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.Session.GetPreviousReport"),
				GetReport: register<APIStudentSession["GetReport"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudentSession["GetReport"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.Session.GetReport"),
				DeleteReport: register<APIStudentSession["DeleteReport"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudentSession["DeleteReport"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.Session.DeleteReport"),
				SetReport: register<APIStudentSession["SetReport"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudentSession["SetReport"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.Session.SetReport"),
				GetQRHash: register<APIStudentSession["GetQRHash"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudentSession["GetQRHash"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.Session.GetQRHash"),
				SetMediaLink: registerAuthless<APIStudentSession["SetMediaLink"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudentSession["SetMediaLink"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.Session.SetMediaLink"),
				Update: register<APIStudentSession["Update"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudentSession["Update"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.Session.Update"),
			},
			GetRemindersMany: register<APIEnrollment["GetRemindersMany"] extends (auth: never, param: infer T) => unknown ? T : never, APIEnrollment["GetRemindersMany"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.GetRemindersMany"),
			Get: register<APIEnrollment["Get"] extends (auth: never, param: infer T) => unknown ? T : never, APIEnrollment["Get"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.Get"),
			GetSessions: register<APIEnrollment["GetSessions"] extends (auth: never, param: infer T) => unknown ? T : never, APIEnrollment["GetSessions"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.GetSessions"),
			Update: register<APIEnrollment["Update"] extends (auth: never, param: infer T) => unknown ? T : never, APIEnrollment["Update"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.Update"),
			Create: register<APIEnrollment["Create"] extends (auth: never, param: infer T) => unknown ? T : never, APIEnrollment["Create"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.Create"),
			ExportAttendanceSheet: registerBlob<APIEnrollment["ExportAttendanceSheet"] extends (auth: never, param: infer T) => unknown ? T : never, APIEnrollment["ExportAttendanceSheet"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.ExportAttendanceSheet"),
			AddMakeup: register<APIEnrollment["AddMakeup"] extends (auth: never, param: infer T) => unknown ? T : never, APIEnrollment["AddMakeup"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.AddMakeup"),
			Delete: register<APIEnrollment["Delete"] extends (auth: never, param: infer T) => unknown ? T : never, APIEnrollment["Delete"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.Delete"),
			GetReport: register<APIEnrollment["GetReport"] extends (auth: never, param: infer T) => unknown ? T : never, APIEnrollment["GetReport"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.GetReport"),
			ExportReportCard: registerBlob<APIEnrollment["ExportReportCard"] extends (auth: never, param: infer T) => unknown ? T : never, APIEnrollment["ExportReportCard"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.ExportReportCard"),
			GetStudentReports: register<APIEnrollment["GetStudentReports"] extends (auth: never, param: infer T) => unknown ? T : never, APIEnrollment["GetStudentReports"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.GetStudentReports"),
			SetReport: register<APIEnrollment["SetReport"] extends (auth: never, param: infer T) => unknown ? T : never, APIEnrollment["SetReport"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Enrollment.SetReport"),
		},
		GetMailingList: register<APIStudent["GetMailingList"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudent["GetMailingList"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.GetMailingList"),
		GetEnrollments: register<APIStudent["GetEnrollments"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudent["GetEnrollments"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.GetEnrollments"),
		Get: register<APIStudent["Get"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudent["Get"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Get"),
		GetName: register<APIStudent["GetName"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudent["GetName"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.GetName"),
		GetBasicInfo: register<APIStudent["GetBasicInfo"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudent["GetBasicInfo"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.GetBasicInfo"),
		GetLastSentMail: register<APIStudent["GetLastSentMail"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudent["GetLastSentMail"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.GetLastSentMail"),
		GetProgress: register<APIStudent["GetProgress"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudent["GetProgress"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.GetProgress"),
		UpdateProgress: register<APIStudent["UpdateProgress"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudent["UpdateProgress"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.UpdateProgress"),
		Search: register<APIStudent["Search"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudent["Search"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Search"),
		Create: register<APIStudent["Create"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudent["Create"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Create"),
		Delete: register<APIStudent["Delete"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudent["Delete"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Delete"),
		Undelete: register<APIStudent["Undelete"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudent["Undelete"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Undelete"),
		Update: register<APIStudent["Update"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudent["Update"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.Update"),
		ModifyCredit: register<APIStudent["ModifyCredit"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudent["ModifyCredit"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.ModifyCredit"),
		AddToLog: register<APIStudent["AddToLog"] extends (auth: never, param: infer T) => unknown ? T : never, APIStudent["AddToLog"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Student.AddToLog"),
	},
	Auth: {
		CreateOauthUrl: register<APIAuth["CreateOauthUrl"] extends (auth: never, param: infer T) => unknown ? T : never, APIAuth["CreateOauthUrl"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Auth.CreateOauthUrl"),
		SetOauthToken: register<APIAuth["SetOauthToken"] extends (auth: never, param: infer T) => unknown ? T : never, APIAuth["SetOauthToken"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Auth.SetOauthToken"),
		Login: registerAuthless<APIAuth["Login"] extends (auth: never, param: infer T) => unknown ? T : never, APIAuth["Login"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Auth.Login"),
	},
	User: {
		GetAll: register<APIUser["GetAll"] extends (auth: never, param: infer T) => unknown ? T : never, APIUser["GetAll"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.User.GetAll"),
		Get: register<APIUser["Get"] extends (auth: never, param: infer T) => unknown ? T : never, APIUser["Get"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.User.Get"),
		Update: register<APIUser["Update"] extends (auth: never, param: infer T) => unknown ? T : never, APIUser["Update"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.User.Update"),
		Delete: register<APIUser["Delete"] extends (auth: never, param: infer T) => unknown ? T : never, APIUser["Delete"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.User.Delete"),
		Create: register<APIUser["Create"] extends (auth: never, param: infer T) => unknown ? T : never, APIUser["Create"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.User.Create"),
	},
	Course: {
		GetAll: register<APICourse["GetAll"] extends (auth: never, param: infer T) => unknown ? T : never, APICourse["GetAll"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Course.GetAll"),
		Get: register<APICourse["Get"] extends (auth: never, param: infer T) => unknown ? T : never, APICourse["Get"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Course.Get"),
		GetArchivedCourses: register<APICourse["GetArchivedCourses"] extends (auth: never, param: infer T) => unknown ? T : never, APICourse["GetArchivedCourses"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Course.GetArchivedCourses"),
		GetClassrooms: register<APICourse["GetClassrooms"] extends (auth: never, param: infer T) => unknown ? T : never, APICourse["GetClassrooms"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Course.GetClassrooms"),
		Create: register<APICourse["Create"] extends (auth: never, param: infer T) => unknown ? T : never, APICourse["Create"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Course.Create"),
		Undelete: register<APICourse["Undelete"] extends (auth: never, param: infer T) => unknown ? T : never, APICourse["Undelete"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Course.Undelete"),
		Delete: register<APICourse["Delete"] extends (auth: never, param: infer T) => unknown ? T : never, APICourse["Delete"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Course.Delete"),
		HardDelete: register<APICourse["HardDelete"] extends (auth: never, param: infer T) => unknown ? T : never, APICourse["HardDelete"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Course.HardDelete"),
		Update: register<APICourse["Update"] extends (auth: never, param: infer T) => unknown ? T : never, APICourse["Update"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Course.Update"),
	},
	Ticket: {
		Task: {
			GetAll: register<APITask["GetAll"] extends (auth: never, param: infer T) => unknown ? T : never, APITask["GetAll"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Ticket.Task.GetAll"),
			Update: register<APITask["Update"] extends (auth: never, param: infer T) => unknown ? T : never, APITask["Update"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Ticket.Task.Update"),
		},
		GetNotifications: register<APITicket["GetNotifications"] extends (auth: never, param: infer T) => unknown ? T : never, APITicket["GetNotifications"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Ticket.GetNotifications"),
		GetAll: register<APITicket["GetAll"] extends (auth: never, param: infer T) => unknown ? T : never, APITicket["GetAll"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Ticket.GetAll"),
		Get: register<APITicket["Get"] extends (auth: never, param: infer T) => unknown ? T : never, APITicket["Get"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Ticket.Get"),
		Create: register<APITicket["Create"] extends (auth: never, param: infer T) => unknown ? T : never, APITicket["Create"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Ticket.Create"),
		CreateBroadcast: register<APITicket["CreateBroadcast"] extends (auth: never, param: infer T) => unknown ? T : never, APITicket["CreateBroadcast"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Ticket.CreateBroadcast"),
		AddReply: register<APITicket["AddReply"] extends (auth: never, param: infer T) => unknown ? T : never, APITicket["AddReply"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Ticket.AddReply"),
		View: register<APITicket["View"] extends (auth: never, param: infer T) => unknown ? T : never, APITicket["View"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Ticket.View"),
		UpdateStatus: register<APITicket["UpdateStatus"] extends (auth: never, param: infer T) => unknown ? T : never, APITicket["UpdateStatus"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Ticket.UpdateStatus"),
		Update: register<APITicket["Update"] extends (auth: never, param: infer T) => unknown ? T : never, APITicket["Update"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Ticket.Update"),
		Delete: register<APITicket["Delete"] extends (auth: never, param: infer T) => unknown ? T : never, APITicket["Delete"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Ticket.Delete"),
	},
	Branch: {
		Schedule: {
			Get: register<APISchedule["Get"] extends (auth: never, param: infer T) => unknown ? T : never, APISchedule["Get"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Schedule.Get"),
			ExportAsXlsx: registerBlob<APISchedule["ExportAsXlsx"] extends (auth: never, param: infer T) => unknown ? T : never, APISchedule["ExportAsXlsx"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Schedule.ExportAsXlsx"),
		},
		Classroom: {
			GetNames: register<APIClassroom["GetNames"] extends (auth: never, param: infer T) => unknown ? T : never, APIClassroom["GetNames"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Classroom.GetNames"),
			GetClassroomsForTeacher: register<APIClassroom["GetClassroomsForTeacher"] extends (auth: never, param: infer T) => unknown ? T : never, APIClassroom["GetClassroomsForTeacher"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Classroom.GetClassroomsForTeacher"),
			Get: register<APIClassroom["Get"] extends (auth: never, param: infer T) => unknown ? T : never, APIClassroom["Get"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Classroom.Get"),
			Create: register<APIClassroom["Create"] extends (auth: never, param: infer T) => unknown ? T : never, APIClassroom["Create"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Classroom.Create"),
			SetDailyLinks: register<APIClassroom["SetDailyLinks"] extends (auth: never, param: infer T) => unknown ? T : never, APIClassroom["SetDailyLinks"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Classroom.SetDailyLinks"),
			SetCurriculumLink: register<APIClassroom["SetCurriculumLink"] extends (auth: never, param: infer T) => unknown ? T : never, APIClassroom["SetCurriculumLink"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Classroom.SetCurriculumLink"),
			SetHours: register<APIClassroom["SetHours"] extends (auth: never, param: infer T) => unknown ? T : never, APIClassroom["SetHours"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Classroom.SetHours"),
			SetTeacher: register<APIClassroom["SetTeacher"] extends (auth: never, param: infer T) => unknown ? T : never, APIClassroom["SetTeacher"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Classroom.SetTeacher"),
			SetCourses: register<APIClassroom["SetCourses"] extends (auth: never, param: infer T) => unknown ? T : never, APIClassroom["SetCourses"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Classroom.SetCourses"),
			CopyToBranch: register<APIClassroom["CopyToBranch"] extends (auth: never, param: infer T) => unknown ? T : never, APIClassroom["CopyToBranch"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Classroom.CopyToBranch"),
			Update: register<APIClassroom["Update"] extends (auth: never, param: infer T) => unknown ? T : never, APIClassroom["Update"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Classroom.Update"),
			Delete: register<APIClassroom["Delete"] extends (auth: never, param: infer T) => unknown ? T : never, APIClassroom["Delete"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Classroom.Delete"),
		},
		Section: {
			Session: {
				SetReport: register<APISectionSession["SetReport"] extends (auth: never, param: infer T) => unknown ? T : never, APISectionSession["SetReport"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Section.Session.SetReport"),
			},
			Get: register<APISection["Get"] extends (auth: never, param: infer T) => unknown ? T : never, APISection["Get"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Section.Get"),
			UpdateSessions: register<APISection["UpdateSessions"] extends (auth: never, param: infer T) => unknown ? T : never, APISection["UpdateSessions"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Section.UpdateSessions"),
			Create: register<APISection["Create"] extends (auth: never, param: infer T) => unknown ? T : never, APISection["Create"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Section.Create"),
			Delete: register<APISection["Delete"] extends (auth: never, param: infer T) => unknown ? T : never, APISection["Delete"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Section.Delete"),
			Update: register<APISection["Update"] extends (auth: never, param: infer T) => unknown ? T : never, APISection["Update"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Section.Update"),
		},
		Payment: {
			GetInvoice: registerBlob<APIPayment["GetInvoice"] extends (auth: never, param: infer T) => unknown ? T : never, APIPayment["GetInvoice"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Payment.GetInvoice"),
			GetAll: register<APIPayment["GetAll"] extends (auth: never, param: infer T) => unknown ? T : never, APIPayment["GetAll"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Payment.GetAll"),
			Get: register<APIPayment["Get"] extends (auth: never, param: infer T) => unknown ? T : never, APIPayment["Get"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Payment.Get"),
			Create: register<APIPayment["Create"] extends (auth: never, param: infer T) => unknown ? T : never, APIPayment["Create"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Payment.Create"),
			Delete: register<APIPayment["Delete"] extends (auth: never, param: infer T) => unknown ? T : never, APIPayment["Delete"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Payment.Delete"),
		},
		Get: register<APIBranch["Get"] extends (auth: never, param: infer T) => unknown ? T : never, APIBranch["Get"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Get"),
		GetTaxPercent: registerAuthless<APIBranch["GetTaxPercent"] extends (auth: never, param: infer T) => unknown ? T : never, APIBranch["GetTaxPercent"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.GetTaxPercent"),
		GetAll: registerAuthless<APIBranch["GetAll"] extends (auth: never, param: infer T) => unknown ? T : never, APIBranch["GetAll"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.GetAll"),
		Create: register<APIBranch["Create"] extends (auth: never, param: infer T) => unknown ? T : never, APIBranch["Create"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Create"),
		UnlinkGoogle: register<APIBranch["UnlinkGoogle"] extends (auth: never, param: infer T) => unknown ? T : never, APIBranch["UnlinkGoogle"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.UnlinkGoogle"),
		Update: register<APIBranch["Update"] extends (auth: never, param: infer T) => unknown ? T : never, APIBranch["Update"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.Update"),
		RemapCourses: register<APIBranch["RemapCourses"] extends (auth: never, param: infer T) => unknown ? T : never, APIBranch["RemapCourses"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.RemapCourses"),
		GetFutureHolidays: register<APIBranch["GetFutureHolidays"] extends (auth: never, param: infer T) => unknown ? T : never, APIBranch["GetFutureHolidays"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.GetFutureHolidays"),
		SetFutureHolidays: register<APIBranch["SetFutureHolidays"] extends (auth: never, param: infer T) => unknown ? T : never, APIBranch["SetFutureHolidays"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.SetFutureHolidays"),
		CopyHolidaysToOtherBranches: register<APIBranch["CopyHolidaysToOtherBranches"] extends (auth: never, param: infer T) => unknown ? T : never, APIBranch["CopyHolidaysToOtherBranches"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Branch.CopyHolidaysToOtherBranches"),
	},
	Mail: {
		Send: register<APIMail["Send"] extends (auth: never, param: infer T) => unknown ? T : never, APIMail["Send"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Mail.Send"),
		SendSMS: register<APIMail["SendSMS"] extends (auth: never, param: infer T) => unknown ? T : never, APIMail["SendSMS"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Mail.SendSMS"),
		SendInternalSMS: register<APIMail["SendInternalSMS"] extends (auth: never, param: infer T) => unknown ? T : never, APIMail["SendInternalSMS"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Mail.SendInternalSMS"),
		GetTemplateList: register<APIMail["GetTemplateList"] extends (auth: never, param: infer T) => unknown ? T : never, APIMail["GetTemplateList"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Mail.GetTemplateList"),
		GetTemplate: register<APIMail["GetTemplate"] extends (auth: never, param: infer T) => unknown ? T : never, APIMail["GetTemplate"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Mail.GetTemplate"),
		GetTemplateRaw: register<APIMail["GetTemplateRaw"] extends (auth: never, param: infer T) => unknown ? T : never, APIMail["GetTemplateRaw"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Mail.GetTemplateRaw"),
		UpdateTemplate: register<APIMail["UpdateTemplate"] extends (auth: never, param: infer T) => unknown ? T : never, APIMail["UpdateTemplate"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Mail.UpdateTemplate"),
		CreateTemplate: register<APIMail["CreateTemplate"] extends (auth: never, param: infer T) => unknown ? T : never, APIMail["CreateTemplate"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Mail.CreateTemplate"),
		DeleteTemplate: register<APIMail["DeleteTemplate"] extends (auth: never, param: infer T) => unknown ? T : never, APIMail["DeleteTemplate"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Mail.DeleteTemplate"),
		GetSMSTemplate: register<APIMail["GetSMSTemplate"] extends (auth: never, param: infer T) => unknown ? T : never, APIMail["GetSMSTemplate"] extends (...args: never[]) => Promise<APIResponse<infer T>> ? T : never>("API.Mail.GetSMSTemplate"),
	},
};
