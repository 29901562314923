import { LeftOutlined } from "@ant-design/icons";
import { Button, Descriptions, List, Popover } from "antd";
import download from "downloadjs";
import { useCallback, useState } from "react";
import { Item, Menu, useContextMenu } from "react-contexify";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import { bind, sessionFormatter, usePromiseExtended } from "../../util";
import { CancelEnrollmentModal } from "../modal/cancel-enrollment-modal";
import { ChangeCourseModal } from "../modal/change-course-modal";
import { ExtendEnrollmentModal } from "../modal/extend-enrollment-modal";
import { ModalsContext, useModals } from "../modal/modals-provider";
import { SectionReschedulerModal } from "../modal/section-rescheduler-modal";
import { AttendanceGrid } from "./attendance-grid";
import { ProgressGrid } from "./progress-grid";
import { TicketsListPopover } from "./tickets-list";

export async function cancelAndMakeup(
	auth: AuthContextLoggedIn,
	modals: ModalsContext,
	sess: { uuid: string; is_cancelled: boolean; section_session: { datetime: string; duration: number } },
	enrollment: {
		uuid: string;
		branch: { uuid: string };
		student: { uuid: string };
		section: { uuid: string; classroom: { uuid: string }; course: { name: string } };
	},
	onCompleted?: () => Promise<void> | void,
) {
	if (sess.is_cancelled) {
		await API.Student.Enrollment.Session.Update(auth, {
			uuid: sess.uuid,
			is_cancelled: false,
		});
		return;
	}

	const result = await modals.prompt({
		lengthAtLeast: 5,
		title: "Please enter a reason for marking this session as absent.",
	});

	if (result.action !== "ok") return;

	await API.Student.Enrollment.Session.Update(auth, {
		uuid: sess.uuid,
		is_cancelled: true,
	});

	const nextAction = async (newDate?: Date) => {
		await API.Ticket.Create(auth, {
			branch_uuid: enrollment.branch.uuid,
			student_uuid: enrollment.student.uuid,
			enrollment_uuid: enrollment.uuid,
			section_uuid: enrollment.section.uuid,
			student_session_uuid: sess.uuid,
			date: sess.section_session.datetime,
			title: "Requested absence",
			type: "note",
			closed: true,
			recipient_roles: ["reception"],
			recipients: [],
			text:
				`Requested absence on ${new Date(sess.section_session.datetime).toLocaleString(
					[],
					sessionFormatter,
				)}. Reason: ${result.data}. ` +
				(newDate
					? `Makeup scheduled for ${newDate.toLocaleString([], sessionFormatter)}`
					: `No makeup scheduled.`),
		});

		await onCompleted?.();

		window.open(
			`/students/${enrollment.student.uuid}?${new URLSearchParams({
				mail: "1",
				mail_template: "request-absence-en",
				param_absence_date: new Date(sess.section_session.datetime).toLocaleString([], sessionFormatter),
				param_new_date: newDate?.toLocaleString([], sessionFormatter) ?? "",
			}).toString()}`,
			"_blank",
		);
	};

	modals.createMakeup({
		cancelText: "No confirmed time",
		bodyText: "The absence has been marked. Do you have a confirmed makeup time?",
		target: {
			auth,
			enrollment,
			original_session: sess,
			section: {
				classroom: enrollment.section.classroom,
				session_duration: sess.section_session.duration,
			},
		},
		async onCreated(session) {
			await nextAction(new Date(session.section_session.datetime));
		},
		async onCancel() {
			await nextAction();
		},
	});
}

export function EnrollmentData(props: {
	linkToSectionPage?: boolean;
	uuid: string;
	auth: AuthContextLoggedIn;
	onDelete?: () => Promise<void> | void;
	onRefresh?: () => Promise<void> | void;
}) {
	const [enrollment, enrollmentR] = usePromiseExtended(
		useCallback(() => API.Student.Enrollment.Get(props.auth, { uuid: props.uuid }), [props.uuid, props.auth]),
	);

	const [sessionDetails, sessionDetailsR] = usePromiseExtended(
		useCallback(
			() => API.Student.Enrollment.GetSessions(props.auth, { uuid: props.uuid }),
			[props.uuid, props.auth],
		),
	);

	const [tickets, ticketsR] = usePromiseExtended(
		useCallback(() => API.Ticket.GetAll(props.auth, { enrollment_uuid: props.uuid }), [props.uuid, props.auth]),
	);

	const [longterm, setLongterm] = useState(false);
	const [changeCourse, setChangeCourse] = useState(false);
	const [resuming, setResuming] = useState(false);
	const [rescheduling, setRescheduling] = useState(false);
	const history = useHistory();
	const modals = useModals();

	const [selectedSession, setSelectedSession] =
		useState<NonNullable<typeof enrollment>["student_sessions"][number]>();

	const ctx = useContextMenu();

	return (
		<div className="subcontent-flex-vertical" style={{ maxWidth: 400 }}>
			{enrollment && (
				<>
					{enrollment.deleted !== null && <div>This enrollment has been deleted.</div>}
					<Descriptions bordered column={1} size="small">
						<Descriptions.Item label="Student">{enrollment.student.name}</Descriptions.Item>
						<Descriptions.Item label="Course">{enrollment.section.course.name}</Descriptions.Item>
						<Descriptions.Item label="Classroom">{enrollment.section.classroom.name}</Descriptions.Item>
						<Descriptions.Item label="Duration">
							{new Date(enrollment.start_datetime).toLocaleDateString()}-
							{new Date(enrollment.end_datetime).toLocaleDateString()}
						</Descriptions.Item>
						<Descriptions.Item label="# Sessions">{enrollment.session_count}</Descriptions.Item>
						<Descriptions.Item label="Meeting time">
							{enrollment.section.regular_time_descriptor}
						</Descriptions.Item>
					</Descriptions>

					<TicketsListPopover
						auth={props.auth}
						tickets={tickets}
						onTicketDismissed={async () => {
							await ticketsR.refresh();
						}}
					/>

					<div style={{ width: 300 }}>
						Sessions:
						<List
							size="small"
							dataSource={enrollment.student_sessions}
							renderItem={item => {
								return (
									<div
										key={item.uuid}
										className="hover-list-item"
										onClick={event => {
											setSelectedSession(item);
											ctx.show(event, { id: "session" });
										}}
									>
										{(item.section_session.sequence_number ?? -1) + 1}.{" "}
										{new Date(item.section_session.datetime).toLocaleString([], sessionFormatter)}
										{item.is_makeup ? " (Makeup) " : " "}
										{item.is_cancelled ? " (Absent) " : " "}
										{bind(item.report?.attendance, x => (x ? "✔️" : "❌")) ?? ""}
									</div>
								);
							}}
						/>
					</div>
					<Button
						type="primary"
						onClick={() => {
							history.push({
								pathname: `/students/${enrollment.student.uuid}`,
								search: `?mail=1`,
							});
						}}
					>
						Send email
					</Button>

					<Button
						type="primary"
						onClick={async () => {
							const val = await modals.prompt({
								title: "Edit comment",
								initialValue: enrollment.comment,
							});

							if (val.action === "ok") {
								await API.Student.Enrollment.Update(props.auth, {
									uuid: enrollment.uuid,
									comment: val.data,
								});
							}
						}}
					>
						Update comment
					</Button>

					{enrollment.payment === null ? (
						<Button
							type="primary"
							onClick={() => {
								modals.payment({
									auth: props.auth,
									params: {
										quantity: enrollment.student_sessions.filter(x => !x.is_cancelled).length,
										unitPrice: enrollment.section.course.session_price,
										itemName: `Payment for ${enrollment.section.course.name}`,
										student_uuid: enrollment.student.uuid,
										branch_uuid: enrollment.section.classroom.branch.uuid,
										enrollment_uuid: enrollment.uuid,
										creditUnitValue: Math.round(enrollment.section.session_duration / 30) / 2,
									},
									async onCreated() {
										await enrollmentR.refresh();
									},
								});
							}}
						>
							Make payment
						</Button>
					) : (
						<Button
							type="primary"
							onClick={() => {
								if (!enrollment.payment) return;

								history.push({
									pathname: `/students/${enrollment.student.uuid}`,
									search: `?payment=${enrollment.payment.uuid}`,
								});
							}}
						>
							View payment
						</Button>
					)}

					<Popover
						placement="left"
						content={
							<div className="subcontent-flex-vertical">
								<Button
									onClick={() => {
										setLongterm(true);
									}}
								>
									Long-term absence
								</Button>
								<Button
									onClick={() => {
										setResuming(true);
									}}
								>
									Extend/Resume from long-term absence
								</Button>
								<Button
									onClick={() => {
										setChangeCourse(true);
									}}
								>
									Early advancement/Change course
								</Button>
								<Button
									onClick={() => {
										setRescheduling(true);
									}}
								>
									Reschedule
								</Button>

								<Button
									disabled={enrollment.deleted !== null}
									onClick={async () => {
										const result = await modals.prompt({
											lengthAtLeast: 5,
											title: "Deleting this enrollment. The payment will be voided. Please enter a reason.",
										});

										if (result.action === "ok") {
											await API.Student.Enrollment.Delete(props.auth, {
												uuid: enrollment.uuid,
												reason: result.data,
											});
											await props.onDelete?.();
										}
									}}
								>
									Delete enrollment
								</Button>
							</div>
						}
					>
						<Button>
							<LeftOutlined />
							Modify enrollment
						</Button>
					</Popover>

					<Popover
						placement="left"
						content={
							<div className="subcontent-flex-vertical">
								<Button
									onClick={() => {
										modals.display({
											args: {
												width: 800,
												title: "Attendance grid",
											},
											contents: (
												<AttendanceGrid auth={props.auth} sessionDetails={sessionDetails} />
											),
										});
									}}
								>
									View attendance grid
								</Button>

								<Button
									onClick={() => {
										modals.display({
											args: {
												width: 1000,
												title: "Progress grid",
											},
											contents: (
												<ProgressGrid
													auth={props.auth}
													student={{
														uuid: enrollment.student.uuid,
													}}
												/>
											),
										});
									}}
								>
									View progress grid
								</Button>

								<Button
									onClick={() => {
										modals.editEnrollmentReport({
											target: { ...enrollment, auth: props.auth },
											async onUpdated() {
												await enrollmentR.refresh();
												await sessionDetailsR.refresh();
											},
										});
									}}
								>
									Report card
								</Button>

								<Button
									onClick={async () => {
										const blob = await API.Student.Enrollment.ExportAttendanceSheet(props.auth, {
											uuid: enrollment.uuid,
										});
										download(blob, "attendance.pdf");
									}}
								>
									Create attendance sheet
								</Button>
							</div>
						}
					>
						<Button>
							<LeftOutlined />
							Teacher reports
						</Button>
					</Popover>

					<Popover
						placement="left"
						content={
							<div className="subcontent-flex-vertical">
								{props.linkToSectionPage === true && (
									<Button
										onClick={() => {
											history.push(`/section/${enrollment.section.uuid}`);
										}}
									>
										View section page
									</Button>
								)}
								<Button
									onClick={async () => {
										const result = await modals.prompt({
											initialValue: enrollment.session_count.toString(),
											isInteger: true,
											title: "Set number of sessions",
										});

										if (result.action === "ok") {
											await API.Student.Enrollment.Update(props.auth, {
												uuid: enrollment.uuid,
												session_count: parseInt(result.data),
											});
											await enrollmentR.refresh();
										}
									}}
								>
									Edit total # sessions
								</Button>
							</div>
						}
					>
						<Button>
							<LeftOutlined />
							Advanced
						</Button>
					</Popover>

					<SectionReschedulerModal
						open={rescheduling}
						section={enrollment.section}
						auth={props.auth}
						onSubmitted={async (remainingSessions, newDatetime) => {
							setRescheduling(false);

							await API.Ticket.Create(props.auth, {
								branch_uuid: enrollment.branch.uuid,
								student_uuid: enrollment.student.uuid,
								enrollment_uuid: enrollment.uuid,
								section_uuid: enrollment.section.uuid,
								recipient_roles: ["reception"],
								recipients: [],
								closed: true,
								title: `Rescheduled`,
								text: `Rescheduled ${
									remainingSessions.length
								} sessions to the new time of ${newDatetime.toLocaleString(
									[],
									sessionFormatter,
								)}:\n${remainingSessions
									.map(x => "- " + new Date(x.datetime).toLocaleString([], sessionFormatter))
									.join("\n")}`,
								type: "note",
							});

							await enrollmentR.refresh();
							await ticketsR.refresh();
							await sessionDetailsR.refresh();
							await props.onRefresh?.();
						}}
						onCancel={() => {
							setRescheduling(false);
						}}
					/>

					<CancelEnrollmentModal
						auth={props.auth}
						open={longterm}
						target={enrollment}
						onSaved={async () => {
							setLongterm(false);
							await enrollmentR.refresh();
							await sessionDetailsR.refresh();
							await ticketsR.refresh();
							await props.onRefresh?.();
						}}
						onCancel={() => {
							setLongterm(false);
						}}
					/>

					<ChangeCourseModal
						auth={props.auth}
						open={changeCourse}
						target={enrollment}
						onUpdated={async () => {
							setChangeCourse(false);
							await enrollmentR.refresh();
							await sessionDetailsR.refresh();
							await ticketsR.refresh();
							await props.onRefresh?.();
						}}
						onCancel={() => {
							setChangeCourse(false);
						}}
					/>

					<ExtendEnrollmentModal
						auth={props.auth}
						open={resuming}
						enrollment={enrollment}
						onSubmitted={async () => {
							setResuming(false);
							await enrollmentR.refresh();
							await sessionDetailsR.refresh();
							await ticketsR.refresh();
							await props.onRefresh?.();
						}}
						onCancel={() => {
							setResuming(false);
						}}
					/>

					{createPortal(
						<Menu id="session">
							<Item
								id="absence"
								onClick={async () => {
									if (!selectedSession) return;
									await cancelAndMakeup(props.auth, modals, selectedSession, enrollment, async () => {
										await enrollmentR.refresh();
										await sessionDetailsR.refresh();
										await props.onRefresh?.();

										ctx.hideAll();
										setSelectedSession(undefined);
									});
								}}
							>
								{selectedSession?.is_cancelled === false ? "Absence and makeup" : "Undo absence"}
							</Item>
							<Item
								id="report"
								onClick={() => {
									if (!selectedSession) return;
									modals.editStudentSessionReport({
										target: {
											auth: props.auth,
											uuid: selectedSession.uuid,
											enrollment,
										},
										async onUpdated() {
											await enrollmentR.refresh();
											await sessionDetailsR.refresh();
										},
									});
								}}
							>
								Attendance
							</Item>
						</Menu>,
						document.body,
					)}
				</>
			)}
		</div>
	);
}
