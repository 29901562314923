import { useCallback, useState } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import { Button, Checkbox, InputNumber, Select } from "antd";
import {
	bind,
	generateSessions,
	regularTimeDescriptor,
	ReturnPromiseType,
	SectionScheduleType,
	usePromise,
	useQuery,
} from "../../util";
import { ClassroomSelector } from "./classroom-selector";
import { CourseSelectorTree } from "./course-selector-tree";
import { SectionDateTimeSelector } from "./section-datetime-selector";

export function SectionCreator(props: {
	onCreateSection: (section: ReturnPromiseType<typeof API.Branch.Section.Get>) => void;
	creditsAvailable?: number;
	disabled?: boolean;
	auth: AuthContextLoggedIn;
}) {
	const [course, setCourse] = useState<ReturnPromiseType<typeof API.Course.GetAll>[number]>();
	const [classroom, setClassroom] = useState<ReturnPromiseType<typeof API.Course.GetClassrooms>[number]>();
	const [duration, setDuration] = useState(60);
	const [startDatetime, setStartDatetime] = useState<Date>();
	const [sessionCount, setSessionCount] = useState<number>(0);
	const [capacity] = useState<number>(1);
	const [vip, setVip] = useState(false);
	const [section, setSection] = useState<ReturnPromiseType<typeof API.Branch.Section.Get>>();
	const [sectionScheduleType, setSectionScheduleType] = useState<SectionScheduleType>("WEEKLY");
	const [ignoreHolidays, setIgnoreHolidays] = useState<boolean>(false);

	const holidays = usePromise(
		useCallback(
			() =>
				API.Branch.GetFutureHolidays(props.auth, {
					uuid: props.auth.currentBranch.uuid,
				}),
			[props.auth],
		),
	);

	const query = useQuery();

	const querystring = query.get("date");
	const initialValue = bind(querystring, x => new Date(x)) ?? undefined;

	const sessions =
		holidays &&
		startDatetime &&
		course &&
		generateSessions(startDatetime, sessionCount, duration, holidays, sectionScheduleType, {
			ignoreHolidays: ignoreHolidays,
		});

	return (
		<div className="subcontent-flex-vertical">
			<div>
				<span>Select course</span>
				<div>
					<CourseSelectorTree
						auth={props.auth}
						disabled={props.disabled}
						onChange={selection => {
							setCourse(selection);
							setDuration(selection?.session_duration ?? 60);
							setSessionCount(selection?.session_count ?? 1);
						}}
					/>
				</div>
			</div>

			{course && (
				<div>
					<span>Select the number of sessions</span>
					<div>
						<InputNumber
							onChange={e => setSessionCount(+(e ?? 1))}
							precision={0}
							value={sessionCount}
							disabled={props.disabled}
						/>
						<Checkbox
							style={{ marginLeft: 16 }}
							onChange={e => {
								if (e.target.checked) {
									const maxRedemption =
										(props.creditsAvailable ?? 0) / (Math.floor(duration / 30) / 2);
									if (maxRedemption > course.session_count) {
										setSessionCount(maxRedemption);
									} else {
										setSessionCount(48);
									}
								} else {
									setSessionCount(course.session_count);
								}
								setVip(e.target.checked);
							}}
							checked={vip}
							disabled={props.disabled}
						>
							VIP
						</Checkbox>
					</div>
					<span>Set the session duration (minutes)</span>
					<div>
						<InputNumber
							onChange={e => setDuration(+(e ?? 60))}
							precision={0}
							value={duration}
							disabled={props.disabled}
						/>
					</div>
					<span>Select the schedule type</span>
					<div>
						<Select
							style={{ width: "100%" }}
							options={[
								{ value: "WEEKLY", label: "Weekly" },
								{ value: "DAILY", label: "Daily" },
								{ value: "BIWEEKLY", label: "Biweekly" },
							]}
							value={sectionScheduleType}
							onChange={v => {
								if (v === undefined) return;
								setSectionScheduleType(v as SectionScheduleType);
							}}
						/>
					</div>
				</div>
			)}

			{course && (
				<ClassroomSelector
					auth={props.auth}
					disabled={props.disabled}
					onChange={x => setClassroom(x)}
					value={classroom?.uuid}
					courseId={course.uuid}
				/>
			)}

			{classroom && course && (
				<div>
					<SectionDateTimeSelector
						auth={props.auth}
						initialDatetime={initialValue ?? undefined}
						duration={duration}
						sessionCount={sessionCount}
						classroom={classroom}
						disabled={props.disabled}
						onSelect={datetime => setStartDatetime(datetime)}
					/>
					<Checkbox checked={ignoreHolidays} onChange={e => setIgnoreHolidays(e.target.checked)}>
						Ignore holidays
					</Checkbox>
				</div>
			)}

			{sessions && (
				<div>
					<div>Section starts and ends</div>
					{new Date(sessions.sessions[0].datetime).toLocaleDateString()} -{" "}
					{new Date(sessions.sessions[sessions.sessions.length - 1].datetime).toLocaleDateString()}
					{sessions.startDateShifted && (
						<div style={{ color: "red" }}>
							Warning: the start date you selected is on a holiday so it has been shifted further back.
						</div>
					)}
				</div>
			)}

			{startDatetime && !section && classroom && course && sessions && (
				<Button
					type="primary"
					onClick={async () => {
						if (!props.auth.loggedIn) return;

						const id = (
							await API.Branch.Section.Create(props.auth, {
								capacity,
								vip,
								session_count: sessionCount,
								session_duration: duration,
								classroom_uuid: classroom.uuid,
								course_uuid: course.uuid,
								sessions: sessions.sessions,
								regular_time_descriptor: regularTimeDescriptor(startDatetime, sectionScheduleType),
								schedule_type: sectionScheduleType,
							})
						).uuid;

						const newSection = await API.Branch.Section.Get(props.auth, { uuid: id });

						setSection(newSection);
						props.onCreateSection(newSection);
					}}
				>
					Create this section
				</Button>
			)}
		</div>
	);
}
