import { Button, DatePicker, Input } from "antd";
import { useCallback, useState } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn, Authenticated } from "../../auth";
import { endOfDay, sessionFormatter, startOfDay, usePromiseExtended } from "../../util";
import { Modal } from "antd";

export function HolidaysPage(props: { auth: AuthContextLoggedIn }) {
	const [holidays, holidaysR] = usePromiseExtended(
		useCallback(async () => {
			return (await API.Branch.GetFutureHolidays(props.auth, { uuid: props.auth.currentBranch.uuid })).map(x => ({
				start_datetime: new Date(x.start_datetime),
				name: x.name,
				end_datetime: new Date(x.end_datetime),
			}));
		}, [props.auth]),
		undefined,
		useCallback(
			async val => {
				if (!val) return;

				await API.Branch.SetFutureHolidays(props.auth, {
					uuid: props.auth.currentBranch.uuid,
					holidays: val.map(x => ({
						start: x.start_datetime.toISOString(),
						end: x.end_datetime.toISOString(),
						name: x.name,
					})),
				});
			},
			[props.auth],
		),
	);

	const [startDate, setStartDate] = useState<Date>();
	const [endDate, setEndDate] = useState<Date>();
	const [name, setName] = useState<string>("");

	return (
		<Authenticated as="admin">
			{holidays && (
				<div className="subcontent-flex-vertical" style={{ width: 500 }}>
					<div>
						<h3>Set holiday times for this year (click a list item to delete)</h3>
						{holidays.map((x, index) => (
							<div
								key={index}
								className="hover-list-item"
								onClick={async () => {
									await holidaysR.update(holidays.filter((_, index2) => index !== index2));
								}}
							>
								{`${x.name}: ${new Date(x.start_datetime).toLocaleString(
									[],
									sessionFormatter,
								)} - ${new Date(x.end_datetime).toLocaleString([], sessionFormatter)}`}
							</div>
						))}
					</div>
					<div style={{ border: "1px solid black", padding: 10, margin: 10 }}>
						<Input placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
						<div>
							<DatePicker.RangePicker
								onChange={e => {
									if (!e) return;
									setStartDate(e[0]?.toDate());
									setEndDate(e[1]?.toDate());
								}}
							/>
						</div>
						<Button
							type="primary"
							disabled={!startDate || !endDate || !name}
							onClick={async () => {
								if (!startDate) return;
								if (!endDate) return;
								if (
									holidays.some(x => x.start_datetime.getTime() === startOfDay(startDate).getTime())
								) {
									alert("Cannot have two holidays with same start date");
									return;
								}

								await holidaysR.update([
									...holidays,
									{
										name,
										start_datetime: startOfDay(startDate),
										end_datetime: endOfDay(endDate),
									},
								]);
							}}
						>
							Add holiday
						</Button>
					</div>
					{props.auth.auth.root && (
						<div>
							<Button
								onClick={() => {
									Modal.confirm({
										title: "Confirm copy",
										async onOk() {
											await API.Branch.CopyHolidaysToOtherBranches(props.auth, {
												source_branch_uuid: props.auth.currentBranch.uuid,
											});
										},
									});
								}}
							>
								Copy holidays to all other branches
							</Button>
						</div>
					)}
				</div>
			)}
		</Authenticated>
	);
}
