import { Button, DatePicker, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useState } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn, Authenticated } from "../../auth";
import { setTotalMinutes } from "../../util";

export function SystemMessagePage(props: { auth: AuthContextLoggedIn }) {
	const [title, setTitle] = useState("");
	const [text, setText] = useState("");
	const [sending, setSending] = useState(false);
	const [sent, setSent] = useState(false);
	const [datetime, setDatetime] = useState(new Date());

	return (
		<Authenticated as="root">
			<div className="content-flex-vertical" style={{ width: 400 }}>
				<DatePicker value={moment(datetime)} onChange={e => setDatetime(e?.toDate() ?? new Date())} />
				<Input placeholder="Title" value={title} onChange={x => setTitle(x.target.value)} />
				<TextArea value={text} onChange={x => setText(x.target.value)} rows={5} />
				<Button
					loading={sending}
					type="primary"
					onClick={async () => {
						if (text.length < 1 || title.length < 1) {
							return;
						}

						setSending(true);
						await API.Ticket.CreateBroadcast(props.auth, {
							title,
							text,
							datetime: setTotalMinutes(datetime, 12 * 60).toISOString(),
						});
						setSending(false);
						setSent(true);
					}}
				>
					Send
				</Button>
				{sent && <>Sent</>}
			</div>
		</Authenticated>
	);
}
