import { TimePicker, DatePicker, Button, Input, Select, Row, Col, InputNumber } from "antd";
import { useCallback, useEffect, useState } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import moment, { Moment } from "moment";
import "./time-picker.css";
import { usePromise } from "../../util";
import { TicketType } from "ro4-server/src/api/ticket";

export function TicketCreatorModalInner(props: {
	datetime?: Date;
	student_session_uuid?: string;
	section_session_uuid?: string;
	student_uuid?: string;
	initialType: TicketType;
	initialTitle?: string;
	initialText?: string;
	section_uuid?: string;
	enrollment_uuid?: string;

	showTaskCreator?: boolean;
	taskDatetime?: Date;
	taskAssigneeId?: string;

	onCreated: (id: string) => void;
	auth: AuthContextLoggedIn;
}) {
	const [title, setTitle] = useState("");
	const [text, setText] = useState("");
	const [type, setType] = useState("message" as TicketType);
	const [date, setDate] = useState<Date>(new Date());
	const [assignee, setAssignee] = useState<string>();
	const [taskDate, setTaskDate] = useState<Date>(new Date());
	const [taskTimeBegin, setTaskTimeBegin] = useState<Moment>(moment());
	const [taskTimeEnd, setTaskTimeEnd] = useState<Moment>(moment().add(1, "h"));
	const [taskRepeat, setTaskRepeat] = useState<number>(0);

	useEffect(() => {
		setTaskRepeat(0);
		setTitle(props.initialTitle ?? "");
		setText(props.initialText ?? "");
		setType(props.initialType);
		setDate(props.datetime ?? new Date());
		setAssignee(props.taskAssigneeId);
		setTaskDate(props.taskDatetime ?? new Date());
		setTaskTimeBegin(moment(props.taskDatetime ?? new Date()));
		setTaskTimeEnd(moment(props.taskDatetime ?? new Date()).add(1, "h"));
	}, [props]);

	const studentName = usePromise(
		useCallback(
			async () =>
				props.student_uuid !== undefined
					? await API.Student.GetName(props.auth, { uuid: props.student_uuid })
					: undefined,
			[props.student_uuid, props.auth],
		),
	);

	const users = usePromise(
		useCallback(
			async () =>
				props.showTaskCreator === true
					? await API.User.GetAll(props.auth, { branch_uuid: props.auth.currentBranch.uuid })
					: undefined,
			[props.showTaskCreator, props.auth],
		),
	);

	const valid = date !== undefined && title !== "" && (props.showTaskCreator !== true || assignee !== undefined);

	return (
		<Row gutter={[16, 16]}>
			<Col span={props.showTaskCreator === true ? 12 : 24} className="subcontent-flex-vertical">
				<div>
					<span>
						{studentName !== undefined ? `Create message regarding ${studentName.name}` : "Create message"}
					</span>
				</div>
				<div>
					<span>Topic</span>
					<Input onChange={v => setTitle(v.target.value)} value={title} />
				</div>
				<div>
					<span>Select a date</span>
					<DatePicker value={moment(date)} onSelect={e => setDate(e.toDate())} style={{ width: "100%" }} />
				</div>
				<div>
					<span>Message type</span>
					<Select
						style={{ width: "100%" }}
						options={[
							{ value: "note", label: "Note" },
							{ value: "task", label: "Task" },
							{ value: "message", label: "Message" },
							{ value: "record", label: "Record" },
						]}
						value={type as string}
						onChange={v => {
							if (v === undefined || v === "") return;
							setType(v as TicketType);
						}}
					/>
				</div>
				<div>
					<span>Text</span>
					<Input.TextArea onChange={v => setText(v.target.value)} value={text} rows={5} />
				</div>
				<Button
					type="primary"
					disabled={valid !== true}
					onClick={async () => {
						if (valid !== true) return;

						const tasks: { datetime: string; assignee_uuid: string; duration: number }[] = [];
						if (props.showTaskCreator === true) {
							if (assignee === undefined) return;

							const taskDateCombined = new Date(taskDate);
							const duration =
								taskTimeEnd.hours() * 60 +
								taskTimeEnd.minutes() -
								taskTimeBegin.hours() * 60 -
								taskTimeBegin.minutes();

							taskDateCombined.setHours(taskTimeBegin.hours());
							taskDateCombined.setMinutes(taskTimeBegin.minutes());

							for (let i = 0; i < taskRepeat + 1; i++) {
								tasks.push({
									datetime: taskDateCombined.toUTCString(),
									assignee_uuid: assignee,
									duration,
								});

								taskDateCombined.setDate(taskDateCombined.getDate() + 7);
							}
						}

						const r = await API.Ticket.Create(props.auth, {
							type: type,
							date: date.toUTCString(),
							student_session_uuid: props.student_session_uuid,
							section_session_uuid: props.section_session_uuid,
							section_uuid: props.section_uuid,
							student_uuid: props.student_uuid,
							enrollment_uuid: props.enrollment_uuid,
							title,
							text,
							recipients: assignee !== undefined ? [{ uuid: assignee }] : [],
							recipient_roles: ["reception"],
							branch_uuid: props.auth.currentBranch.uuid,
							tasks,
						});
						props.onCreated(r.uuid);
					}}
				>
					Save
				</Button>
			</Col>
			{props.showTaskCreator === true && users && (
				<Col span={12} className="subcontent-flex-vertical">
					<div>
						<span>Set task details</span>
					</div>
					<div>
						<span>Select assignee</span>
						<Select
							options={users.map(x => ({ value: x.uuid, label: x.name }))}
							value={assignee}
							onChange={v => {
								setAssignee(v);
							}}
							style={{ width: "100%" }}
						/>
					</div>
					<div>
						<span>Select the task date</span>
						<DatePicker
							value={moment(taskDate)}
							onSelect={e => setTaskDate(e.toDate())}
							style={{ width: "100%" }}
						/>
					</div>
					<div>
						<label>Task time</label>
						<TimePicker.RangePicker
							use12Hours
							value={[taskTimeBegin, taskTimeEnd]}
							format={"HH:mm"}
							onChange={x => {
								setTaskTimeBegin(x?.[0] ?? moment());
								setTaskTimeEnd(x?.[1] ?? moment().add(1, "h"));
							}}
						/>
					</div>
					<div>
						Repeat this task weekly for{" "}
						<InputNumber
							value={taskRepeat}
							precision={0}
							onChange={x => setTaskRepeat(x ?? 0)}
							style={{ width: 50 }}
						/>{" "}
						additional weeks
					</div>
				</Col>
			)}
		</Row>
	);
}
