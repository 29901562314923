import { TreeSelect, Tree } from "antd";
import { useMemo } from "react";
import { API } from "../../api-types";
import { ReturnPromiseType } from "../../util";

interface TreeData {
	title: string;
	key: string;
	value: string;
	selectable?: boolean;
	children?: TreeData[];
	isLeaf: boolean;
}

function makeTree(templateList: ReturnPromiseType<typeof API.Mail.GetTemplateList> | undefined) {
	if (!templateList) return [];

	const fakeRoot: TreeData = { children: [], key: "root", title: "root", value: "", isLeaf: false };
	let counter = 0;

	templateList.forEach(x => {
		const split = x.name.split(" ");

		let current = fakeRoot;

		split.forEach((y, i, a) => {
			const children = current.children;
			if (!children) {
				throw new Error();
			}

			if (i < a.length - 1) {
				const found = children.find((z: TreeData) => z.title === y && z.children);

				if (!found) {
					current = {
						title: y,
						key: `NODE${counter}`,
						value: `NODE${counter}`,
						children: [],
						selectable: false,
						isLeaf: false,
					};
					counter += 1;
					children.push(current);
				} else {
					current = found;
				}
			} else {
				children.push({
					title: x.name,
					key: x.file,
					value: x.file,
					isLeaf: true,
				});
			}
		});
	});

	return fakeRoot.children;
}

export function TemplateSelectorTree(props: {
	selectedTemplate?: string;
	defaultTemplate?: string;
	disabled?: boolean;
	templates: ReturnPromiseType<typeof API.Mail.GetTemplateList> | undefined;
	onChange: (selected: string | undefined) => void;
}) {
	const tree = useMemo(() => makeTree(props.templates), [props.templates]);

	return (
		<>
			{tree && (
				<TreeSelect
					style={{ width: "100%" }}
					treeData={tree}
					allowClear
					showSearch
					treeNodeFilterProp="title"
					defaultValue={props.defaultTemplate}
					value={props.selectedTemplate}
					disabled={props.disabled}
					onChange={selected => {
						props.onChange(selected);
					}}
				/>
			)}
		</>
	);
}

export function TemplateTree(props: {
	disabled?: boolean;
	selectedTemplate: string | undefined;
	templates: ReturnPromiseType<typeof API.Mail.GetTemplateList> | undefined;
	onChange: (selected: ReturnPromiseType<typeof API.Mail.GetTemplateList>[number]) => void;
}) {
	const tree = useMemo(() => makeTree(props.templates), [props.templates]);
	if (!tree) return <></>;

	return (
		<Tree.DirectoryTree
			autoExpandParent
			expandAction="click"
			style={{ width: "100%" }}
			treeData={tree}
			selectedKeys={props.selectedTemplate !== undefined ? [props.selectedTemplate] : []}
			disabled={props.disabled}
			onSelect={selected => {
				if (selected.length < 1) return;
				const found = props.templates?.find(x => x.file === selected[0]);
				if (!found) return;
				props.onChange(found);
			}}
		/>
	);
}
