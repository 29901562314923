import { Modal } from "antd";
import { useCallback, useState } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import { ReturnPromiseType, usePromise } from "../../util";
import { SectionDateTimeSelector } from "../misc/section-datetime-selector";

export function MakeupCreatorModal(props: {
	cancelText?: string;
	bodyText?: string;
	target:
		| {
				section: { classroom: { uuid: string }; session_duration: number };
				original_session: { uuid: string };
				enrollment: { uuid: string };
				auth: AuthContextLoggedIn;
		  }
		| undefined;
	onCreated: (x: ReturnPromiseType<typeof API.Student.Enrollment.AddMakeup>) => void;
	onCancel: () => void;
}) {
	const [selected, setSelected] = useState<Date>();

	const classroom = usePromise(
		useCallback(async () => {
			if (!props.target) return;
			return API.Branch.Classroom.Get(props.target.auth, { uuid: props.target.section.classroom.uuid });
		}, [props.target]),
	);

	return (
		<Modal
			destroyOnClose
			open={!!props.target}
			maskClosable={false}
			okButtonProps={{ disabled: !selected }}
			okText="Create makeup session"
			cancelText={props.cancelText}
			onOk={async () => {
				if (!selected) return;
				if (!props.target) return;

				const result = await API.Student.Enrollment.AddMakeup(props.target.auth, {
					original_session_uuid: props.target.original_session.uuid,
					uuid: props.target.enrollment.uuid,
					datetime: selected.toISOString(),
					duration: props.target.section.session_duration,
				});

				props.onCreated(result);
			}}
			onCancel={() => props.onCancel()}
		>
			{props.bodyText}
			{props.bodyText !== undefined && <br />}
			Select makeup time
			{classroom && props.target && (
				<div>
					<SectionDateTimeSelector
						auth={props.target.auth}
						initialDatetime={undefined}
						duration={props.target.section.session_duration}
						sessionCount={1}
						classroom={classroom}
						onSelect={datetime => setSelected(datetime)}
					/>
				</div>
			)}
		</Modal>
	);
}
