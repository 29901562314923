import { Button, Form, InputNumber } from "antd";
import { useForm } from "antd/lib/form/Form";
import Modal from "antd/lib/modal/Modal";

export function SectionSessionEditorModal({
	target,
	onSave,
	onCancel,
}: {
	target: { uuid: string; lesson_number: number | null } | undefined;
	onSave: (id: string, values: { lesson_number: number }) => void;
	onCancel: () => void;
}) {
	function Inner(props: { innerTarget: NonNullable<typeof target> }) {
		const [form] = useForm();

		return (
			<Form
				form={form}
				initialValues={{ ordinal: props.innerTarget.lesson_number ?? -1 }}
				onFinish={values => {
					onSave(props.innerTarget.uuid, { lesson_number: values.ordinal });
				}}
			>
				<Form.Item name="ordinal" label="Lesson number" rules={[{ required: true, type: "number" }]}>
					<InputNumber min={1} max={99} precision={0} />
				</Form.Item>
				<Button type="primary" onClick={() => form.submit()}>
					Save
				</Button>
			</Form>
		);
	}

	return (
		<Modal
			destroyOnClose
			getContainer={false}
			open={!!target}
			footer={null}
			onCancel={() => {
				onCancel();
			}}
		>
			{target && <Inner innerTarget={target} />}
		</Modal>
	);
}
