import { TemplateTree } from "../misc/template-selector-tree";
import { Button, Input, Row, Col } from "antd";
import { useCallback, useState } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn, Authenticated } from "../../auth";
import { usePromiseExtended } from "../../util";

export function TemplatesPage(props: { auth: AuthContextLoggedIn }) {
	const [templates, templatesR] = usePromiseExtended(
		useCallback(
			() => API.Mail.GetTemplateList(props.auth, { branch_uuid: props.auth.currentBranch.uuid }),
			[props.auth],
		),
	);

	const [selectedTemplate, setSelectedTemplate] = useState<{ file: string } | "new">();
	const [title, setTitle] = useState<string>("");
	const [fileName, setFileName] = useState<string>("");

	const [source, setSource] = useState<string>("");

	return (
		<Authenticated as="root">
			<div className="content-flex-horizontal">
				<div className="subcontent-flex-vertical" style={{ width: 300 }}>
					<TemplateTree
						templates={templates}
						selectedTemplate={selectedTemplate !== "new" ? selectedTemplate?.file : undefined}
						onChange={async template => {
							setSelectedTemplate({ file: template.file });
							setFileName(template.file);
							setTitle(template.name);
							setSource((await API.Mail.GetTemplateRaw(props.auth, { file: template.file })).html);
						}}
					/>
					<Button
						onClick={() => {
							setSelectedTemplate("new");
							setSource("");
							setTitle("");
							setFileName("");
						}}
					>
						Create new
					</Button>
				</div>
				<div style={{ flexGrow: 1 }}>
					<Row gutter={[16, 16]}>
						<Col span={12}>
							{selectedTemplate === "new" && <div>Creating New</div>}
							<span>File Name (no spaces; required)</span>
							<Input
								onChange={x => setFileName(x.target.value)}
								value={fileName}
								disabled={selectedTemplate === undefined}
								style={{ width: "100%" }}
							/>
							<span>Name</span>
							<Input
								onChange={x => setTitle(x.target.value)}
								value={title}
								disabled={selectedTemplate === undefined}
								style={{ width: "100%" }}
							/>
							<span>HTML Editor</span>
							<Input.TextArea
								rows={30}
								style={{ fontFamily: "monospace" }}
								disabled={selectedTemplate === undefined}
								onChange={x => setSource(x.target.value)}
								value={source}
							/>
							<Button
								type="primary"
								style={{ marginTop: 50 }}
								onClick={async () => {
									if (selectedTemplate === undefined) return;

									if (selectedTemplate !== "new") {
										await API.Mail.UpdateTemplate(props.auth, {
											file: selectedTemplate.file,
											new_file: fileName,
											content: source,
											name: title,
										});
										setSelectedTemplate({ file: fileName });
									} else {
										await API.Mail.CreateTemplate(props.auth, {
											file: fileName,
											content: source,
											name: title,
											auth: "teacher",
										});
										setSelectedTemplate({ file: fileName });
									}
									await templatesR.refresh();
								}}
							>
								Save
							</Button>
							{selectedTemplate !== "new" && (
								<Button
									onClick={() => {
										setSelectedTemplate("new");
										setSource(source);
										setTitle(title);
										setFileName(fileName);
									}}
								>
									Duplicate
								</Button>
							)}
						</Col>
						<Col span={12}>
							{source !== undefined && (
								<div>
									<span>HTML Preview</span>
									<div>
										<iframe title="template" srcDoc={source} width="100%" height={800} />
									</div>
								</div>
							)}
						</Col>
					</Row>
				</div>
			</div>
		</Authenticated>
	);
}
