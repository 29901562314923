import { Button, DatePicker, Drawer, Table } from "antd";
import download from "downloadjs";
import moment from "moment";
import { useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { API } from "../../api-types";
import { AuthContextLoggedIn, Authenticated } from "../../auth";
import { endOfDay, sessionFormatter, startOfDay, unformatMoney, usePromise, useQuery } from "../../util";
import { PaymentData } from "../misc/payment-data";

export function FinancePage(props: { auth: AuthContextLoggedIn }) {
	const startOfMonth = new Date();
	startOfMonth.setDate(1);

	const [date, setDate] = useState([startOfMonth, new Date()]);
	const branch = usePromise(
		useCallback(async () => {
			return await API.Branch.Get(props.auth, { uuid: props.auth.currentBranch.uuid });
		}, [props.auth]),
	);

	const query = useQuery();
	const paymentUuid = query.get("payment");
	const history = useHistory();
	const location = useLocation();

	const payments = usePromise(
		useCallback(() => {
			return API.Branch.Payment.GetAll(props.auth, {
				branch_uuid: props.auth.currentBranch.uuid,
				start: startOfDay(date[0]).toISOString(),
				end: endOfDay(date[1]).toISOString(),
			});
		}, [date, props.auth]),
	);

	const paymentsV = usePromise(
		useCallback(() => {
			return API.Branch.Payment.GetAll(props.auth, {
				branch_uuid: props.auth.currentBranch.uuid,
				start: startOfDay(date[0]).toISOString(),
				end: endOfDay(date[1]).toISOString(),
				deleted: true,
			});
		}, [date, props.auth]),
	);

	return (
		<Authenticated as="reception">
			<div className="subcontent-flex-vertical">
				<div>Select a date range</div>
				<div>
					<DatePicker.RangePicker
						picker="date"
						value={[moment(date[0]), moment(date[1])]}
						onChange={e => {
							setDate([e?.[0]?.toDate() ?? startOfMonth, e?.[1]?.toDate() ?? new Date()]);
						}}
						style={{ width: 500 }}
					/>
					<Button
						onClick={() => {
							if (!payments) return;
							download(
								"date,student,course,start,end,rate,count,discount,tax,total,reference,invoice\n" +
									payments
										.map(x => {
											return (
												[
													new Date(x.created).toISOString(),
													x.student?.name ?? "",
													x.enrollment?.section?.course?.name ?? "",
													x.enrollment?.start_datetime !== undefined
														? new Date(x.enrollment.start_datetime).toISOString()
														: "",
													x.enrollment?.end_datetime !== undefined
														? new Date(x.enrollment.end_datetime).toISOString()
														: "",
													x.unit_price,
													x.quantity,
													"-" + x.surcharge,
													x.tax,
													x.total,
													x.identifier,
													x.invoice_number,
												].join(",") + "\n"
											);
										})
										.join(""),
								"export.csv",
								"text/plain",
							);
						}}
					>
						Export to Excel
					</Button>
				</div>
				{payments && (
					<>
						<Table
							rowKey={"uuid"}
							columns={[
								{
									title: "Date",
									dataIndex: "created",
									key: "datetime",
									render: (s: string) => new Date(s).toLocaleString([], sessionFormatter),
								},
								{
									title: "Student",
									dataIndex: ["student", "name"],
									key: "student",
								},
								{
									title: "Course",
									dataIndex: ["enrollment", "section", "course", "name"],
									key: "course",
								},
								{
									title: "Start/End",
									dataIndex: ["enrollment"],
									key: "startend",
									render: (s: { start_datetime: string; end_datetime: string } | null) =>
										s
											? `${new Date(s.start_datetime).toLocaleDateString()}-${new Date(
													s.end_datetime,
											  ).toLocaleDateString()}`
											: undefined,
								},
								{
									title: "Rate",
									dataIndex: "unit_price",
									key: "unit_price",
									render: (s: string) => "$" + s,
								},
								{
									title: "Quantity",
									dataIndex: "quantity",
									key: "quantity",
								},
								{
									title: "Discount",
									dataIndex: "surcharge",
									key: "discount",
									render: (s: string) => "$" + (-+s).toFixed(2),
								},
								{
									title: "Before tax",
									dataIndex: [],
									key: "discount",
									render: (s: { unit_price: string; quantity: number; surcharge: string }) => `
											$${(+unformatMoney(s.unit_price) * s.quantity + +unformatMoney(s.surcharge)).toFixed(2)}`,
								},
								{
									title: "Total",
									dataIndex: "total",
									key: "total",
									render: (s: string) => "$" + s,
								},
								{
									title: "Reference",
									dataIndex: "identifier",
									key: "identifier",
								},
								{
									title: "Invoice",
									dataIndex: "invoice_number",
									key: "invoice",
								},
							]}
							onRow={record => ({
								onClick() {
									history.push({
										pathname: location.pathname,
										search: `?payment=${record.uuid}`,
									});
								},
							})}
							dataSource={payments}
						/>
						<div>
							<div>
								Total amount received for this period (after tax): $
								{payments.reduce<number>((y, x) => y + +unformatMoney(x.total), 0).toFixed(2)}
							</div>
							<div>
								Total amount received for this period (before tax): $
								{payments
									.reduce<number>((y, x) => y + +unformatMoney(x.total) - +unformatMoney(x.tax), 0)
									.toFixed(2)}
							</div>
							<div style={{ marginTop: 16 }}>
								Royalty due for this period: $
								{(
									(payments.reduce<number>(
										(y, x) => y + +unformatMoney(x.total) - +unformatMoney(x.tax),
										0,
									) *
										(branch?.royalty_percent ?? 0)) /
									100
								).toFixed(2)}
							</div>
						</div>
					</>
				)}
				<div>Voided payments</div>
				{paymentsV && (
					<Table
						rowKey={"uuid"}
						columns={[
							{
								title: "Date",
								dataIndex: "created",
								key: "datetime",
								render: (s: string) => new Date(s).toLocaleString([], sessionFormatter),
							},
							{
								title: "Voided date",
								dataIndex: "deleted",
								key: "voided",
								render: (s: string) => new Date(s).toLocaleString([], sessionFormatter),
							},
							{
								title: "Student",
								dataIndex: ["student", "name"],
								key: "student",
							},
							{
								title: "Course",
								dataIndex: ["enrollment", "section", "course", "name"],
								key: "course",
							},
							{
								title: "Total",
								dataIndex: "total",
								key: "total",
								render: (s: string) => "$" + s,
							},
							{
								title: "Reference",
								dataIndex: "identifier",
								key: "identifier",
							},
							{
								title: "Invoice",
								dataIndex: "invoice_number",
								key: "invoice",
							},
						]}
						onRow={record => ({
							onClick() {
								history.push({
									pathname: location.pathname,
									search: `?payment=${record.uuid}`,
								});
							},
						})}
						dataSource={paymentsV}
					/>
				)}
			</div>
			<Drawer
				width={600}
				title="Payment information"
				placement="right"
				onClose={() => {
					history.push({
						pathname: location.pathname,
						search: "",
					});
				}}
				open={paymentUuid !== null}
			>
				{paymentUuid !== null && <PaymentData uuid={paymentUuid} auth={props.auth} />}
			</Drawer>
		</Authenticated>
	);
}
