import React, { createContext, useCallback, useContext, useState } from "react";
import { Button, Select } from "antd";
import { API } from "./api-types";
import { useHistory } from "react-router-dom";

export interface Auth {
	token: string;
	name: string;
	uuid: string;
	root: boolean;
	branches: { name: string; uuid: string; role: "admin" | "teacher" | "reception"; timezone?: string }[];
	currentBranch: number;
}

export interface AuthContextLoggedIn {
	loggedIn: true;
	auth: Auth;
	setBranch: (index: number) => void;
	signin: (user: string, pass: string) => Promise<void>;
	signout: () => void;
	readonly currentBranch: Auth["branches"][number];
}

export type AuthContext =
	| AuthContextLoggedIn
	| { loggedIn: false; signin: (user: string, pass: string) => Promise<void> };

const stored = window.localStorage.getItem("auth");
let initialAuth: Auth | null = null;
if (stored !== null) {
	try {
		initialAuth = JSON.parse(stored);
		if (initialAuth === null) throw Error();
		if (typeof initialAuth.token !== "string") throw Error();
		if (typeof initialAuth.name !== "string") throw Error();
		if (typeof initialAuth.uuid !== "string") throw Error();
		if (typeof initialAuth.root !== "boolean") throw Error();
		if (typeof initialAuth.currentBranch !== "number") throw Error();
		if (!(initialAuth.branches instanceof Array)) throw Error();
		if (
			initialAuth.branches.some(x => {
				return typeof x.name !== "string" || typeof x.role !== "string" || typeof x.uuid !== "string";
			})
		)
			throw Error();
	} catch {
		initialAuth = null;
	}
}
const authContext = createContext<AuthContext>(
	initialAuth === null
		? {
				loggedIn: false,
				signin: async () => {},
		  }
		: {
				loggedIn: true,
				auth: initialAuth,
				setBranch: _ => {},
				signin: async () => {},
				signout: () => {},
				get currentBranch() {
					return this.auth.branches[this.auth.currentBranch];
				},
		  },
);

export function ProvideAuth(param: { children: React.ReactNode }) {
	const auth = useProvideAuth();
	return <authContext.Provider value={auth}>{param.children}</authContext.Provider>;
}

function useProvideAuth(): AuthContext {
	const [auth, setAuth] = useState(initialAuth);

	const signin = useCallback(async (user: string, pass: string) => {
		const result = await API.Auth.Login({ username: user, password: pass });
		const newAuth = {
			loggedIn: true,
			name: result.name,
			uuid: result.uuid,
			token: result.token,
			root: result.root,
			currentBranch: 0,
			branches: result.roles.map(x => ({
				role: x.role as any,
				uuid: x.branch.uuid,
				name: x.branch.name,
				timezone: x.branch.timezone,
			})),
		};
		window.localStorage.setItem("auth", JSON.stringify(newAuth));
		setAuth(newAuth);
	}, []);

	const signout = useCallback(() => {
		window.localStorage.removeItem("auth");
		setAuth(null);
	}, []);

	const setBranch = useCallback(
		(index: number) => {
			if (auth !== null) {
				const newAuth = { ...auth };
				newAuth.currentBranch = index;
				window.localStorage.setItem("auth", JSON.stringify(newAuth));
				setAuth(newAuth);
			}
		},
		[auth],
	);

	// Return the user object and auth methods
	return auth === null
		? {
				loggedIn: false,
				signin,
		  }
		: {
				loggedIn: true,
				auth,
				setBranch,
				signin,
				signout,
				get currentBranch() {
					return this.auth.branches[this.auth.currentBranch];
				},
		  };
}

export function useAuth<AssertLoggedIn extends boolean = false>(
	assertLoggedIn?: AssertLoggedIn,
): AssertLoggedIn extends true ? AuthContextLoggedIn : AuthContext {
	const ctx = useContext(authContext);
	if (assertLoggedIn === true) {
		if (!ctx.loggedIn) throw new Error();
	}

	return ctx as never;
}

const hierarchy = ["root", "admin", "reception", "teacher"];
export function currentRoleAtLeast(auth: AuthContext, level: "root" | "teacher" | "reception" | "admin") {
	if (!auth.loggedIn) return false;
	return hierarchy.indexOf(auth.currentBranch.role) <= hierarchy.indexOf(level);
}

export function Authenticated(props: { as?: "root" | "teacher" | "reception" | "admin"; children: React.ReactNode }) {
	const auth = useAuth();

	return (
		<>
			{auth.loggedIn ? (
				<>
					{props.as === undefined ||
					hierarchy.indexOf(auth.currentBranch.role) <= hierarchy.indexOf(props.as) ? (
						<>{props.children}</>
					) : (
						<>You are not authorized to view this resource.</>
					)}
				</>
			) : (
				<>Please log in.</>
			)}
		</>
	);
}

export function LoginComponent() {
	const [user, setUser] = useState("");
	const [pass, setPass] = useState("");
	const ctx = useAuth();
	const [err, setErr] = useState("");
	const history = useHistory();

	const loginFunc = async () => {
		try {
			await ctx.signin(user, pass);
			setErr("");
			history.push("/");
		} catch {
			setErr("couldn't log in");
		}
	};

	if (!ctx.loggedIn) {
		return (
			<div>
				<input type="text" value={user} onChange={e => setUser(e.target.value)} />
				<input type="password" value={pass} onChange={e => setPass(e.target.value)} />
				<button onClick={loginFunc}>log in</button>
				{err !== "" ? <div style={{ color: "red" }}>{err}</div> : null}
			</div>
		);
	}

	return (
		<div>
			Logged in as {ctx.auth.name}
			<div style={{ minHeight: 30 }}>
				<Button onClick={ctx.signout} style={{ float: "right" }}>
					Logout
				</Button>
				{ctx.auth.branches.length > 1 && (
					<div style={{ marginTop: 10 }}>
						<div>Select a branch</div>
						<Select
							style={{ width: "100%" }}
							options={ctx.auth.branches.map((x, i) => ({
								value: i,
								label: x.name,
							}))}
							value={ctx.auth.currentBranch}
							onChange={v => {
								if (v === undefined) return;
								ctx.setBranch(v);
							}}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
