import { Button, Drawer, Input, List, Modal, Select } from "antd";
import { useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import moment from "moment";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import {
	endOfDay,
	ReturnPromiseType,
	startOfDay,
	strippedStringSchema,
	stripPhone,
	usePromiseExtended,
	useQuery,
} from "../../util";
import { EnrollmentData } from "../misc/enrollment-data";
import { PaymentData } from "../misc/payment-data";
import { TicketsList } from "../misc/tickets-list";
import { useModals } from "../modal/modals-provider";
import { MailForm } from "../form/mail-form";
import { SMSForm } from "../page/sms-page";
import {
	DateField,
	FormLoader,
	getSubField,
	SelectField,
	AutoCompleteTextField,
	TextField,
	useMyFormik,
	BooleanField,
} from "./form";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import { TicketType } from "ro4-server/src/api/ticket";

const schema = Yup.object({
	name: strippedStringSchema.required(),
	phone: strippedStringSchema.required(),
	email: Yup.string().required().email(),
	address: strippedStringSchema,
	postal: strippedStringSchema,
	city: strippedStringSchema,
	country: strippedStringSchema,
	language: strippedStringSchema,
	school: strippedStringSchema,
	parent_name: strippedStringSchema,
	referrer: strippedStringSchema,
	gender: Yup.string(),
	residency: Yup.string(),
	email_optout: Yup.boolean(),
	birth_date: Yup.date(),
});

export function StudentForm(props: {
	student: ReturnPromiseType<typeof API.Student.Get> | undefined;
	onUpdated?: () => Promise<void> | void;
	onCreated?: (id: string) => Promise<void> | void;
	branches: ReturnPromiseType<typeof API.Branch.GetAll> | undefined;
	auth: AuthContextLoggedIn;
}) {
	const formik = useMyFormik({
		id: props.student?.uuid,
		schema,
		initialValues: props.student
			? {
					name: props.student.name,
					language: props.student.language,
					address: props.student.address,
					city: props.student.city,
					postal: props.student.postal,
					country: props.student.country,
					school: props.student.school,
					email: props.student.email,
					parent_name: props.student.parent_name,
					phone: props.student.phone,
					gender: props.student.gender,
					birth_date: props.student.birth_date ?? undefined,
					residency: props.student.residency,
					referrer: props.student.referrer,
					email_optout: props.student.email_optout,
			  }
			: {
					name: "",
					language: "",
					address: "",
					city: "",
					postal: "",
					country: "",
					school: "",
					email: "",
					parent_name: "",
					phone: "",
					referrer: "",
					gender: undefined as string | undefined,
					birth_date: undefined as string | undefined,
					residency: undefined as string | undefined,
					email_optout: false,
			  },
		onCreate: async values => {
			const newid = (
				await API.Student.Create(props.auth, {
					...values,
					phone: stripPhone(values.phone),
					branch_uuid: props.auth.currentBranch.uuid,
					gender: (values.gender ?? "UNKNOWN") as "UNKNOWN" | "MALE" | "FEMALE" | "OTHER",
					residency: (values.residency ?? "UNKNOWN") as
						| "UNKNOWN"
						| "CITIZEN"
						| "PERMANENT_RESIDENT"
						| "VISITOR",
					birth_date:
						values.birth_date !== undefined
							? moment(new Date(values.birth_date)).format("YYYY-MM-DD")
							: undefined,
				})
			).uuid;

			await props.onCreated?.(newid);
		},
		onUpdate: async (id, values) => {
			await API.Student.Update(props.auth, {
				...values,
				gender: (values.gender ?? "UNKNOWN") as "UNKNOWN" | "MALE" | "FEMALE",
				residency: (values.residency ?? "UNKNOWN") as "UNKNOWN" | "CITIZEN" | "PERMANENT_RESIDENT" | "VISITOR",
				birth_date: values.birth_date,
				phone: stripPhone(values.phone),
				uuid: id,
			});

			await props.onUpdated?.();
		},
	});

	return (
		<FormLoader
			formik={formik}
			new={props.student === undefined}
			disabled={props.student && props.student.branch.uuid !== props.auth.currentBranch.uuid}
		>
			{props.branches &&
				(props.student?.dispatch_branch?.uuid === props.auth.currentBranch.uuid ? (
					<div style={{ color: "red" }}>
						This student is referred from{" "}
						{props.branches.find(x => x.uuid === props.student?.branch?.uuid)?.name}
					</div>
				) : (
					<></>
				))}
			<TextField field={getSubField(formik, "name")} label="Name" />
			<SelectField
				field={getSubField(formik, "gender")}
				label="Gender"
				options={[
					{ value: "UNKNOWN", label: "Unknown" },
					{ value: "MALE", label: "Male" },
					{ value: "FEMALE", label: "Female" },
					{ value: "OTHER", label: "Other" },
				]}
			/>
			<TextField field={getSubField(formik, "phone")} label="Phone" />
			<TextField field={getSubField(formik, "email")} label="Email" />
			<TextField field={getSubField(formik, "address")} label="Address" />
			<TextField field={getSubField(formik, "city")} label="City" />
			<TextField field={getSubField(formik, "postal")} label="Postal" />
			<TextField field={getSubField(formik, "country")} label="Country" />
			<AutoCompleteTextField
				options={["English", "Mandarin", "Cantonese"]}
				field={getSubField(formik, "language")}
				label="Language"
			/>
			<TextField field={getSubField(formik, "school")} label="School" />
			<AutoCompleteTextField
				options={["Friend", "Google", "Facebook", "Wechat"]}
				field={getSubField(formik, "referrer")}
				label="Referrer"
			/>
			<TextField field={getSubField(formik, "parent_name")} label="Parent name" />
			<SelectField
				field={getSubField(formik, "residency")}
				label="Residency"
				options={[
					{ value: "UNKNOWN", label: "Unknown" },
					{ value: "CITIZEN", label: "Citizen" },
					{ value: "PERMANENT_RESIDENT", label: "Permanent resident" },
					{ value: "VISITOR", label: "International student" },
				]}
			/>
			<DateField field={getSubField(formik, "birth_date")} label="Birthdate" />
			<BooleanField field={getSubField(formik, "email_optout")} label="Opt out from group email" />
		</FormLoader>
	);
}

export function StudentData(props: {
	studentData: ReturnPromiseType<typeof API.Student.Get>;
	auth: AuthContextLoggedIn;
	branches: ReturnPromiseType<typeof API.Branch.GetAll>;
	onRefreshData?: () => Promise<void> | void;
	onDeleted?: () => Promise<void> | void;
}) {
	const query = useQuery();
	const location = useLocation();
	const [selectedBranch, setSelectedBranch] = useState<string>();
	const [referMessage, setReferMessage] = useState<string>("");
	const [ticketFilter, setTicketFilter] = useState<TicketType | "">("");

	const enrollmentUuid = query.get("enrollment");
	const paymentUuid = query.get("payment");
	const mail = query.get("mail");
	const mailTemplate = query.get("mail_template");
	const mailPaymentAttach = query.get("mail_payment_attach");
	const mailReportCardAttach = query.get("mail_report_card_attach");
	const mailCloseTicket = query.get("mail_close_ticket");
	const sms = query.get("sms");
	const smsTemplate = query.get("sms_template");
	const smsCloseTicket = query.get("sms_close_ticket");

	const [tickets, ticketsR] = usePromiseExtended(
		useCallback(async () => {
			return await API.Ticket.GetAll(props.auth, {
				student_uuid: props.studentData.uuid,
				filter_type: ticketFilter === "" ? undefined : ticketFilter,
			});
		}, [props.auth, props.studentData, ticketFilter]),
	);

	const [enrollments] = usePromiseExtended(
		useCallback(async () => {
			return await API.Student.GetEnrollments(props.auth, {
				branch_uuid: props.auth.currentBranch.uuid,
				uuid: props.studentData.uuid,
			});
		}, [props.auth, props.studentData]),
	);

	const [referringStudent, setReferringStudent] = useState(false);

	const history = useHistory();
	const modals = useModals();

	return (
		<div className="content-flex-horizontal">
			<div className="subcontent-flex-vertical" style={{ width: 320, flexShrink: 0 }}>
				<Button
					onClick={() => {
						history.push(`/new-enrollment?student=${props.studentData.uuid}`);
					}}
					type="primary"
				>
					Create new enrollment
				</Button>
				{(props.studentData.branch_infos?.credits ?? 0) > 0 && (
					<div>{props.studentData.branch_infos?.credits} credits</div>
				)}
				{enrollments && (
					<List
						style={{ overflow: "auto" }}
						itemLayout="vertical"
						size="large"
						dataSource={enrollments}
						renderItem={item => {
							const start = startOfDay(new Date(item.start_datetime));
							const end = endOfDay(new Date(item.end_datetime));
							const today = new Date();
							const ongoing = today > start && today < end;

							return (
								<List.Item
									actions={[
										<span
											key="view"
											onClick={() => {
												history.push({
													pathname: location.pathname,
													search: `?enrollment=${item.uuid}`,
												});
											}}
											style={{ cursor: "pointer" }}
										>
											View enrollment
										</span>,
										<span
											key="payment"
											style={{
												color: item.payment === null ? "red" : undefined,
												cursor: "pointer",
											}}
											onClick={e => {
												e.stopPropagation();
												e.preventDefault();

												if (item.payment === null) {
													modals.payment({
														auth: props.auth,
														params: {
															quantity: item.student_sessions.filter(x => !x.is_cancelled)
																.length,
															unitPrice: item.section.course.session_price,
															itemName: `Payment for ${item.section.course.name}`,
															student_uuid: props.studentData.uuid,
															branch_uuid: item.section.classroom.branch.uuid,
															enrollment_uuid: item.uuid,
															creditUnitValue:
																Math.round(item.section.session_duration / 30) / 2,
														},
														onCreated: async () => {
															await props.onRefreshData?.();
														},
													});
												} else {
													if (item.payment === null) return;

													history.push({
														pathname: location.pathname,
														search: `?payment=${item.payment.uuid}`,
													});
												}
											}}
										>
											{item.payment === null ? "Make payment" : "View payment"}
										</span>,
									]}
									key={item.uuid}
								>
									<div
										style={{
											backgroundColor: ongoing ? "lightgreen" : today > end ? undefined : "khaki",
											paddingLeft: 16,
										}}
									>
										<div
											style={{
												backgroundColor: "white",
												paddingLeft: 16,
												height: "100%",
												width: "100%",
											}}
										>
											<List.Item.Meta
												title={
													(props.studentData.branch.uuid !== props.auth.currentBranch.uuid
														? `${item.branch.name} - `
														: "") +
													(item.section.vip ? "(V) " : "") +
													item.section.course.name
												}
												description={
													<div>
														{item.section.regular_time_descriptor}
														<br />
														{ongoing
															? "In progress"
															: today > end
															? "Completed"
															: "Not yet started"}
														{` ${new Date(
															item.start_datetime,
														).toLocaleDateString()} to ${new Date(
															item.end_datetime,
														).toLocaleDateString()}`}
													</div>
												}
											/>
										</div>
									</div>
								</List.Item>
							);
						}}
					/>
				)}
			</div>
			{tickets && (
				<div className="subcontent-flex-vertical" style={{ width: 280, height: "100%" }}>
					<Button
						type="primary"
						onClick={() => {
							modals.createTicket({
								initialType: "message",
								auth: props.auth,
								student_uuid: props.studentData.uuid,
								async onCreated() {
									await ticketsR.refresh();
								},
							});
						}}
					>
						Create internal message
						<DownOutlined />
					</Button>
					<Button
						type="primary"
						onClick={() => {
							modals.createTicket({
								initialType: "record",
								initialTitle: "Parent-teacher meeting",
								initialText: `## Working Habits Review

Seeking help:

Taking challenges:

Time management:

Teamwork:

## Exam Review

Design:

Coding:

Communication:

## Planning

Interests, values, and passions:

Course plan:

Contest plan:

Camp plan:`,
								auth: props.auth,
								student_uuid: props.studentData.uuid,
								async onCreated() {
									await ticketsR.refresh();
								},
							});
						}}
					>
						Parent-teacher meeting
					</Button>
					<div>
						Filter messages
						<Select
							style={{ width: "100%" }}
							options={[
								{ value: "", label: "All" },
								{ value: "note", label: "Note" },
								{ value: "task", label: "Task" },
								{ value: "message", label: "Message" },
								{ value: "record", label: "Record" },
							]}
							value={ticketFilter as string}
							onChange={v => {
								setTicketFilter(v as TicketType | "");
							}}
						/>
					</div>
					<TicketsList
						showCourseName
						auth={props.auth}
						tickets={tickets}
						onTicketDismissed={async () => {
							await ticketsR.refresh();
						}}
					/>
				</div>
			)}
			<div className="subcontent-flex-vertical">
				<div>
					<Button
						type="primary"
						onClick={() => {
							history.push({
								pathname: location.pathname,
								search: `?mail=1`,
							});
						}}
					>
						Send mail
					</Button>
				</div>
				{props.studentData.branch?.uuid === props.auth.currentBranch.uuid && (
					<div>
						<Button onClick={() => setReferringStudent(true)}>Refer this student</Button>
					</div>
				)}
				<div>
					<Button
						type="primary"
						onClick={async () => {
							if (props.studentData.deleted === null) {
								Modal.confirm({
									content: "Are you sure?",
									async onOk() {
										await API.Student.Delete(props.auth, { uuid: props.studentData.uuid });
										history.push("/students");
										await props.onDeleted?.();
									},
								});
							} else {
								await API.Student.Undelete(props.auth, { uuid: props.studentData.uuid });
							}
						}}
					>
						{props.studentData.deleted === null ? <>Delete this student</> : <>Undo delete</>}
					</Button>
				</div>
			</div>

			<Drawer
				title="Enrollment information"
				placement="right"
				onClose={() => {
					history.push({ pathname: location.pathname, search: "" });
				}}
				open={enrollmentUuid !== null}
			>
				{enrollmentUuid !== null && (
					<EnrollmentData
						uuid={enrollmentUuid}
						auth={props.auth}
						linkToSectionPage
						onDelete={async () => {
							history.push({
								pathname: location.pathname,
								search: "",
							});
							await props.onRefreshData?.();
						}}
						onRefresh={async () => {
							await props.onRefreshData?.();
						}}
					/>
				)}
			</Drawer>

			<Drawer
				title="Payment information"
				placement="right"
				width={600}
				onClose={() => {
					history.push({ pathname: location.pathname, search: "" });
				}}
				open={paymentUuid !== null}
			>
				{paymentUuid !== null && <PaymentData uuid={paymentUuid} auth={props.auth} />}
			</Drawer>

			<Drawer
				title="Send email"
				placement="right"
				onClose={() => {
					history.push({ pathname: location.pathname, search: "" });
				}}
				open={mail !== null}
				width={600}
			>
				<MailForm
					key={mailTemplate ?? undefined}
					studentUuid={props.studentData.uuid}
					initialTemplate={mailTemplate ?? ""}
					auth={props.auth}
					invoice={mailPaymentAttach !== null ? { payment_uuid: mailPaymentAttach } : undefined}
					report_card={mailReportCardAttach !== null ? { enrollment_uuid: mailReportCardAttach } : undefined}
					onSent={async () => {
						if (mailCloseTicket !== null) {
							await API.Ticket.UpdateStatus(props.auth, {
								uuid: mailCloseTicket,
								closed: true,
							});
						}

						history.push({ pathname: location.pathname, search: "" });
					}}
				/>
			</Drawer>

			<Drawer
				title="Send SMS (DEBUG MODE)"
				placement="right"
				onClose={() => {
					history.push({ pathname: location.pathname, search: "" });
				}}
				open={sms !== null}
				width={600}
			>
				<div>Note: Send SMS feature is still under development</div>
				<SMSForm
					auth={props.auth}
					student_uuid={props.studentData.uuid}
					initialTemplate={smsTemplate ?? undefined}
					onSent={async () => {
						if (smsCloseTicket !== null) {
							await API.Ticket.UpdateStatus(props.auth, {
								uuid: smsCloseTicket,
								closed: true,
							});
						}
						history.push({ pathname: location.pathname, search: "" });
					}}
				/>
			</Drawer>

			<Modal
				destroyOnClose
				open={referringStudent}
				onCancel={() => setReferringStudent(false)}
				okButtonProps={{ disabled: selectedBranch === undefined }}
				onOk={async () => {
					if (selectedBranch === undefined) return;
					await API.Student.Update(props.auth, {
						uuid: props.studentData.uuid,
						dispatch_branch_uuid: selectedBranch,
					});
					await API.Ticket.Create(props.auth, {
						branch_uuid: selectedBranch,
						recipient_roles: ["reception"],
						recipients: [],
						student_uuid: props.studentData.uuid,
						type: "message",
						date: new Date().toISOString(),
						title: "Student referred",
						text: `Branch ${props.auth.currentBranch.name} has referred this student with the following message: ${referMessage}`,
					});

					setReferringStudent(false);
					setSelectedBranch(undefined);
					setReferMessage("");
				}}
			>
				Refer this student to another branch
				<div>
					<Select
						style={{ width: 300 }}
						value={selectedBranch}
						options={props.branches
							.filter(x => x.uuid !== props.auth.currentBranch.uuid)
							.map(x => ({ value: x.uuid, label: x.name }))}
						onChange={x => setSelectedBranch(x)}
					/>
				</div>
				Include a message
				<Input.TextArea rows={4} value={referMessage} onChange={x => setReferMessage(x.target.value)} />
			</Modal>
		</div>
	);
}
