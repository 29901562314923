import { Button, Col, Row, Select } from "antd";
import React, { useCallback, useState } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn, Authenticated } from "../../auth";
import { usePromise } from "../../util";

export function RemapCoursesPage(props: { auth: AuthContextLoggedIn }) {
	const [error, setError] = useState("");
	const [result, setResult] = useState("");

	const coursesList = usePromise(useCallback(() => API.Course.GetAll(props.auth, {}), [props.auth]));
	const oldCoursesList = usePromise(
		useCallback(
			() => API.Course.GetArchivedCourses(props.auth, { branch_uuid: props.auth.currentBranch.uuid }),
			[props.auth],
		),
	);

	const [selectedOldCourse, setSelectedOldCourse] = useState<string>();
	const [selectedNewCourse, setSelectedNewCourse] = useState<string>();

	return (
		<Authenticated as="admin">
			This page is for when data has been newly imported from RO3. You can use this page to change the old courses
			to the new standardized RO4 courses.
			<br />
			<Row>
				<Col span={8}>
					<h1>Single map</h1>
					{oldCoursesList && coursesList && (
						<>
							<div>Old course</div>
							<Select
								showSearch
								optionFilterProp="label"
								style={{ width: 300 }}
								onChange={x => setSelectedOldCourse(x)}
								value={selectedOldCourse}
								options={oldCoursesList.map(x => {
									return { label: x.name, value: x.uuid };
								})}
							/>
							<div>New course</div>
							<Select
								showSearch
								optionFilterProp="label"
								style={{ width: 300 }}
								onChange={x => setSelectedNewCourse(x)}
								value={selectedNewCourse}
								options={coursesList.map(x => {
									return { label: x.name, value: x.uuid };
								})}
							/>
							<div>
								<Button
									disabled={selectedOldCourse === undefined || selectedNewCourse === undefined}
									onClick={async () => {
										if (selectedOldCourse === undefined || selectedNewCourse === undefined) return;

										setError("");
										setResult("Working... please don't navigate away");
										await API.Branch.RemapCourses(props.auth, {
											uuid: props.auth.currentBranch.uuid,
											map: [[selectedOldCourse, selectedNewCourse]],
											type: "id",
										});
										setResult("Done!");
									}}
								>
									Go
								</Button>
							</div>
						</>
					)}
				</Col>
				<Col span={8}>
					<h1>Batch map</h1>
					<ol>
						<li>
							<a href="/excel_template.xlsx">Download template</a>
						</li>
						<li>Make a list of old and new course names like shown</li>
						<li>Select all of the cells as shown</li>
						<li>Copy the cells</li>
						<li>
							<button
								onClick={async () => {
									if (!coursesList) return;

									const clip = await navigator.clipboard.readText();
									const lines = clip.split("\n");
									const map: [string, string][] = [];
									if (
										lines.length < 1 ||
										lines[0].toLowerCase().trimEnd() !== "old course name\tnew course name"
									) {
										setError(
											"You need to include the first line 'Old Course Name/New Course Name' in the copied area",
										);
										return;
									}
									for (let i = 1; i < lines.length; i++) {
										if (lines[i].trimEnd() === "") continue;

										const split = lines[i].trimEnd().split("\t");
										if (split.length !== 2) {
											setError("Error reading data, was one of the cells empty?");
											return;
										}
										const found = coursesList.find(x => x.name === split[1]);
										if (found === undefined) {
											setError(`Couldn't find course called "${split[1]}"`);
											return;
										}
										if (map.find(x => x[0] === split[0]) !== undefined) {
											setError(`Duplicate input "${split[0]}"`);
											return;
										}
										map.push([split[0], found.uuid]);
									}
									setError("");
									setResult("Working... please don't navigate away");
									await API.Branch.RemapCourses(props.auth, {
										uuid: props.auth.currentBranch.uuid,
										map,
										type: "name",
									});
									alert("Done!");
									setResult("Done!");
								}}
							>
								Click here and then allow the website to access your clipboard. Please don't change the
								page until you get a confirmation.
							</button>
						</li>
					</ol>
					<img src="/excel_example.png" alt="" />
				</Col>
			</Row>
			<div>{result}</div>
			<div style={{ color: "red" }}>{error}</div>
		</Authenticated>
	);
}
