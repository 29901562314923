import { Modal, Select } from "antd";
import { useCallback, useState } from "react";
import { API } from "../../api-types";
import { usePromise } from "../../util";

export function SelectBranchModal(props: {
	title?: string;
	visible: boolean;
	onOk: (uuid: string) => Promise<void>;
	onCancel: () => Promise<void>;
}) {
	const branches = usePromise(useCallback(() => API.Branch.GetAll({}), []));
	const [selected, setSelected] = useState<string>();

	return (
		<Modal
			destroyOnClose
			open={props.visible}
			okButtonProps={{ disabled: selected === undefined }}
			onOk={async () => {
				if (selected === undefined) return;
				await props.onOk(selected);
			}}
			onCancel={props.onCancel}
		>
			<label>{props.title}</label>
			{branches && (
				<Select
					style={{ width: 400 }}
					value={selected}
					options={branches.map(x => ({ label: x.name, value: x.uuid }))}
					onChange={x => setSelected(x)}
				/>
			)}
		</Modal>
	);
}
