import { API } from "../../api-types";
import { ReturnPromiseType, hasValue, sessionFormatter } from "../../util";
import Table from "antd/lib/table/Table";
import { AuthContextLoggedIn } from "../../auth";
import useAsync from "react-use/lib/useAsync";

function reportColRender(s: string) {
	if (s === "true") return "✔️";
	else if (s === "false") return "❌";
	return "";
}

export function AttendanceGrid(props: {
	auth: AuthContextLoggedIn;
	sessionDetails?: ReturnPromiseType<typeof API.Student.Enrollment.GetSessions>;
	enrollmentUuid?: string;
}) {
	const sessionDetailsResult = useAsync(async () => {
		if (props.sessionDetails) {
			return props.sessionDetails;
		}

		if (props.enrollmentUuid) {
			return await API.Student.Enrollment.GetSessions(props.auth, { uuid: props.enrollmentUuid });
		}
		return null;
	}, [props.enrollmentUuid, props.auth]);

	return (
		<>
			{sessionDetailsResult.value && (
				<Table
					pagination={{ pageSize: 12 }}
					size="small"
					rowKey={"uuid"}
					columns={[
						{
							key: "datetime",
							dataIndex: ["section_session", "datetime"],
							title: "Date",
							render: (s: string) => new Date(s).toLocaleString([], sessionFormatter),
						},
						{
							key: "attendance",
							dataIndex: ["report", "attendance"],
							title: "Attendance",
							render: (s: boolean | null) => {
								if (s === true) return "✔️";
								else if (s === false) return "❌";
								return "❔";
							},
						},
						{
							key: "project_name",
							dataIndex: ["report", "data", "project_name"],
							title: "Project description",
						},
						{
							key: "lesson_number",
							dataIndex: ["report", "lesson_number"],
							title: "Lesson number",
						},
						{
							key: "project",
							dataIndex: ["report", "data", "project"],
							title: "Project",
							render: reportColRender,
						},
						{
							key: "homework",
							dataIndex: ["report", "data", "homework"],
							title: "Homework",
							render: reportColRender,
						},
						{
							key: "presentation",
							dataIndex: ["report", "data", "presentation"],
							title: "Presentation",
							render: reportColRender,
						},
						{
							key: "score",
							dataIndex: ["report", "data", "score"],
							title: "Score",
						},
						{
							key: "remark",
							dataIndex: ["report", "data", "remark"],
							title: "Remarks",
						},
					]}
					summary={rows => {
						const reduced = rows.reduce(
							(a, x) => {
								const score: number | null | undefined = (x.report?.data as any)?.score;
								if (hasValue(score)) {
									return [score + a[0], a[1] + 1];
								}
								return a;
							},
							[0, 0],
						);

						return (
							<Table.Summary.Row>
								<Table.Summary.Cell index={0}>Average score</Table.Summary.Cell>
								<Table.Summary.Cell index={1} colSpan={4} />
								<Table.Summary.Cell index={2}>
									{(reduced[0] / reduced[1]).toFixed(1)}
								</Table.Summary.Cell>
								<Table.Summary.Cell index={3} />
							</Table.Summary.Row>
						);
					}}
					dataSource={sessionDetailsResult.value.student_sessions.filter(x => !x.is_cancelled)}
				/>
			)}
		</>
	);
}
