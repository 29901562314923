import { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { API } from "../../api-types";
import { AuthContextLoggedIn, Authenticated } from "../../auth";
import { useQuery } from "../../util";
import { FormLoader, getSubField, SelectField, TextAreaField, useMyFormik } from "../form/form";
import { StudentSearch } from "../misc/student-search";

const schema = Yup.object({
	text: Yup.string().min(3).required(),
});

export function SMSForm(props: {
	student_uuid: string;
	initialTemplate?: string;
	onSent: () => Promise<void>;
	auth: AuthContextLoggedIn;
}) {
	const [sent, setSent] = useState(false);
	const [loadedTemplate, setLoadedTemplate] = useState<{ text: string; template: string } | null>(null);

	useEffect(() => {
		void (async () => {
			if (props.initialTemplate === undefined) {
				setLoadedTemplate(null);
				return;
			}

			const template = await API.Mail.GetSMSTemplate(props.auth, {
				template: props.initialTemplate,
				student_uuid: props.student_uuid,
				branch_uuid: props.auth.currentBranch.uuid,
			});

			setLoadedTemplate({ text: template.text, template: props.initialTemplate });
		})();
	}, [props.auth, props.initialTemplate, props.student_uuid]);

	const formik = useMyFormik({
		schema,
		initialValues: useMemo(
			() =>
				loadedTemplate
					? {
							text: "",
							template: loadedTemplate.template,
					  }
					: {
							text: "",
							template: "",
					  },
			[loadedTemplate],
		),
		onCreate: async values => {
			await API.Mail.SendSMS(props.auth, {
				student_uuid: props.student_uuid,
				body: values.text,
				branch_uuid: props.auth.currentBranch.uuid,
			});
			setSent(true);
			await props.onSent();
		},
	});

	const sfv = formik.setFieldValue;
	const onTemplateChange = useCallback(
		async key => {
			if (key === "") {
				await sfv("text", "");
			} else {
				const template = await API.Mail.GetSMSTemplate(props.auth, {
					template: key,
					student_uuid: props.student_uuid,
					branch_uuid: props.auth.currentBranch.uuid,
				});
				await sfv("text", template.text);
			}
		},
		[props.auth, sfv, props.student_uuid],
	);

	return (
		<FormLoader formik={formik} disabled={sent} saveButtonLabel={!sent ? "Send" : "SMS successfully sent"}>
			<SelectField
				nullable
				disabled={sent}
				field={getSubField(formik, "template")}
				label="Template"
				options={[
					// TODO get template list from server
					{ value: "default", label: "Placeholder template" },
				]}
				onChange={onTemplateChange}
			/>
			<TextAreaField
				field={getSubField(formik, "text")}
				label="Body"
				disabled={sent}
				placeholder="Warning: the message typed here will be lost when you switch students or templates."
			/>
		</FormLoader>
	);
}

export function SMSPage(props: { auth: AuthContextLoggedIn }) {
	const [student, setStudent] = useState<{ uuid: string; name: string }>();
	const query = useQuery();

	const studentid = query.get("student");
	const template = query.get("template");
	const ticket = query.get("ticket");

	useEffect(() => {
		void (async () => {
			if (studentid === null) return;
			setStudent(
				await API.Student.Get(props.auth, { branch_uuid: props.auth.currentBranch.uuid, uuid: studentid }),
			);
		})();
	}, [props.auth, studentid]);

	return (
		<Authenticated as="reception">
			<div className="content-flex-horizontal">
				<div style={{ width: 300 }}>
					<div>Send SMS to student</div>
					<StudentSearch onSelect={s => setStudent(s)} auth={props.auth} />
				</div>
				{student && (
					<div style={{ width: 600 }}>
						<SMSForm
							auth={props.auth}
							student_uuid={student.uuid}
							initialTemplate={template ?? undefined}
							onSent={async () => {
								if (ticket === null) return;

								await API.Ticket.UpdateStatus(props.auth, {
									uuid: ticket,
									closed: true,
								});
							}}
						/>
					</div>
				)}
			</div>
		</Authenticated>
	);
}
