import { useCallback } from "react";
import * as Yup from "yup";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import { strippedStringSchema, unformatMoney, usePromise } from "../../util";
import { DiscountField, getSubField, NumberField, SelectField, TextField, useMyFormik } from "./form";

const schema = Yup.object({
	item_name: strippedStringSchema.required(),
	identifier: strippedStringSchema,
	quantity: Yup.number().integer().min(0).required(),
	unit_price: Yup.number().min(0).required(),
	discount: Yup.number().required(),
	discountType: Yup.string().required(),
	tax_percent: Yup.number().positive().required(),

	// this value is the number of sessions that are paid via credits
	// e.g. redeemCredits might be 1, but 2 credits would be used, if 2 hour long sessions
	redeemCredits: Yup.number().integer().min(0),
	method: Yup.string(),
});

export interface PaymentFormParams {
	unitPrice: string;
	quantity: number;
	itemName: string;
	student_uuid: string;
	branch_uuid: string;
	enrollment_uuid: string;
	creditUnitValue?: number;
}

function calcPrice(values: {
	discountType: "pct" | "flat";
	discount: string;
	unit_price: string;
	quantity: number;
	tax_percent: number;
	redeemCredits: number;
}) {
	const baseprice = (+values.unit_price * (values.quantity - values.redeemCredits)).toFixed(2);
	const surcharge =
		values.discountType === "pct"
			? (-(+values.discount / 100) * +baseprice).toFixed(2)
			: (-+values.discount).toFixed(2);
	const subtotal = (+baseprice + +surcharge).toFixed(2);
	const tax = Math.max(0, +subtotal * (values.tax_percent / 100)).toFixed(2);
	const total = (+subtotal + +tax).toFixed(2);

	return { surcharge, subtotal, tax, total, baseprice };
}

export function usePaymentFormik(params: {
	formParams: PaymentFormParams;
	disableReinitialize?: boolean;
	onCreated: (id: string) => void;
	auth: AuthContextLoggedIn;
}) {
	const creditUnitValue = params.formParams.creditUnitValue ?? 1;

	const formik = useMyFormik({
		schema,
		additionalTests: [
			{
				name: "identifierEmpty",
				test: values => {
					const nonzero = +calcPrice(values).total > 0;
					return nonzero && (values.identifier === undefined || values.identifier === "")
						? { path: "identifier", message: "confirmation number cannot be empty" }
						: undefined;
				},
			},
			{
				name: "methodEmpty",
				test: values => {
					const nonzero = +calcPrice(values).total > 0;
					return nonzero && (values.method === undefined || values.method === "UNKNOWN")
						? { path: "method", message: "method cannot be unknown or empty" }
						: undefined;
				},
			},
			{
				name: "creditsExceeding",
				test: values => {
					return values.redeemCredits * creditUnitValue > credits
						? { path: "redeemCredits", message: "cannot redeem more credits than available" }
						: undefined;
				},
			},
			{
				name: "creditsNegative",
				test: values => {
					return values.redeemCredits * creditUnitValue < 0
						? { path: "redeemCredits", message: "cannot redeem negative credits" }
						: undefined;
				},
			},
		],
		disableReinitialize: params.disableReinitialize,
		initialValues: {
			item_name: params.formParams.itemName,
			unit_price: unformatMoney(params.formParams.unitPrice),
			quantity: params.formParams.quantity,
			discount: "0",
			discountType: "pct" as "pct" | "flat",
			tax_percent: 0,
			method: undefined as string | undefined,
			identifier: "",
			redeemCredits: 0,
		},
		onCreate: async values => {
			const { surcharge, tax, total } = calcPrice(values);

			const result = await API.Branch.Payment.Create(params.auth, {
				item_name: values.item_name,
				method: (values.method ?? "UNKNOWN") as any,
				identifier: values.identifier,
				quantity: values.quantity,
				unit_price: values.unit_price,
				surcharge,
				tax_percent: values.tax_percent,
				tax,
				total,
				student_uuid: params.formParams.student_uuid,
				branch_uuid: params.formParams.branch_uuid,
				enrollment_uuid: params.formParams.enrollment_uuid,
			});

			if (params.formParams.student_uuid !== undefined && values.redeemCredits > 0) {
				const newCredits = await API.Student.ModifyCredit(params.auth, {
					uuid: params.formParams.student_uuid,
					branch_uuid: params.auth.currentBranch.uuid,
					add: -values.redeemCredits * creditUnitValue,
				});

				await API.Ticket.Create(params.auth, {
					title: "Credit redeemed for payment",
					recipient_roles: ["reception"],
					recipients: [],
					text: `${values.redeemCredits * creditUnitValue} credits redeemed. The current balance is ${
						newCredits.credits
					}.`,
					type: "note",
					closed: true,
					branch_uuid: params.auth.currentBranch.uuid,
					payment_uuid: result.uuid,
					student_uuid: params.formParams.student_uuid,
					enrollment_uuid: params.formParams.enrollment_uuid,
				});
			}

			return params.onCreated(result.uuid);
		},
	});
	const sfv = formik.setFieldValue;

	const credits = usePromise(
		useCallback(async () => {
			if (params.formParams.student_uuid === undefined) return 0;
			const got = await API.Student.Get(params.auth, {
				branch_uuid: params.auth.currentBranch.uuid,
				uuid: params.formParams.student_uuid,
			});
			return Math.max(0, got.branch_infos?.credits ?? 0);
		}, [params.formParams.student_uuid, params.auth]),
		0,
	);

	usePromise(
		useCallback(async () => {
			await sfv("redeemCredits", Math.min(Math.floor(credits / creditUnitValue), params.formParams.quantity));
		}, [sfv, params.formParams.quantity, creditUnitValue, credits]),
	);

	usePromise(
		useCallback(async () => {
			const result = await API.Branch.GetTaxPercent({ uuid: params.formParams.branch_uuid });
			await sfv("tax_percent", result.tax_percent);
		}, [params.formParams.branch_uuid, sfv]),
	);

	return { formik, maxCreditRedemption: Math.floor(credits / creditUnitValue) };
}

export function PaymentForm(props: { formik: ReturnType<typeof usePaymentFormik> }) {
	const { maxCreditRedemption, formik } = props.formik;
	const price = calcPrice(formik.values);
	return (
		<>
			<TextField field={getSubField(formik, "item_name")} label="Item Name" />
			<TextField field={getSubField(formik, "unit_price")} label="Unit Price" />
			<NumberField field={getSubField(formik, "quantity")} precision={0} label="Quantity" />
			{maxCreditRedemption > 0 && (
				<NumberField
					field={getSubField(formik, "redeemCredits")}
					precision={0}
					label={`Redeem credits for how many sessions (available ${maxCreditRedemption})`}
					min={0}
					max={maxCreditRedemption}
				/>
			)}
			<DiscountField
				field={getSubField(formik, "discount")}
				discountTypeField={getSubField(formik, "discountType")}
				label="Discount"
			/>
			<NumberField field={getSubField(formik, "tax_percent")} label="Tax percent" />
			<SelectField
				field={getSubField(formik, "method")}
				label="Payment method"
				options={[
					{ value: "UNKNOWN", label: "Unknown" },
					{ value: "CREDIT", label: "Credit" },
					{ value: "DEBIT", label: "Debit" },
					{ value: "CASH", label: "Cash" },
					{ value: "CHECK", label: "Check" },
					{ value: "ETRANSFER", label: "E-Transfer" },
					{ value: "WECHATPAY", label: "WeChat Pay" },
				]}
			/>
			<TextField field={getSubField(formik, "identifier")} label="Confirmation number" />
			<div style={{ marginTop: 10 }}>
				<div>Subtotal: ${price.baseprice}</div>
				<div>Discount: ${price.surcharge}</div>
				<div>Tax: ${price.tax}</div>
				<div style={{ fontWeight: "bolder" }}>Total: ${price.total}</div>
			</div>
		</>
	);
}
