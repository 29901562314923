import { Checkbox, InputNumber, Modal } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import { generateSessions, regularTimeDescriptor, SectionScheduleType, sessionFormatter, usePromise } from "../../util";
import { SectionDateTimeSelector } from "../misc/section-datetime-selector";

export function ExtendEnrollmentModal(props: {
	open: boolean;
	enrollment: {
		uuid: string;
		branch: {
			uuid: string;
		};
		student: {
			uuid: string;
		};
		student_sessions: {
			uuid: string;
			section_session: { uuid: string; datetime: string };
		}[];
		section: {
			uuid: string;
			session_duration: number;
			classroom: { uuid: string };
			schedule_type: SectionScheduleType;
		};
	};
	onSubmitted?: (newDatetime: Date) => Promise<void> | void;
	onCancel?: () => Promise<void> | void;
	auth: AuthContextLoggedIn;
}) {
	const [newDatetime, setNewDatetime] = useState<Date>();
	const [nSessions, setNSessions] = useState(0);
	const [useCredits, setUseCredits] = useState(false);
	const [ignoreHolidays, setIgnoreHolidays] = useState<boolean>(false);

	const studentData = usePromise(
		useCallback(
			async () =>
				await API.Student.Get(props.auth, {
					uuid: props.enrollment.student.uuid,
					branch_uuid: props.auth.currentBranch.uuid,
				}),
			[props.enrollment, props.auth],
		),
	);

	const availableCredits = studentData?.branch_infos?.credits ?? 0;
	const creditUnitValue = Math.round(props.enrollment.section.session_duration / 30) / 2;
	const maxRedemption = Math.floor(availableCredits / creditUnitValue);

	const classroom = usePromise(
		useCallback(
			() => API.Branch.Classroom.Get(props.auth, { uuid: props.enrollment.section.classroom.uuid }),
			[props.auth, props.enrollment.section.classroom.uuid],
		),
	);

	const holidays = usePromise(
		useCallback(
			() =>
				API.Branch.GetFutureHolidays(props.auth, {
					uuid: props.auth.currentBranch.uuid,
				}),
			[props.auth],
		),
	);

	useEffect(() => {
		setNewDatetime(undefined);
	}, [props.enrollment.section.uuid, props.enrollment.uuid, props.open]);

	useEffect(() => {
		setUseCredits(maxRedemption > 0);
		setNSessions(maxRedemption);
	}, [studentData, maxRedemption]);

	const newSessions = useMemo(
		() =>
			newDatetime &&
			holidays &&
			generateSessions(
				newDatetime,
				nSessions,
				props.enrollment.section.session_duration,
				holidays,
				props.enrollment.section.schedule_type,
				{ ignoreHolidays: ignoreHolidays },
			),
		[
			nSessions,
			newDatetime,
			props.enrollment.section.session_duration,
			holidays,
			props.enrollment.section.schedule_type,
			ignoreHolidays,
		],
	);

	return (
		<Modal
			destroyOnClose
			open={props.open}
			okButtonProps={{ disabled: !newDatetime }}
			onCancel={async () => {
				await props.onCancel?.();
			}}
			onOk={async () => {
				if (!newDatetime) return;
				if (!newSessions) return;
				if (useCredits && maxRedemption < nSessions) return;

				const updated = await API.Branch.Section.UpdateSessions(props.auth, {
					uuid: props.enrollment.section.uuid,
					create: newSessions.sessions.map(x => ({ datetime: x.datetime, duration: x.duration })),
					regular_time_descriptor: regularTimeDescriptor(newDatetime, props.enrollment.section.schedule_type),
				});

				await API.Student.Enrollment.Update(props.auth, {
					uuid: props.enrollment.uuid,
					sessions: { create: updated.created?.map(x => ({ section_session_uuid: x.uuid })) },
				});

				const ticketText = [
					`New sessions: ${newSessions.sessions
						.map(x => `- ${new Date(x.datetime).toLocaleDateString()}`)
						.join("\n")}`,
				];

				if (useCredits) {
					const newCredits = await API.Student.ModifyCredit(props.auth, {
						uuid: props.enrollment.student.uuid,
						branch_uuid: props.enrollment.branch.uuid,
						add: -nSessions * creditUnitValue,
					});

					ticketText.push(
						`${nSessions * creditUnitValue} credits consumed; current balance: ${newCredits.credits}`,
					);
				}

				await API.Ticket.Create(props.auth, {
					branch_uuid: props.enrollment.branch.uuid,
					enrollment_uuid: props.enrollment.uuid,
					student_uuid: props.enrollment.student.uuid,
					section_uuid: props.enrollment.section.uuid,
					title: "Extended enrollment",
					recipient_roles: ["reception"],
					recipients: [],
					text: ticketText,
					type: "note",
					closed: true,
				});

				await props.onSubmitted?.(newDatetime);
			}}
		>
			<div>
				<div>
					<Checkbox
						disabled={maxRedemption <= 0}
						checked={useCredits}
						onChange={checked => {
							if (checked.target.checked) {
								setNSessions(maxRedemption);
							}
							setUseCredits(checked.target.checked);
						}}
					>
						Redeem credits (max possible: {maxRedemption})
					</Checkbox>
				</div>
				{!useCredits && (
					<div>
						Warning: when using this function without redeeming credits, sessions will be added without
						receiving any payment from the student.
					</div>
				)}
				Please select the number of weeks to extend by:{" "}
				<InputNumber
					precision={0}
					min={1}
					max={useCredits ? maxRedemption : undefined}
					value={nSessions}
					onChange={x => setNSessions(x ?? 1)}
				/>
				{classroom && (
					<div>
						Please select a new start date
						<SectionDateTimeSelector
							auth={props.auth}
							initialDatetime={undefined}
							duration={props.enrollment.section.session_duration}
							sessionCount={nSessions}
							disabled={false}
							classroom={classroom}
							onSelect={datetime => setNewDatetime(datetime)}
						/>
						<Checkbox checked={ignoreHolidays} onChange={e => setIgnoreHolidays(e.target.checked)}>
							Ignore holidays
						</Checkbox>
					</div>
				)}
				{newSessions && (
					<>
						<div>
							The enrollment will be extended to{" "}
							{new Date(newSessions.sessions[newSessions.sessions.length - 1].datetime).toLocaleString(
								[],
								sessionFormatter,
							)}
							.
						</div>
						{newSessions.startDateShifted && (
							<div style={{ color: "red" }}>
								Warning: the start date you selected is on a holiday so it has been shifted further
								back.
							</div>
						)}
					</>
				)}
			</div>
		</Modal>
	);
}
