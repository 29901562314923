import { Button, Input } from "antd";
import { useCallback, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useHistory } from "react-router-dom";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import { usePromiseExtended, sessionFormatter } from "../../util";
import { useModals } from "./modals-provider";

export function TicketReply(params: { children: string }) {
	return (
		<ReactMarkdown
			renderers={{
				link: e => {
					return <a href={e.href} children={e.children} target="_blank" rel="noopener noreferrer" />;
				},
			}}
		>
			{params.children}
		</ReactMarkdown>
	);
}

export function TicketViewerModalInner(props: {
	uuid: string;
	auth: AuthContextLoggedIn;
	onDismiss?: () => Promise<void> | void;
}) {
	const [ticket, ticketR] = usePromiseExtended(
		useCallback(async () => {
			await API.Ticket.View(props.auth, { uuid: props.uuid });
			return await API.Ticket.Get(props.auth, { uuid: props.uuid });
		}, [props.uuid, props.auth]),
	);

	const modals = useModals();

	const [reply, setReply] = useState("");
	const history = useHistory();

	return (
		<>
			{ticket && (
				<div className="subcontent-flex-vertical">
					<div>
						<div style={{ fontSize: "2em" }}>{ticket.title}</div>
						<div style={{ fontSize: "1em", marginLeft: 20 }}>
							{ticket.author && <div>Author: {ticket.author.name}</div>}
						</div>
						<div style={{ fontSize: "1em", marginLeft: 20 }}>
							{new Date(ticket.datetime).toLocaleString([], sessionFormatter)}
						</div>
					</div>
					{ticket.closed !== null && (
						<div style={{ textDecoration: "underline" }}>This message has been solved.</div>
					)}
					<div>
						{ticket.student && (
							<Button
								onClick={async () => {
									if (!ticket.student) return;
									history.push(`/students/${ticket.student.uuid}`);
									await props.onDismiss?.();
								}}
							>
								{ticket.student.name}
							</Button>
						)}
						{ticket.enrollment && (
							<Button
								onClick={async () => {
									if (!ticket.enrollment) return;
									history.push({
										pathname: `/students/${ticket.enrollment.student.uuid}`,
										search: `?enrollment=${ticket.enrollment.uuid}`,
									});
									await props.onDismiss?.();
								}}
							>
								View enrollment
							</Button>
						)}
						{ticket.student_session && (
							<Button
								onClick={async () => {
									if (ticket.student_session === null) return;

									modals.editStudentSessionReport({
										target: {
											uuid: ticket.student_session.uuid,
											auth: props.auth,
											enrollment: ticket.student_session.enrollment,
										},
									});
									await props.onDismiss?.();
								}}
							>
								View session report
							</Button>
						)}
						{ticket.section_session && (
							<>
								<span>
									This message is regarding {ticket.section_session.section.course.name}{" "}
									{ticket.section_session.sequence_number !== null
										? `#${ticket.section_session.sequence_number + 1}`
										: `(makeup)`}
								</span>
							</>
						)}
						{ticket.tasks.length > 0 && (
							<>
								<span>
									This message has an associated task at{" "}
									{new Date(ticket.tasks[0].datetime).toLocaleString([], sessionFormatter)}.
								</span>
							</>
						)}
					</div>
					{ticket.text !== "" && (
						<div>
							<TicketReply>{ticket.text}</TicketReply>
						</div>
					)}
					<div>
						{ticket.replies.map(x => (
							<div key={x.uuid}>
								<div style={{ fontWeight: "bold" }}>
									Reply from {x.author ? x.author.name : "system"}:
								</div>
								<div>
									<TicketReply>{x.text}</TicketReply>
								</div>
							</div>
						))}
					</div>
					<div>
						<span>Write a reply</span>
						<Input.TextArea onChange={e => setReply(e.target.value)} value={reply} rows={4} />
						<Button
							type="primary"
							disabled={reply.trim().length < 2}
							onClick={async () => {
								if (!props.auth.loggedIn) return;
								await API.Ticket.AddReply(props.auth, { uuid: props.uuid, text: reply });
								await ticketR.refresh();
								setReply("");
							}}
						>
							Add reply
						</Button>
					</div>
					<Button
						onClick={async () => {
							if (!props.auth.loggedIn) return;
							await API.Ticket.UpdateStatus(props.auth, {
								uuid: props.uuid,
								closed: ticket.closed === null,
							});
							await props.onDismiss?.();
							// await ticketR.refresh();
						}}
						disabled={ticket.closed !== null}
					>
						Mark issue as solved
					</Button>
				</div>
			)}
		</>
	);
}
