import { Modal } from "antd";
import { useMemo, useState } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import { ReturnPromiseType, sessionFormatter } from "../../util";
import { CourseSelectorTree } from "../misc/course-selector-tree";
import { useModals } from "./modals-provider";

export interface ChangeCourseModalProps {
	target: ReturnPromiseType<typeof API.Student.Enrollment.Get>;
	open: boolean;
	onCancel: () => void;
	onUpdated: () => void;
	auth: AuthContextLoggedIn;
}

export function ChangeCourseModal(props: ChangeCourseModalProps) {
	const [startDatetime, setStartDateTime] = useState<Date>();
	const [newCourse, setNewCourse] = useState<{ uuid: string; name: string }>();
	const modals = useModals();

	const remainingSessions = useMemo(
		() =>
			props.target.student_sessions.filter(cur => {
				if (!startDatetime) return false;
				return new Date(cur.section_session.datetime) >= startDatetime;
			}),
		[startDatetime, props.target],
	);

	return (
		<Modal
			destroyOnClose
			open={props.open}
			okButtonProps={{ disabled: !startDatetime || newCourse === undefined }}
			onOk={async () => {
				if (!props.auth.loggedIn) return;
				if (newCourse === undefined) return;

				const resp = await modals.prompt({
					title: "Please enter a reason for changing the course",
					lengthAtLeast: 4,
				});

				if (resp.action !== "ok") return;

				await API.Student.Enrollment.Update(props.auth, {
					uuid: props.target.uuid,
					session_count: props.target.session_count - remainingSessions.length,
					sessions: { delete: remainingSessions.map(x => x.uuid) },
				});

				const newsection = await API.Branch.Section.Create(props.auth, {
					capacity: props.target.section.capacity,
					classroom_uuid: props.target.section.classroom.uuid,
					course_uuid: newCourse.uuid,
					regular_time_descriptor: props.target.section.regular_time_descriptor,
					session_count: remainingSessions.length,
					session_duration: props.target.section.session_duration,
					vip: props.target.section.vip,
					sessions: remainingSessions.map(x => ({
						datetime: x.section_session.datetime,
						duration: x.section_session.duration,
					})),
					schedule_type: props.target.section.schedule_type,
				});

				const newenr = await API.Student.Enrollment.Create(props.auth, {
					section_uuid: newsection.uuid,
					student_uuid: props.target.student.uuid,
					branch_uuid: props.target.branch.uuid,
				});

				await API.Ticket.Create(props.auth, {
					branch_uuid: props.target.branch.uuid,
					student_uuid: props.target.student.uuid,
					enrollment_uuid: props.target.uuid,
					section_uuid: props.target.section.uuid,
					recipient_roles: ["reception"],
					recipients: [],
					closed: true,
					type: "note",
					title: `Changed course`,
					text: [
						`${remainingSessions.length} sessions cancelled
${remainingSessions.map(x => `- ${new Date(x.section_session.datetime).toLocaleDateString()}`).join("\n")}`,
						`Moved to course ${newCourse.uuid}, enrollment ${newenr.uuid}`,
					],
				});

				if (props.target.payment !== null) {
					const payment = await API.Branch.Payment.Create(props.auth, {
						item_name: newCourse.name,
						method: "UNKNOWN",
						identifier: "",
						quantity: 0,
						unit_price: "0",
						surcharge: "0",
						tax_percent: 0,
						tax: "0",
						total: "0",
						student_uuid: props.target.student.uuid,
						branch_uuid: props.target.branch.uuid,
						enrollment_uuid: newenr.uuid,
					});

					await API.Ticket.Create(props.auth, {
						branch_uuid: props.target.branch.uuid,
						student_uuid: props.target.student.uuid,
						enrollment_uuid: newenr.uuid,
						section_uuid: newsection.uuid,
						payment_uuid: payment.uuid,
						recipient_roles: ["reception"],
						recipients: [],
						closed: true,
						type: "note",
						title: `Payment continued from previous enrollment`,
						text: [`See enrollment ${props.target.uuid}`],
					});
				}

				props.onUpdated();
			}}
			onCancel={() => {
				props.onCancel();
			}}
		>
			Starting from which session do you want to change?
			{props.target.student_sessions.map(x => (
				<div key={x.uuid}>
					<button
						onClick={() => {
							setStartDateTime(new Date(x.section_session.datetime));
						}}
						style={remainingSessions.some(y => y.uuid === x.uuid) ? { background: "pink" } : undefined}
					>
						{new Date(x.section_session.datetime).toLocaleString([], sessionFormatter)}
					</button>
				</div>
			))}
			<div>
				please select a new course to change to
				{startDatetime && (
					<CourseSelectorTree
						value={newCourse?.uuid}
						onChange={x => x && setNewCourse(x)}
						auth={props.auth}
					/>
				)}
			</div>
		</Modal>
	);
}
