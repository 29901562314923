import { Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import { generateSessions, regularTimeDescriptor, SectionScheduleType, sessionFormatter, usePromise } from "../../util";
import { SectionDateTimeSelector } from "../misc/section-datetime-selector";
import { useModals } from "./modals-provider";
import Checkbox from "antd/lib/checkbox/Checkbox";

export function SectionReschedulerModal(props: {
	open: boolean;
	section: {
		uuid: string;
		session_duration: number;
		section_sessions: { datetime: string; duration: number; uuid: string }[];
		classroom: { uuid: string; branch: { uuid: string } };
		schedule_type: SectionScheduleType;
	};
	onSubmitted?: (remainingSessions: { datetime: string; uuid: string }[], newDatetime: Date) => Promise<void> | void;
	onCancel?: () => Promise<void> | void;
	auth: AuthContextLoggedIn;
}) {
	const modals = useModals();
	const [startDatetime, setStartDatetime] = useState<Date>();
	const [remainingSessions, setRemainingSessions] = useState<{ datetime: string; uuid: string }[]>();
	const [newDatetime, setNewDateTime] = useState<Date>();
	const [ignoreHolidays, setIgnoreHolidays] = useState<boolean>(false);

	const classroom = usePromise(
		useCallback(
			() => API.Branch.Classroom.Get(props.auth, { uuid: props.section.classroom.uuid }),
			[props.auth, props.section.classroom.uuid],
		),
	);

	const holidays = usePromise(
		useCallback(
			() =>
				API.Branch.GetFutureHolidays(props.auth, {
					uuid: props.auth.currentBranch.uuid,
				}),
			[props.auth],
		),
	);

	useEffect(() => {
		setStartDatetime(undefined);
		setRemainingSessions(undefined);
		setNewDateTime(undefined);
	}, [props.section, props.open]);

	useEffect(() => {
		if (!startDatetime) return;

		setRemainingSessions(props.section.section_sessions.filter(cur => new Date(cur.datetime) >= startDatetime));
	}, [startDatetime, props.section]);

	const newSessions =
		holidays &&
		newDatetime &&
		remainingSessions &&
		generateSessions(
			newDatetime,
			remainingSessions.length,
			props.section.session_duration,
			holidays,
			props.section.schedule_type,
			{ ignoreHolidays: ignoreHolidays },
		);

	return (
		<Modal
			destroyOnClose
			open={props.open}
			okButtonProps={{ disabled: !(newDatetime && startDatetime) }}
			onCancel={async () => {
				await props.onCancel?.();
			}}
			onOk={async () => {
				if (!newSessions) return;

				const prompt = await modals.prompt({
					lengthAtLeast: 5,
					title: "Please enter a reason for the reschedule.",
				});

				if (prompt.action !== "ok") return;

				const updates = newSessions.sessions.map((x, index) => ({ ...x, uuid: remainingSessions[index].uuid }));

				await API.Branch.Section.UpdateSessions(props.auth, {
					uuid: props.section.uuid,
					update: updates,
					regular_time_descriptor: regularTimeDescriptor(newDatetime, props.section.schedule_type),
				});

				await API.Ticket.Create(props.auth, {
					branch_uuid: props.section.classroom.branch.uuid,
					section_uuid: props.section.uuid,
					title: "Rescheduled section",
					recipient_roles: ["reception"],
					recipients: [],
					text: `Rescheduled section to new time of ${newDatetime.toLocaleString(
						[],
						sessionFormatter,
					)}. Reason: ${prompt.data}`,
					type: "note",
					closed: true,
				});

				await props.onSubmitted?.(remainingSessions, newDatetime);
			}}
		>
			<div>
				Starting from which session do you want to reschedule?
				{props.section.section_sessions.map(x => (
					<div key={x.uuid}>
						<button
							onClick={() => setStartDatetime(new Date(x.datetime))}
							style={{
								backgroundColor:
									startDatetime && new Date(x.datetime) >= startDatetime ? "pink" : undefined,
							}}
						>
							{new Date(x.datetime).toLocaleString([], sessionFormatter)}
						</button>
					</div>
				))}
				{startDatetime && classroom && remainingSessions && (
					<div>
						You have selected {startDatetime.toLocaleString()}. Please select a new start date for the new
						sessions
						<SectionDateTimeSelector
							auth={props.auth}
							initialDatetime={undefined}
							duration={props.section.session_duration}
							sessionCount={remainingSessions.length}
							disabled={false}
							classroom={classroom}
							onSelect={datetime => setNewDateTime(datetime)}
						/>
						<Checkbox checked={ignoreHolidays} onChange={e => setIgnoreHolidays(e.target.checked)}>
							Ignore holidays
						</Checkbox>
					</div>
				)}
				{newDatetime && startDatetime && remainingSessions && (
					<>
						<div>
							The {remainingSessions.length} sessions starting on{" "}
							{startDatetime.toLocaleString([], sessionFormatter)}
							will be moved to {newDatetime.toLocaleString([], sessionFormatter)}.
						</div>
						{newSessions?.startDateShifted && (
							<div style={{ color: "red" }}>
								Warning: the start date you selected is on a holiday so it has been shifted further
								back.
							</div>
						)}
					</>
				)}
			</div>
		</Modal>
	);
}
