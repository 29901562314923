import { useCallback, useEffect, useMemo, useReducer, useState, useRef } from "react";
import { DatePicker } from "antd";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import { convertTime, endOfDay, getTotalMinutes, setTotalMinutes, startOfDay, usePromise } from "../../util";

import moment from "moment";

export function SectionDateTimeSelector(props: {
	duration: number;
	classroom: {
		uuid: string;
		capacity: number;
		hours: { open_time: number; duration: number; day_of_week: number }[];
	};
	sessionCount: number;
	disabled?: boolean;

	auth: AuthContextLoggedIn;
	initialDatetime?: Date;
	onSelect: (value: Date) => void;
}) {
	const [time, setTime] = useState<number | null>(null);
	const [date, setDate] = useState<Date | undefined>(props.initialDatetime);

	const schedule = usePromise(
		useCallback(async () => {
			if (!date) return null;

			return await API.Branch.Schedule.Get(props.auth, {
				branch_uuid: props.auth.currentBranch.uuid,
				start: startOfDay(date).toISOString(),
				end: endOfDay(date).toISOString(),
				classroom_uuid: props.classroom.uuid,
			});
		}, [date, props.classroom.uuid, props.auth]),
	);

	const classroomTimeslots = useMemo(() => {
		if (!date) return null;

		const filtered_hours = props.classroom.hours.filter(x => x.day_of_week === date.getDay());

		const blocks: [start: number, end: number][] = [];

		for (const hours of filtered_hours) {
			for (let i = 0; i + props.duration <= hours.duration; i += 60) {
				blocks.push([hours.open_time + i, hours.open_time + i + props.duration]);
			}
		}

		return blocks;
	}, [date, props.classroom, props.duration]);

	const timeslots = useMemo(() => {
		if (!classroomTimeslots) return null;
		if (!schedule) return null;

		const result = classroomTimeslots.map(([start, end]) => ({
			capacity: props.classroom.capacity,
			count: 0,
			start,
			end,
		}));

		for (const sess of schedule) {
			for (const slot of result) {
				if (
					slot.start <
						getTotalMinutes(new Date(sess.section_session.datetime)) + sess.section_session.duration &&
					slot.end > getTotalMinutes(new Date(sess.section_session.datetime))
				) {
					slot.count += 1;
				}
			}
		}

		return result;
	}, [classroomTimeslots, schedule, props.classroom.capacity]);

	const [, dispatchInitialTimeSelection] = useReducer((state: Date | undefined) => {
		if (timeslots === null) return state;
		if (date === null) return state;

		if (state) {
			const found0 = timeslots.find(x => x.start === getTotalMinutes(state));
			if (found0) setTime(found0.start);
			else {
				const found1 = timeslots.find(x => x.start === getTotalMinutes(state) - 30);
				if (found1) setTime(found1.start);
			}
		} else {
			setTime(null);
		}

		return undefined;
	}, props.initialDatetime);

	const propsRef = useRef(props);
	propsRef.current = props;

	useEffect(() => {
		dispatchInitialTimeSelection();
	}, [props.classroom, date]);

	useEffect(() => {
		if (props.disabled === true) return;
		if (!date) return;
		if (time === null) return;

		propsRef.current.onSelect(setTotalMinutes(startOfDay(date), time));
	}, [props.disabled, time, date]);

	return (
		<div className="subcontent-flex-vertical">
			<div>
				<span>Select a date</span>
				<div>
					<DatePicker
						disabled={props.disabled}
						value={date && moment(date)}
						onChange={e => setDate(e?.toDate() ?? undefined)}
					/>
				</div>
			</div>

			{timeslots && (
				<div>
					<span>Select a time</span>
					<div style={{ maxHeight: 300, overflowY: "auto" }}>
						{timeslots.length > 0
							? timeslots.map(x => (
									<div
										key={x.start}
										className={"hover-list-item" + (x.start === time ? " selected" : "")}
										onClick={() => {
											if (props.disabled === true) return;
											setTime(x.start);
										}}
									>
										{convertTime(x.start).toLocaleTimeString([], { timeStyle: "short" })}
										{" - "}
										{convertTime(x.end).toLocaleTimeString([], { timeStyle: "short" })} {x.count}/
										{x.capacity}
									</div>
							  ))
							: "This classroom has no open hours."}
					</div>
				</div>
			)}
		</div>
	);
}
