import FullCalendar, { EventContentArg, EventInput, startOfDay } from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import { API } from "../../api-types";
import { AuthContextLoggedIn, currentRoleAtLeast } from "../../auth";
import { addMinutes, bind, endOfDay, hasValue, ReturnPromiseType } from "../../util";

export type StudentSessionData = ReturnPromiseType<typeof API.Branch.Schedule.Get>[number];
export type StudentSessionDataWithExtras = StudentSessionData & {
	enrData?: ReturnPromiseType<typeof API.Student.Enrollment.GetRemindersMany>[string];
};

export interface ExtendedPropsTicket {
	type: "ticket";
	ticket: ReturnPromiseType<typeof API.Ticket.GetAll>[number];
}

export interface ExtendedPropsSectionSession {
	type: "section";
	section_session: StudentSessionData["section_session"] & {
		section: StudentSessionData["enrollment"]["section"];
		student_sessions: StudentSessionDataWithExtras[];
	};
}

export interface ExtendedPropsClassroomSession {
	type: "classroom";
	classroom: StudentSessionData["enrollment"]["section"]["classroom"];
	datetime: Date;
	duration: number;
	section_sessions: EventInput[];
}

export interface ExtendedPropsTask {
	type: "task";
	task: ReturnPromiseType<typeof API.Ticket.Task.GetAll>[number];
}

export type ExtendedProps =
	| ExtendedPropsTicket
	| ExtendedPropsSectionSession
	| ExtendedPropsTask
	| ExtendedPropsClassroomSession;

export function isDarkBg(s: string) {
	return (
		parseInt(s.substring(1, 3), 16) + parseInt(s.substring(3, 5), 16) + parseInt(s.substring(5, 7), 16) < 128 * 3
	);
}

function RenderSectionEvent(props: {
	event: { title?: string; extendedProps?: unknown };
	onSectionClick?: (section: ExtendedPropsSectionSession["section_session"], target: MouseEvent) => void;
	onSessionClick?: (session: StudentSessionDataWithExtras, target: MouseEvent) => void;
	auth: AuthContextLoggedIn;
}) {
	const extended = props.event.extendedProps as ExtendedProps | undefined;
	if (!hasValue(extended) || extended.type !== "section") return <></>;

	return (
		<div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
			<div
				className="calendar-event-header"
				onClick={e => {
					props.onSectionClick?.(extended.section_session, e.nativeEvent);
				}}
				data-tip={JSON.stringify({
					type: "section",
					course: extended.section_session.section.course.name,
				})}
				style={{
					backgroundColor: extended.section_session.section.classroom.header_color,
					color: isDarkBg(extended.section_session.section.classroom.header_color) ? "white" : "black",
				}}
			>
				{props.event.title}
				{extended.section_session.is_makeup ? (
					<>
						<br />
						Makeup
					</>
				) : (
					<></>
				)}
			</div>
			<div className="calendar-event-content">
				{extended.section_session.student_sessions.map(x => {
					const status = getSessionStatus(x, props.auth);
					const style = getStyleForSession(status);
					return (
						<div
							className="calendar-event-content-entry"
							data-tip={JSON.stringify({
								type: "session",
								status,
								name: x.enrollment.student.name,
								course: x.enrollment.section.course.name,
							})}
							key={x.uuid}
							onMouseLeave={() => {
								ReactTooltip.hide();
							}}
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();
								props.onSessionClick?.(x, e.nativeEvent);
							}}
							style={style}
						>
							{studentSessionPrefix(status)}
							{x.enrollment.student.name}
						</div>
					);
				})}
			</div>
		</div>
	);
}

export function EventsCalendar(props: {
	tickets: ReturnPromiseType<typeof API.Ticket.GetAll>;
	studentSessions: ReturnPromiseType<typeof API.Branch.Schedule.Get>;
	tasks: ReturnPromiseType<typeof API.Ticket.Task.GetAll>;
	enrollmentData: ReturnPromiseType<typeof API.Student.Enrollment.GetRemindersMany>;
	auth: AuthContextLoggedIn;

	initialDate: string | null;
	classroomUuidSelection: string[];
	groupByClassroom?: boolean;

	onSessionClick: (session: StudentSessionDataWithExtras, target: MouseEvent) => void;
	onTicketClick: (ticket: ExtendedPropsTicket["ticket"], target: MouseEvent) => void;
	onSectionClick: (section: ExtendedPropsSectionSession["section_session"], target: MouseEvent) => void;
	onTaskClick: (section: ExtendedPropsTask["task"], target: MouseEvent) => void;
	onAlldayClick: (datetime: { dateStr: string; date: Date; allDay: boolean }, target: MouseEvent) => void;
	onDatetimeClick: (datetime: { dateStr: string; date: Date; allDay: boolean }, target: MouseEvent) => void;
	onTaskReschedule: (taskId: string, time: { datetime?: Date; duration?: number }) => void;
	onDateRangeChange: (range: [Date, Date]) => void;
}) {
	const ticketEvents = useMemo(
		() =>
			props.tickets.map(x => {
				const status = x.closed !== null ? "closed" : x.viewed ? "viewed" : "open";
				const back = status === "closed" ? "green" : status === "viewed" ? "orange" : "red";
				const text = status === "viewed" ? "black" : undefined;
				const emoji = status === "closed" ? "✔️" : status === "viewed" ? "❔" : "❗";

				return {
					id: `Ticket${x.uuid}`,
					textColor: text,
					backgroundColor: back,
					borderColor: back,
					allDay: true,
					start: new Date(x.datetime),
					title: emoji + (x.student ? `${x.student.name} ` : "") + x.title,
					extendedProps: {
						type: "ticket",
						ticket: x,
					},
					sortOrder: status === "closed" ? 2 : status === "viewed" ? 1 : 0,
				};
			}),
		[props.tickets],
	);

	const sessionEvents: EventInput[] = useMemo(() => {
		const sectionsMap: Record<string, ExtendedPropsSectionSession> = {};

		for (const session of props.studentSessions) {
			if (session.section_session.uuid in sectionsMap) {
				sectionsMap[session.section_session.uuid].section_session.student_sessions.push({
					...session,
					enrData: props.enrollmentData[session.enrollment.uuid],
				});
			} else {
				sectionsMap[session.section_session.uuid] = {
					type: "section",
					section_session: {
						...session.section_session,
						section: session.enrollment.section,
						student_sessions: [{ ...session, enrData: props.enrollmentData[session.enrollment.uuid] }],
					},
				};
			}
		}

		return Object.entries(sectionsMap).map(([k, v]) => ({
			id: `SectionSession${k}`,
			title: `${v.section_session.section.vip ? "V-" : ""}${v.section_session.section.course.short_name}`,
			start: new Date(v.section_session.datetime),
			end: addMinutes(new Date(v.section_session.datetime), v.section_session.duration),
			extendedProps: v,
		}));
	}, [props.enrollmentData, props.studentSessions]);

	const classroomEvents: EventInput[] = useMemo(() => {
		const classroomTimesMap: Record<string, ExtendedPropsClassroomSession> = {};

		for (const session of sessionEvents) {
			const ext = session.extendedProps as ExtendedProps;
			if (ext.type !== "section") continue;

			const key = [
				ext.section_session.section.classroom.uuid,
				new Date(ext.section_session.datetime).toISOString(),
				ext.section_session.duration.toFixed(0),
			].join("|");

			if (key in classroomTimesMap) {
				classroomTimesMap[key].section_sessions.push(session);
			} else {
				classroomTimesMap[key] = {
					type: "classroom",
					classroom: ext.section_session.section.classroom,
					datetime: new Date(ext.section_session.datetime),
					duration: ext.section_session.duration,
					section_sessions: [session],
				};
			}
		}

		return Object.entries(classroomTimesMap).map(([k, v]) => {
			v.section_sessions.sort((x, y) =>
				(x.extendedProps as ExtendedPropsSectionSession).section_session.section.uuid.localeCompare(
					(y.extendedProps as ExtendedPropsSectionSession).section_session.section.uuid,
				),
			);

			return {
				id: `ClassroomSession${k}`,
				title: `${v.classroom.name}`,
				start: v.datetime,
				end: addMinutes(v.datetime, v.duration),
				backgroundColor: "lightblue",
				extendedProps: v,
			};
		});
	}, [sessionEvents]);

	const [viewExpandedClassroomKey, setViewExpandedClassroomKey] = useState<string>();
	const viewExpandedClassroom = useMemo(() => {
		return bind(
			viewExpandedClassroomKey,
			x => classroomEvents.find(y => y.id === x)?.extendedProps as ExtendedPropsClassroomSession,
		);
	}, [viewExpandedClassroomKey, classroomEvents]);

	useEffect(() => {
		if (viewExpandedClassroom !== undefined) ReactTooltip.rebuild();
	});

	const taskEvents: EventInput[] = useMemo(
		() =>
			props.tasks.map(x => {
				return {
					id: `Task${x.uuid}`,
					backgroundColor: "olive",
					borderColor: "olive",
					title: x.ticket.title,
					start: new Date(x.datetime),
					end: addMinutes(new Date(x.datetime), x.duration),
					startEditable: true,
					durationEditable: x.ticket.author?.uuid === props.auth.auth.uuid,
					extendedProps: {
						type: "task",
						task: x,
					},
				};
			}),
		[props.tasks, props.auth],
	);

	const events = useMemo(
		() => [
			...(function* () {
				yield* taskEvents;
				yield* ticketEvents;

				if (props.groupByClassroom) {
					yield* classroomEvents.filter(x =>
						props.classroomUuidSelection.includes(
							(x.extendedProps as ExtendedPropsClassroomSession)?.classroom?.uuid,
						),
					);
				} else {
					yield* sessionEvents.filter(x =>
						props.classroomUuidSelection.includes(
							(x.extendedProps as ExtendedPropsSectionSession)?.section_session?.section?.classroom?.uuid,
						),
					);
				}
			})(),
		],
		[
			taskEvents,
			ticketEvents,
			classroomEvents,
			sessionEvents,
			props.groupByClassroom,
			props.classroomUuidSelection,
		],
	);

	const modalRef = useRef<HTMLDivElement>(null);

	return (
		<>
			<FullCalendar
				plugins={[timeGridPlugin, interactionPlugin]}
				allDayText="Msgs"
				initialView={props.auth.currentBranch.role === "teacher" ? "timeGridDay" : "timeGridWeek"}
				weekends
				nowIndicator
				slotMinTime="9:00:00"
				slotMaxTime="21:00:00"
				slotEventOverlap={props.groupByClassroom !== true}
				scrollTime={new Date().toLocaleTimeString("en-US", { hourCycle: "h24" })}
				height="100%"
				// WARNING: setting this to true will cause maximum depth exceeded somewhere
				expandRows={false}
				windowResizeDelay={500}
				initialDate={bind(props.initialDate, x => startOfDay(new Date(x))) ?? undefined}
				headerToolbar={{
					left: "timeGridWeek,timeGridDay",
					center: "title",
					right: "today,prev,next",
				}}
				eventDrop={e => {
					const extended = e.event.extendedProps as ExtendedProps;
					if (extended.type !== "task") return;
					if (!e.event.start) throw new Error();

					props.onTaskReschedule(extended.task.uuid, {
						datetime: e.event.start,
					});
				}}
				// When dragging up an event while opening the context menu, it will count as a received event.
				eventReceive={e => {
					e.event.remove();
					const extended = e.event.extendedProps as ExtendedProps;
					if (extended.type !== "task") return;
					if (!e.event.start) throw new Error();

					props.onTaskReschedule(extended.task.uuid, {
						datetime: e.event.start,
					});
				}}
				eventResize={e => {
					e.event.remove();
					const extended = e.event.extendedProps as ExtendedProps;
					if (extended.type !== "task") return;
					if (!e.event.start) throw new Error();
					if (!e.event.end) throw new Error();

					props.onTaskReschedule(extended.task.uuid, {
						duration:
							e.event.end.getHours() * 60 +
							e.event.end.getMinutes() -
							e.event.start.getHours() * 60 -
							e.event.start.getMinutes(),
					});
				}}
				eventClick={e => {
					const extended = e.event.extendedProps as ExtendedProps;
					if (extended.type === "ticket") {
						props.onTicketClick(extended.ticket, e.jsEvent);
					} else if (extended.type === "task") {
						props.onTaskClick(extended.task, e.jsEvent);
					}
				}}
				dateClick={e => {
					if (e.allDay) {
						props.onAlldayClick(e, e.jsEvent);
					} else {
						props.onDatetimeClick(e, e.jsEvent);
					}
				}}
				events={events}
				eventOrder={["sortOrder", "title"]}
				eventDidMount={() => {
					ReactTooltip.rebuild();
				}}
				eventContent={({ event }: EventContentArg) => {
					const extended = event.extendedProps as ExtendedProps;

					if (extended.type === "ticket") {
						return <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>{event.title}</div>;
					}

					if (extended.type === "section") {
						return (
							<RenderSectionEvent
								event={event}
								auth={props.auth}
								onSectionClick={props.onSectionClick}
								onSessionClick={props.onSessionClick}
							/>
						);
					}

					if (extended.type === "classroom") {
						const nentries = extended.section_sessions
							.map(
								x =>
									(x.extendedProps as ExtendedPropsSectionSession).section_session.student_sessions
										.length,
							)
							.reduce((x, a) => x + a, 0);

						return (
							<div
								style={{ width: "100%", height: "100%", overflow: "hidden" }}
								onClick={() => {
									ReactTooltip.hide();
									setViewExpandedClassroomKey(event.id);
								}}
							>
								<div
									className="calendar-event-header"
									data-tip={JSON.stringify({
										type: "classroom",
										id: event.id,
									})}
									style={{
										fontSize: 16,
										backgroundColor: extended.classroom.header_color,
										color: isDarkBg(extended.classroom.header_color) ? "white" : "black",
									}}
								>
									{event.title}
								</div>

								<div
									style={{
										padding: 3,
										fontSize: 14,
										display: "flex",
										overflow: "hidden",
										gap: "3px 3px",
										flexWrap: nentries > 3 ? "wrap" : "nowrap",
										flexDirection: nentries > 3 ? "row" : "column",
									}}
								>
									{extended.section_sessions
										.flatMap(
											x =>
												(x.extendedProps as ExtendedPropsSectionSession).section_session
													.student_sessions,
										)
										.map((x, i, a) => {
											const status = getSessionStatus(x, props.auth);
											const style = getStyleForSession(status);

											return (
												<div
													key={x.uuid}
													onClick={e => {
														e.preventDefault();
														e.stopPropagation();
														props.onSessionClick(x, e.nativeEvent);
													}}
													data-tip={JSON.stringify({
														type: "session",
														status,
														name: x.enrollment.student.name,
														course: x.enrollment.section.course.name,
													})}
													onMouseLeave={() => {
														ReactTooltip.hide();
													}}
													style={{
														backgroundColor: "white",
														...style,
														borderRadius: 2,
														width: nentries > 3 ? 21 : undefined,
														height: 21,
														justifyContent: "center",
														textAlign: nentries > 3 ? "center" : undefined,
													}}
												>
													{studentSessionPrefix(status) +
														(nentries > 3 ? "" : x.enrollment.student.name)}
												</div>
											);
										})}
								</div>
							</div>
						);
					}

					if (extended.type === "task") {
						return (
							<div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
								{event.title}
								<br />
								{`assigned to ${extended.task.assignee.name}`}
							</div>
						);
					}
				}}
				datesSet={arg => {
					props.onDateRangeChange([arg.start, arg.end]);
				}}
			/>
			<ReactTooltip
				place={props.groupByClassroom ? (viewExpandedClassroom !== undefined ? "left" : "top") : "left"}
				type="light"
				backgroundColor="none"
				effect="solid"
				padding="0"
				getContent={RenderToolTip({
					classroomEvents,
					auth: props.auth,
				})}
				className="opaque"
			/>
			{viewExpandedClassroom && (
				<>
					<div
						ref={modalRef}
						className="classroom-view-bg"
						onClick={e => {
							if (e.target === modalRef.current) {
								setViewExpandedClassroomKey(undefined);
							}
						}}
					>
						<RenderClassroomContent
							data={viewExpandedClassroom}
							auth={props.auth}
							onSectionClick={props.onSectionClick}
							onSessionClick={props.onSessionClick}
						/>
					</div>
				</>
			)}
		</>
	);
}

function RenderClassroomContent(props: {
	data: ExtendedPropsClassroomSession;
	auth: AuthContextLoggedIn;
	onSectionClick?: (section: ExtendedPropsSectionSession["section_session"], target: MouseEvent) => void;
	onSessionClick?: (session: StudentSessionDataWithExtras, target: MouseEvent) => void;
}) {
	return (
		<div className="classroom-view-modal">
			<div
				style={{
					marginBottom: 20,
				}}
			>
				<div
					style={{
						borderRadius: 4,
						fontSize: 16,
						padding: 12,
						backgroundColor: props.data.classroom.header_color,
						color: isDarkBg(props.data.classroom.header_color) ? "white" : "black",
					}}
				>
					<div>{props.data.classroom.name}</div>
					<div>{props.data.datetime.toLocaleString()}</div>
				</div>
			</div>
			<div className="classroom-view-section-grid">
				{props.data.section_sessions.map(x => (
					<div key={x.id} className="classroom-view-section-item">
						<RenderSectionEvent
							event={x}
							auth={props.auth}
							onSectionClick={props.onSectionClick}
							onSessionClick={props.onSessionClick}
						/>
					</div>
				))}
			</div>
		</div>
	);
}

function ToolTipFrame(props: { children: React.ReactNode }) {
	return (
		<div
			style={{
				padding: 20,
				backgroundColor: "#FDD",
				border: "1px solid black",
				borderRadius: 4,
			}}
		>
			{props.children}
		</div>
	);
}

function RenderToolTip(params: { classroomEvents: EventInput[]; auth: AuthContextLoggedIn }) {
	return (dataTip: string) => {
		const data = JSON.parse(dataTip);

		if (data === undefined || data === null) return <></>;

		if (data.type === "session") {
			return (
				<ToolTipFrame>
					<div style={{ fontWeight: "bold" }}>{data.name}</div>
					<div style={{ fontStyle: "italic" }}>{data.course}</div>
					{data.status.map((x: string, i: number) => (
						<div
							key={i}
							style={{
								...getStyleForSession([x]),
								padding: "2px 5px",
								marginTop: "5px",
								fontWeight: "bold",
							}}
						>
							{mapStatusDescription(x)}
						</div>
					))}
				</ToolTipFrame>
			);
		} else if (data.type === "section") {
			return (
				<ToolTipFrame>
					<label style={{ fontWeight: "bold" }}>{data.course}</label>
				</ToolTipFrame>
			);
		} else if (data.type === "classroom") {
			const event = params.classroomEvents.find(x => x.id === data.id);

			if (event !== undefined) {
				return (
					<RenderClassroomContent
						data={event.extendedProps as ExtendedPropsClassroomSession}
						auth={params.auth}
					/>
				);
			}
		}

		return <></>;
	};
}

const stripedBg =
	"repeating-linear-gradient(45deg, rgba(255,255,255,0.3), rgba(255,255,255,0.3) 20px, rgba(0,0,0,0.1) 20px, rgba(0,0,0,0.1) 40px)";

export function getSessionStatus(x: StudentSessionDataWithExtras, auth: AuthContextLoggedIn) {
	const twoWeeksLater = new Date(x.section_session.datetime);
	twoWeeksLater.setDate(twoWeeksLater.getDate() + 13);

	const status = [];

	if (x.enrData && x.enrData.last_video) {
		status.push("last-video " + new Date(x.enrData.last_video).toLocaleDateString());
	}
	if (x.enrData && x.enrData.last_lesson) {
		status.push(
			"last-lesson " +
				x.enrData.last_lesson.lesson_number +
				" " +
				new Date(x.enrData.last_lesson.datetime).toLocaleDateString(),
		);
	}

	if (x.is_cancelled) status.push("cancel");
	if (currentRoleAtLeast(auth, "reception") && x.enrollment.payment === null) status.push("unpaid");
	if (x.enrollment.section.course.name.toLowerCase().includes("assessment")) status.push("assess");
	if (x.enrData && x.enrData.twoweek_ticket && !x.enrData.twoweek_ticket.closed) status.push("2week");
	if (x.enrData && x.enrData.twoweek_ticket && !x.enrData.twoweek_ticket.closed) status.push("1week");
	if (
		currentRoleAtLeast(auth, "teacher") &&
		x.section_session.sequence_number !== null &&
		(x.section_session.sequence_number + 1) % 3 === 0
	)
		status.push("video");

	if (currentRoleAtLeast(auth, "teacher") && x.section_session.sequence_number === 9) status.push("reportcard");

	if (currentRoleAtLeast(auth, "reception") && x.section_session.sequence_number === 8) status.push("parentmeeting");

	if (x.enrollment.student.branch.uuid !== auth.currentBranch.uuid) status.push("dispatched");
	if (x.report && x.report.attendance !== null) {
		if (x.report.attendance === false) {
			status.push("report-made-no");
		} else if (x.report.attendance === true) {
			status.push("report-made-yes");
		}
	} else if (new Date(x.section_session.datetime).getTime() < endOfDay(new Date()).getTime()) {
		status.push("report-not-made");
	}

	return status;
}

export function getStyleForSession(status: string[]) {
	const style = {
		color: "black",
		backgroundImage: undefined,
		fontStyle: undefined,
		fontWeight: undefined,
	};

	for (const stat of status.slice().reverse()) {
		setStatusStyle(stat, style);
	}

	return style;
}

function studentSessionPrefix(status: string[]) {
	if (status.includes("report-made-yes")) {
		return `✔️`;
	} else if (status.includes("report-made-no")) {
		return `❌`;
	} else if (status.includes("report-not-made")) {
		return `❔`;
	} else return "";
}

function setStatusStyle(
	status: string,
	style: {
		backgroundColor?: string;
		color?: string;
		backgroundImage?: string;
		fontStyle?: string;
		fontWeight?: string;
	},
) {
	if (status === "unpaid") {
		style.backgroundColor = "tomato";
		style.color = "black";
	}
	if (status === "2week" || status === "1week") {
		style.backgroundColor = "#ffe000";
		style.color = "black";
	}
	if (status === "assess") {
		style.backgroundColor = "lightgreen";
		style.color = "black";
	}
	if (status === "video") {
		style.backgroundColor = "navy";
		style.color = "white";
	}
	if (status === "reportcard") {
		style.backgroundColor = "indigo";
		style.color = "white";
	}
	if (status === "parentmeeting") {
		style.backgroundColor = "darkgreen";
		style.color = "white";
	}
	if (status === "cancel") {
		style.backgroundColor = "#888";
		style.color = "black";
	}

	if (status === "dispatched") style.fontWeight = "bolder";
	if (status === "absent") style.backgroundImage = stripedBg;
}

function mapStatusDescription(x: string) {
	if (x.startsWith("last-video")) {
		return `Last video: ${x.split(" ")[1]}`;
	} else if (x.startsWith("last-lesson")) {
		return `Last lesson number: ${x.split(" ")[1]} on ${x.split(" ")[2]}`;
	} else {
		return {
			"2week": "Two-week notice",
			"1week": "One-week notice",
			video: "Video recording session",
			reportcard: "Make report card",
			parentmeeting: "Parent meeting",
			assess: "Assessment",
			cancel: "Cancelled session",
			absent: "Student did not show up",
			unpaid: "Payment not yet received",
			dispatched: "Student referred from other branch",
			"report-made-yes": "✔️ Student attended",
			"report-made-no": "❌ Student did not attend",
			"report-not-made": "❔ No attendance made yet",
		}[x];
	}
}
