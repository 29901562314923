import { useCallback } from "react";
import * as Yup from "yup";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import { ReturnPromiseType, usePromise } from "../../util";
import { BooleanField, ColorField, FormLoader, getSubField, NumberField, TextField, useMyFormik } from "./form";

const schema = Yup.object({
	name: Yup.string().required(),
	header_color: Yup.string().required(),
	capacity: Yup.number().integer().min(0),
	zoom_link: Yup.string(),
	is_online: Yup.boolean(),
});

export function ClassroomForm(props: {
	classroom: ReturnPromiseType<typeof API.Branch.Classroom.Get> | undefined;
	onCreated: (id: string) => void;
	onUpdated: () => void;
	onDeleted: () => void;
	auth: AuthContextLoggedIn;
}) {
	const users = usePromise(
		useCallback(() => API.User.GetAll(props.auth, { branch_uuid: props.auth.currentBranch.uuid }), [props.auth]),
	);

	const formik = useMyFormik({
		id: props.classroom?.uuid,
		schema,
		initialValues: props.classroom
			? {
					name: props.classroom.name,
					capacity: props.classroom.capacity,
					zoom_link: props.classroom.zoom_link,
					header_color: props.classroom.header_color,
					is_online: props.classroom.is_online,
			  }
			: {
					name: "",
					capacity: 1,
					zoom_link: "",
					is_online: false,
					header_color: "#770077",
			  },
		onCreate: async values => {
			const result = await API.Branch.Classroom.Create(props.auth, {
				name: values.name,
				capacity: values.capacity,
				branch_uuid: props.auth.currentBranch.uuid,
				zoom_link: values.zoom_link,
				is_online: values.is_online,
				header_color: values.header_color,
			});
			props.onCreated(result.uuid);
		},
		onUpdate: async (id, values) => {
			await API.Branch.Classroom.Update(props.auth, {
				name: values.name,
				capacity: values.capacity,
				uuid: id,
				zoom_link: values.zoom_link,
				is_online: values.is_online,
				header_color: values.header_color,
			});
			props.onUpdated();
		},
	});

	return users ? (
		<FormLoader
			formik={formik}
			// showDelete={!!props.classroom}
			onDelete={async () => {
				if (!props.classroom) return;
				await API.Branch.Classroom.Delete(props.auth, { uuid: props.classroom.uuid });
				props.onDeleted();
			}}
		>
			<TextField field={getSubField(formik, "name")} label="Name" />
			<NumberField field={getSubField(formik, "capacity")} label="Capacity" />
			<TextField field={getSubField(formik, "zoom_link")} label="Zoom link" />
			<BooleanField field={getSubField(formik, "is_online")} label="Is online?" />
			<ColorField field={getSubField(formik, "header_color")} label="Color" />
		</FormLoader>
	) : null;
}
