import { InputNumber } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState, useEffect, useRef } from "react";
import { ReportResponse } from "../modal/session-report-editor-modal";

const examCategories = [
	{ section: "Quiz", points: 10 },
	{ section: "Engineering/Designing", points: 30 },
	{ section: "Basic Coding", points: 25 },
	{ section: "Advanced Coding", points: 25 },
	{ section: "Communication/Presentation", points: 10 },
];

export function ExamReport(props: {
	active: boolean;
	report: ReportResponse | undefined;
	attendance: boolean | "late" | null;
	onUpdateValues: (valid: boolean, x: Record<string, unknown>) => void;
}) {
	const [sections, setSections] = useState<string[]>([]);
	const [comments, setComments] = useState<string[]>([]);
	const [scores, setScores] = useState<(number | undefined)[]>([]);
	const [scoreMax, setScoreMax] = useState<number[]>([]);

	useEffect(() => {
		const prevExam: [string, string, number, number][] | undefined = (props.report?.data as any)?.exam;

		if (prevExam !== undefined && prevExam instanceof Array) {
			const prevSections: string[] = [];
			const prevComments: string[] = [];
			const prevScores: number[] = [];
			const prevMax: number[] = [];

			prevExam.forEach((x, i) => {
				prevSections.push(x[0]);
				prevComments.push(x[1]);
				prevScores.push(x[2]);
				prevMax.push(x[3]);
			});

			setSections(prevSections);
			setComments(prevComments);
			setScores(prevScores);
			setScoreMax(prevMax);
		} else {
			setSections(examCategories.map(x => x.section));
			setComments(examCategories.map(_ => ""));
			setScores(examCategories.map(_ => undefined));
			setScoreMax(examCategories.map(x => x.points));
		}
	}, [props.report]);

	const propsRef = useRef(props);
	propsRef.current = props;

	useEffect(() => {
		if (propsRef.current.active) {
			propsRef.current.onUpdateValues(true, {
				exam: sections.map((x, i) => [x, comments[i], scores[i] ?? 0, scoreMax[i]]),
			});
		}
	}, [sections, comments, scores, scoreMax]);

	return (
		<>
			{sections.map((x, i) => (
				<div key={i} style={{ marginBottom: 15 }}>
					<div>
						{x + ": "}
						<InputNumber
							min={0}
							max={scoreMax[i]}
							value={scores[i]}
							onChange={e => {
								const newScore = [...scores];
								newScore[i] = e ?? undefined;
								setScores(newScore);
							}}
						/>
						/{scoreMax[i]}
					</div>
					<TextArea
						style={{ width: "100%" }}
						value={comments[i]}
						rows={2}
						onChange={e => {
							const newComments = [...comments];
							newComments[i] = e.target.value;
							setComments(newComments);
						}}
					/>
				</div>
			))}
		</>
	);
}
