import moment, { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn, Authenticated } from "../../auth";
import { formatDOW, formatTime, usePromiseExtended } from "../../util";
import { ClassroomForm } from "../form/classroom-form";
import { CourseSelectorTreeMulti } from "../misc/course-selector-tree";
import { EditClassroomHoursModal } from "../modal/edit-classroom-hours-modal";
import { Button, Modal, Input } from "antd";
import { useModals } from "../modal/modals-provider";
import "./classrooms.css";

export function ClassroomsPage(props: { auth: AuthContextLoggedIn }) {
	const modals = useModals();
	const [classroomsList, classroomsListR] = usePromiseExtended(
		useCallback(
			() =>
				API.Branch.Classroom.GetNames(props.auth, {
					branch_uuid: props.auth.currentBranch.uuid,
				}),
			[props.auth],
		),
	);

	const [selection, setSelection] = useState<string>();
	const [classroom, classroomR] = usePromiseExtended(
		useCallback(async () => {
			if (selection === undefined || selection === "new") return;
			return await API.Branch.Classroom.Get(props.auth, { uuid: selection });
		}, [selection, props.auth]),
	);

	const [curriculumLink, setCurriculumLink] = useState("");
	useEffect(() => {
		setCurriculumLink(classroom?.curriculum_link ?? "");
	}, [classroom]);

	const [courses, coursesR] = usePromiseExtended(
		useCallback(async () => {
			if (classroom !== undefined) {
				return Promise.resolve(classroom.courses.map(x => x.course.uuid));
			} else {
				return Promise.resolve(undefined);
			}
		}, [classroom]),
		undefined,
		useCallback(
			async newValue => {
				if (!newValue) return;
				if (!classroom) return;
				await API.Branch.Classroom.SetCourses(props.auth, {
					uuid: classroom.uuid,
					course_uuids: newValue,
				});
				await classroomR.refresh();
			},
			[classroom, props.auth, classroomR],
		),
	);

	const [hours, hoursR] = usePromiseExtended(
		useCallback(async () => {
			if (classroom !== undefined) {
				return Promise.resolve(classroom.hours);
			} else {
				return Promise.resolve(undefined);
			}
		}, [classroom]),
		undefined,
		useCallback(
			async newValue => {
				if (!newValue) return;
				if (!classroom) return;
				await API.Branch.Classroom.SetHours(props.auth, {
					uuid: classroom.uuid,
					hours: newValue,
				});
				await classroomR.refresh();
			},
			[classroom, props.auth, classroomR],
		),
	);

	const [hoursEditorTarget, setHoursEditorTarget] = useState<{
		index: number;
		dow: number;
		begin: Moment;
		end: Moment;
	}>();

	const modalOnSave = useCallback(
		async (index: number, dow: number, begin: Moment, end: Moment) => {
			if (!hours) return;

			if (
				hours.some(
					(x, i) =>
						i !== index && x.open_time === begin.hours() * 60 + begin.minutes() && x.day_of_week === dow,
				)
			) {
				alert("cannot have two openings with the same start date and time");
				return;
			}

			if (index < 0) {
				await hoursR.update([
					...hours,
					{
						day_of_week: dow,
						open_time: begin.hours() * 60 + begin.minutes(),
						duration: end.hours() * 60 + end.minutes() - begin.hours() * 60 - begin.minutes(),
					},
				]);
			} else {
				const copy = hours.slice();
				copy[index] = {
					day_of_week: dow,
					open_time: begin.hours() * 60 + begin.minutes(),
					duration: end.hours() * 60 + end.minutes() - begin.hours() * 60 - begin.minutes(),
				};
				await hoursR.update(copy);
			}

			setHoursEditorTarget(undefined);
		},
		[hours, hoursR],
	);

	const modalOnDelete = useCallback(
		async index => {
			await hoursR.update(hours ? hours.filter((_, index2) => index !== index2) : undefined);
			setHoursEditorTarget(undefined);
		},
		[hours, hoursR],
	);

	const modalOnCancel = useCallback(() => {
		setHoursEditorTarget(undefined);
	}, []);

	return (
		<Authenticated as="admin">
			<div className="content-flex-horizontal">
				{classroomsList && (
					<div className="subcontent-flex-vertical" style={{ width: 300 }}>
						<div>
							<h3>Classrooms</h3>
							<h3>In-Person</h3>
							{classroomsList
								.filter(x => !x.is_online)
								.map(x => (
									<div
										style={{ justifyContent: "space-between" }}
										key={x.uuid}
										className={
											"hover-list-item hover-parent" +
											(selection !== "new" && selection === x.uuid ? " selected" : "")
										}
										onClick={() => {
											setSelection(x.uuid);
										}}
									>
										{x.name}
										{props.auth.auth.root && (
											<div
												className="show-on-hover"
												onClick={() => {
													modals.selectBranch({
														title: "Select a branch to copy to",
														async onOk(id) {
															await API.Branch.Classroom.CopyToBranch(props.auth, {
																classroom_uuid: x.uuid,
																target_branch_uuid: id,
															});
														},
													});
												}}
												style={{ color: "blue", float: "right" }}
											>
												copy to branch
											</div>
										)}
									</div>
								))}
							<h3>Online</h3>
							{classroomsList
								.filter(x => x.is_online)
								.map(x => (
									<div
										style={{ justifyContent: "space-between" }}
										key={x.uuid}
										className={
											"hover-list-item hover-parent" +
											(selection !== "new" && selection === x.uuid ? " selected" : "")
										}
										onClick={() => {
											setSelection(x.uuid);
										}}
									>
										{x.name}
										{props.auth.auth.root && (
											<div
												className="show-on-hover"
												onClick={() => {
													modals.selectBranch({
														title: "Select a branch to copy to",
														async onOk(id) {
															await API.Branch.Classroom.CopyToBranch(props.auth, {
																classroom_uuid: x.uuid,
																target_branch_uuid: id,
															});
														},
													});
												}}
												style={{ color: "blue", float: "right" }}
											>
												copy to branch
											</div>
										)}
									</div>
								))}
						</div>
						<Button onClick={() => setSelection("new")}>Create new classroom</Button>
					</div>
				)}

				{selection !== undefined && (selection === "new" || (hours !== undefined && courses !== undefined)) && (
					<>
						<div style={{ width: 300 }}>
							<ClassroomForm
								auth={props.auth}
								classroom={selection === "new" ? undefined : classroom}
								onUpdated={async () => {
									await classroomsListR.refresh();
								}}
								onCreated={async id => {
									setSelection(id);
									await classroomsListR.refresh();
								}}
								onDeleted={async () => {
									setSelection(undefined);
									await classroomsListR.refresh();
								}}
							/>
						</div>

						<div className="subcontent-flex-vertical" style={{ width: 600 }}>
							{hours && (
								<>
									<h3>Change hours for this classroom</h3>
									<div>
										{hours.map((x, index) => (
											<div
												key={index}
												className="hover-list-item"
												onClick={() => {
													setHoursEditorTarget({
														index,
														dow: x.day_of_week,
														begin: moment().startOf("day").minute(x.open_time),
														end: moment()
															.startOf("day")
															.minute(x.open_time + x.duration),
													});
												}}
											>
												{`${formatDOW(x.day_of_week)} ${formatTime(x.open_time)} - ${formatTime(
													x.open_time + x.duration,
												)}`}
											</div>
										))}
									</div>
									<Button
										onClick={() =>
											setHoursEditorTarget({
												index: -1,
												dow: 0,
												begin: moment().startOf("day").hour(15),
												end: moment().startOf("day").hour(16),
											})
										}
									>
										Add open time
									</Button>
									<Button
										onClick={() =>
											Modal.confirm({
												content: "Are you sure about deleting all times?",
												async onOk() {
													await hoursR.update([]);
												},
											})
										}
									>
										Clear all times
									</Button>

									<EditClassroomHoursModal
										target={hoursEditorTarget}
										onSave={modalOnSave}
										onDelete={modalOnDelete}
										onCancel={modalOnCancel}
									/>
								</>
							)}
							{courses && (
								<div>
									<h3>Select courses for this classroom</h3>
									<CourseSelectorTreeMulti
										auth={props.auth}
										values={courses}
										disabled={false}
										onChange={async coursesSelection => {
											await coursesR.update(coursesSelection);
										}}
									/>
								</div>
							)}
							<div>
								<h3>Set curriculum link</h3>
								<Input.Group compact>
									<Input
										value={curriculumLink}
										onChange={e => setCurriculumLink(e.target.value)}
										style={{ width: "calc(100% - 80px)" }}
									/>
									<Button
										type="primary"
										style={{ width: 80 }}
										onClick={async () => {
											if (!classroom) return;
											await API.Branch.Classroom.SetCurriculumLink(props.auth, {
												uuid: classroom.uuid,
												link: curriculumLink,
											});
										}}
									>
										Save
									</Button>
								</Input.Group>
							</div>
						</div>
					</>
				)}
			</div>
		</Authenticated>
	);
}
