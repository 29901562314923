import { API } from "../../api-types";
import { usePromise } from "../../util";
import Table from "antd/lib/table/Table";
import { AuthContextLoggedIn } from "../../auth";
import { useCallback, useState, useMemo } from "react";
import Tabs from "antd/lib/tabs";
import { ProgressItems } from "./session-report-progress";
import Popover from "antd/lib/popover";

export function ProgressGrid(props: { auth: AuthContextLoggedIn; student: { uuid: string } }) {
	const entries = usePromise(
		useCallback(async () => {
			return await API.Student.GetProgress(props.auth, {
				uuid: props.student.uuid,
			});
		}, [props.auth, props.student.uuid]),
	);

	const [tab, setTab] = useState<string>();

	const tabs = useMemo(() => {
		if (entries === undefined) return [];
		const ret = Array.from(new Set(entries.map(x => x.category)));
		if (ret.length === 1) setTab(ret[0]);
		return ret;
	}, [entries]);

	const { rows, cols } = useMemo(() => {
		if (entries === undefined || tab === undefined)
			return {
				rows: [],
				cols: [],
			};

		const cat = ProgressItems.find(x => x.value === tab);

		const cols = Array.from(new Set(entries.filter(x => x.category === tab).map(x => x.problem)));
		const rows: Record<string, Record<string, unknown>> = Object.fromEntries(
			cat?.children?.map(x => [
				x.value,
				Object.fromEntries(x.children?.map(y => [y.value, { label: y.label }]) ?? []),
			]) ?? [],
		);

		for (const e of entries.filter(x => x.category === tab)) {
			if (!(e.bank in rows)) {
				rows[e.bank] = {};
			}
			rows[e.bank][e.problem] = {
				...e,
				label:
					cat?.children?.find(x => x.value === e.bank)?.children?.find(x => x.value === e.problem)?.label ??
					e.problem,
			};
		}

		return {
			cols: cols,
			rows: Object.entries(rows)
				.map(([k, v]) => ({
					...v,
					bank: k,
				}))
				.toSorted((a, b) => a.bank.localeCompare(b.bank)),
		};
	}, [tab, entries]);

	const fullCols = Array.from(
		new Set([...cols, ...ProgressItems.find(x => x.value)!.children!.flatMap(x => x.children!.map(y => y.value))]),
	).toSorted();

	return (
		<>
			<Tabs
				activeKey={tab}
				onChange={t => {
					setTab(t);
				}}
				items={tabs.map(t => ({
					label: t,
					key: t,
					children: (
						<Table
							pagination={{ pageSize: 12, position: ["topLeft"] }}
							size="small"
							rowKey={"bank"}
							columns={[
								{ key: "bank", dataIndex: "bank", title: "Bank" },
								...fullCols.map(x => ({
									key: x,
									dataIndex: x,
									title: x,
									render: (obj: {
										label: string;
										passed?: boolean;
										datetime?: string;
										comment?: string;
									}) => (
										<Popover
											content={
												<div>
													<div>{obj.label}</div>
													{obj.datetime && (
														<div>{new Date(obj.datetime).toLocaleDateString()}</div>
													)}
													{obj.comment && <div>{obj.comment}</div>}
												</div>
											}
										>
											<div style={{ width: "100%", height: 20 }}>
												{obj.passed !== undefined && (obj.passed ? "✔️" : "❌")}
											</div>
										</Popover>
									),
								})),
							]}
							dataSource={rows}
						/>
					),
				}))}
			/>
		</>
	);
}
