import { useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContextLoggedIn } from "../../auth";
import { FormLoaderModal } from "../form/form";
import { PaymentForm, PaymentFormParams, usePaymentFormik } from "../form/payment-form";

export interface PaymentCreatorModalProps {
	target:
		| {
				params: PaymentFormParams;
				auth: AuthContextLoggedIn;
		  }
		| undefined;
	onCreated: (id: string) => void;
	onCancel: () => void;
}

function Inner(props: PaymentCreatorModalProps & { target: NonNullable<PaymentCreatorModalProps["target"]> }) {
	const [open, setopen] = useState(true);
	const history = useHistory();

	const formik = usePaymentFormik({
		auth: props.target.auth,
		formParams: props.target.params,
		onCreated: id => {
			props.onCreated(id);

			history.push({
				pathname: `/students/${props.target.params.student_uuid}`,
				search: `?payment=${id}`,
			});
		},
	});

	return (
		<FormLoaderModal
			open={open}
			formik={formik.formik}
			maskClosable={false}
			onCancel={() => {
				setopen(false);
				setTimeout(() => {
					props.onCancel();
				}, 200);

				window.open(
					`/students/${props.target.params.student_uuid}?${new URLSearchParams({
						mail: "1",
						mail_template: "email-invoice-new-en",
					}).toString()}`,
					"_blank",
				);
			}}
			cancelButtonLabel="Pay later"
		>
			<PaymentForm formik={formik} />
		</FormLoaderModal>
	);
}

export function PaymentCreatorModal(props: PaymentCreatorModalProps) {
	if (props.target === undefined) return <></>;
	return <Inner {...props} target={props.target} />;
}
