import { useCallback, useState } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn, Authenticated, currentRoleAtLeast } from "../../auth";
import { Button, Input, Switch } from "antd";
import { usePromiseExtended } from "../../util";
import { CourseForm } from "../form/course-form";
import { CourseSelectorDirectoryTree } from "../misc/course-selector-tree";

export function CoursesPage(props: { auth: AuthContextLoggedIn }) {
	const [showDeleted, setShowDeleted] = useState(false);
	const [courseId, setCourseId] = useState<string>();
	const [coursesList, coursesListR] = usePromiseExtended(
		useCallback(() => API.Course.GetAll(props.auth, { withDeleted: showDeleted }), [props.auth, showDeleted]),
	);

	const [rci, setRci] = useState("");
	const [course, courseR] = usePromiseExtended(
		useCallback(async () => {
			if (courseId === undefined) return;
			if (courseId === "new") return {};
			const a = await API.Course.Get(props.auth, { uuid: courseId });
			setRci(a.report_card_info);
			return a;
		}, [courseId, props.auth]),
	);

	return (
		<Authenticated as="admin">
			<div className="content-flex-horizontal">
				{coursesList && (
					<div
						className="subcontent-flex-vertical"
						style={{ width: 320, height: "calc(100vh - 32px)", overflowY: "auto" }}
					>
						<CourseSelectorDirectoryTree
							value={courseId}
							courses={coursesList}
							onChange={id => {
								setCourseId(id);
							}}
						/>
						{currentRoleAtLeast(props.auth, "root") && (
							<>
								<Button
									type="primary"
									onClick={() => {
										setCourseId("new");
									}}
								>
									Create new course
								</Button>
								<div>
									<Switch checked={showDeleted} onChange={x => setShowDeleted(x)} />
									<span>Show hidden courses</span>
								</div>
							</>
						)}
					</div>
				)}
				{courseId !== undefined && course && (
					<>
						<div style={{ width: 300 }}>
							<CourseForm
								auth={props.auth}
								course={course}
								onCreated={async id => {
									await coursesListR.refresh();
									setCourseId(id);
								}}
								onUpdated={() => coursesListR.refresh()}
							/>
							{courseId !== "new" && currentRoleAtLeast(props.auth, "root") && (
								<Button
									onClick={() => {
										setCourseId("new");
										courseR.set({ ...course, uuid: "" });
									}}
								>
									Duplicate
								</Button>
							)}
						</div>
						<div className="subcontent-flex-vertical" style={{ width: 600 }}>
							<>
								<h3>Report card info</h3>
								<Input.TextArea rows={4} value={rci} onChange={x => setRci(x.target.value)} />
								<Button
									onClick={async () => {
										await API.Course.Update(props.auth, { uuid: courseId, report_card_info: rci });
									}}
								>
									Save
								</Button>
							</>
						</div>
					</>
				)}
			</div>
		</Authenticated>
	);
}
