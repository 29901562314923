import { Button, Checkbox, Collapse, Descriptions, Layout, Modal, Select, Tooltip, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { API } from "../../api-types";
import { AuthContextLoggedIn, Authenticated, currentRoleAtLeast } from "../../auth";
import { usePromiseExtended, useQuery } from "../../util";
import { ClassroomSelector } from "../misc/classroom-selector";
import { CourseSelectorTree } from "../misc/course-selector-tree";
import { EnrollmentData } from "../misc/enrollment-data";
import { SessionListEditor } from "../misc/session-list-editor";

export function SectionPage(props: { auth: AuthContextLoggedIn }) {
	const id = useParams<{ id: string }>();
	const location = useLocation();
	const query = useQuery();
	const history = useHistory();

	const enrollmentId = query.get("enrollment");

	const [sectionData, sectionDataR] = usePromiseExtended(
		useCallback(() => API.Branch.Section.Get(props.auth, { uuid: id.id }), [id.id, props.auth]),
	);

	const [editingCourse, setEditingCourse] = useState(false);
	const [editingClassroom, setEditingClassroom] = useState(false);
	const [editingSessions, setEditingSessions] = useState(false);

	const [courseEditor, setCourseEditor] = useState<string>();
	const [courseEditorVip, setCourseEditorVip] = useState<boolean>(false);
	const [classroomEditor, setClassroomEditor] = useState<string>();

	useEffect(() => {
		setCourseEditor(sectionData?.course?.uuid);
		setCourseEditorVip(sectionData?.vip ?? false);
	}, [sectionData]);

	return (
		<Layout.Content className="main-content">
			<Authenticated as="reception">
				{sectionData && (
					<div className="content-flex-horizontal">
						<div className="subcontent-flex-vertical" style={{ width: 400 }}>
							<Descriptions title="Section Information" bordered column={1} size="small">
								<Descriptions.Item label="Course">{sectionData.course.name}</Descriptions.Item>
								<Descriptions.Item label="Classroom">{sectionData.classroom.name}</Descriptions.Item>
								<Descriptions.Item label="Duration">
									{new Date(sectionData.start_datetime).toLocaleDateString()}-
									{new Date(sectionData.end_datetime).toLocaleDateString()}
								</Descriptions.Item>
								<Descriptions.Item label="Meeting time">
									{sectionData.regular_time_descriptor}
								</Descriptions.Item>
								<Descriptions.Item label="Student capacity">{sectionData.capacity}</Descriptions.Item>
								<Descriptions.Item label="Sessions (including cancelled)">
									{sectionData.section_sessions.length}
								</Descriptions.Item>
							</Descriptions>
							<Collapse>
								<Collapse.Panel key="0" header="Advanced">
									<Button
										disabled={!currentRoleAtLeast(props.auth, "admin")}
										onClick={() => {
											setEditingCourse(true);
										}}
									>
										Edit course
									</Button>
									<Button
										disabled={!currentRoleAtLeast(props.auth, "admin")}
										onClick={() => setEditingClassroom(true)}
									>
										Edit classroom
									</Button>
									<Button
										disabled={!currentRoleAtLeast(props.auth, "admin")}
										onClick={() => setEditingSessions(true)}
									>
										Edit hours
									</Button>
									<Tooltip title="You can only delete the section when no students are enrolled.">
										<Button
											style={{ margin: "auto" }}
											disabled={sectionData.enrollments.length > 0}
											onClick={async () => {
												if (!window.confirm("Confirm delete this section")) return;
												await API.Branch.Section.Delete(props.auth, { uuid: sectionData.uuid });
												history.push("/");
											}}
										>
											Delete section
										</Button>
									</Tooltip>
								</Collapse.Panel>
							</Collapse>

							<Typography.Title level={5}>Select a student to view enrollment</Typography.Title>
							<Select
								style={{ width: 300 }}
								options={sectionData.enrollments.map(x => ({
									label: x.student.name,
									value: x.uuid,
								}))}
								value={enrollmentId}
								onChange={x => {
									history.push({ pathname: location.pathname, search: `?enrollment=${x}` });
								}}
							/>

							<SessionListEditor
								duration={sectionData.session_duration}
								open={editingSessions}
								sessionList={sectionData.section_sessions}
								onCommit={async data => {
									await API.Branch.Section.UpdateSessions(props.auth, {
										uuid: sectionData.uuid,
										create: data.create.map(x => ({
											datetime: x.datetime.toISOString(),
											duration: x.duration,
										})),
										update: data.update.map(x => ({
											datetime: x.datetime.toISOString(),
											duration: x.duration,
											uuid: x.uuid,
										})),
										delete: data.delete,
									});
									await sectionDataR.refresh();
									setEditingSessions(false);
								}}
								onCancel={() => {
									setEditingSessions(false);
								}}
							/>

							<Modal
								destroyOnClose
								open={editingCourse}
								okButtonProps={{ disabled: courseEditor === undefined }}
								onOk={async () => {
									await API.Branch.Section.Update(props.auth, {
										uuid: sectionData.uuid,
										course_uuid: courseEditor,
										vip: courseEditorVip,
									});
									setCourseEditor(undefined);
									setEditingCourse(false);
									await sectionDataR.refresh();
								}}
								onCancel={() => {
									setCourseEditor(undefined);
									setEditingCourse(false);
								}}
							>
								Change the course of this section. Please use this function if you are correcting an
								error in registration. Otherwise, use Modify enrollment{" -> "}Change course. Advanced
								functions will not create a new enrollment and invoice.
								<CourseSelectorTree
									auth={props.auth}
									value={courseEditor}
									onChange={e => setCourseEditor(e?.uuid)}
								/>
								<Checkbox
									onChange={e => {
										setCourseEditorVip(e.target.checked);
									}}
									checked={courseEditorVip}
								>
									VIP
								</Checkbox>
							</Modal>
							<Modal
								destroyOnClose
								open={editingClassroom}
								okButtonProps={{ disabled: classroomEditor === undefined }}
								onCancel={() => {
									setEditingClassroom(false);
									setClassroomEditor(undefined);
								}}
								onOk={async () => {
									if (classroomEditor === undefined) return;

									await API.Branch.Section.Update(props.auth, {
										uuid: sectionData.uuid,
										classroom_uuid: classroomEditor,
									});

									setClassroomEditor(undefined);
									setEditingClassroom(false);
									await sectionDataR.refresh();
								}}
							>
								<ClassroomSelector
									auth={props.auth}
									showAllBranches
									value={classroomEditor}
									onChange={x => setClassroomEditor(x?.uuid)}
									courseId={sectionData.course.uuid}
								/>
							</Modal>
						</div>
						{enrollmentId !== null && (
							<EnrollmentData
								uuid={enrollmentId}
								auth={props.auth}
								onDelete={async () => {
									await sectionDataR.refresh();
								}}
							/>
						)}
					</div>
				)}
			</Authenticated>
		</Layout.Content>
	);
}
