import { ClockCircleOutlined, StarOutlined } from "@ant-design/icons";
import { AutoComplete, Input } from "antd";
import debounce from "debounce";
import { useState } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import { ReturnPromiseType } from "../../util";

export type StudentSearchResult = Parameters<Parameters<typeof StudentSearch>[0]["onSelect"]>[0];

export function StudentSearch(props: {
	onSelect: (x: ReturnPromiseType<typeof API.Student.Search>[number]) => void;
	disabled?: boolean;
	withDeleted?: boolean;
	auth: AuthContextLoggedIn;
}) {
	const [searchResults, setSearchResults] = useState<ReturnPromiseType<typeof API.Student.Search>>([]);

	return (
		<AutoComplete
			filterOption={false}
			options={searchResults.map(result => {
				return {
					item: result,
					label: (
						<div
							key={result.uuid}
							onClick={() => {
								props.onSelect(result);
							}}
						>
							{result.enrollments.some(x => new Date(x.end_datetime) > new Date()) && <StarOutlined />}
							{result.deleted !== null && <ClockCircleOutlined />}
							<span>{result.name}</span>
							<span style={{ float: "right" }}>{result.phone}</span>
						</div>
					),
				};
			})}
			listHeight={640}
			onSearch={debounce(async (e: string) => {
				setSearchResults([]);
				const res = await API.Student.Search(props.auth, {
					branch_uuid: props.auth.currentBranch.uuid,
					query: e,
					take: 50,
					with_deleted: props.withDeleted,
				});
				setSearchResults(res);
			}, 200)}
		>
			<Input.Search
				disabled={props.disabled}
				placeholder="Student name or phone"
				allowClear
				enterButton="Search"
				size="large"
			/>
		</AutoComplete>
	);
}
