/* eslint-disable dot-notation */
import { LeftOutlined } from "@ant-design/icons";
import download from "downloadjs";
import { AutoComplete, Button, Checkbox, Form, Input, List, Modal, Popover } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn, currentRoleAtLeast } from "../../auth";
import { bind, ReturnPromiseType, sessionFormatter } from "../../util";

const defaultEval = {
	dropdown0: "",
	dropdown1: "",
	dropdown2: "",
	dropdown3: "",
	dropdown4: "",
	dropdown5: "",
	check0: [] as string[],
	check1: [] as string[],
	check2: [] as string[],
	comments: "",
};

const dropdownList = [
	{
		section: "Participation",
		values: [
			"STUDENT is beginning to participate in class activities.",
			"STUDENT is developing participation skills.",
			"STUDENT is applying participation skills consistently.",
			"STUDENT is extending participation skills beyond expectations.",
		],
	},
	{
		section: "Planning",
		values: [
			"STUDENT is beginning to acquire planning skills.",
			"STUDENT is developing planning skills.",
			"STUDENT is applying planning skills in class consistently.",
			"STUDENT is extending planning skills strategically.",
		],
	},
	{
		section: "Risk Taking",
		values: [
			"STUDENT is beginning to use risk taking skills.",
			"STUDENT is developing understanding of risk taking.",
			"STUDENT is applying risk taking strategies consistently.",
			"STUDENT is extending risk taking strategically and creatively.",
		],
	},
	{
		section: "Help Seeking",
		values: [
			"STUDENT is beginning to acquire help seeking skills.",
			"STUDENT is developing help seeking skills.",
			"STUDENT is applying help seeking skills consistently.",
			"STUDENT is extending help seeking skills beyond expectations.",
		],
	},
	{
		section: "Focus Span",
		values: [
			"STUDENT is beginning to acquire the ability to focus on tasks.",
			"STUDENT is developing the ability to focus on tasks.",
			"STUDENT is regularly able to focus on tasks.",
			"STUDENT is extending the ability to focus on tasks beyond expectations.",
		],
	},
	{
		section: "Time Management",
		values: [
			"STUDENT is beginning to acquire time management skills.",
			"STUDENT is developing time management skills.",
			"STUDENT is applying time management skills consistently.",
			"STUDENT is extending time management skills beyond expectations",
		],
	},
];
const checkList = [
	{
		section: "Presentation/Communication",
		values: ["Sufficient length", "Sufficient volume", "Effective organization"],
	},
	{
		section: "Troubleshooting/Automation",
		values: ["Engaged", "Iterative", "Effective"],
	},
	{
		section: "Engineering/Design",
		values: ["Detailed", "Creative", "Effective"],
	},
];

export function EnrollmentReportEditorModal(props: {
	target:
		| {
				section: { course: { name: string } };
				uuid: string;
				auth: AuthContextLoggedIn;
				report?: { data: any; updated: string } | undefined | null;
				student: { uuid: string; name: string };
		  }
		| undefined;
	onSave: (uuid: string) => void;
	onCancel: () => void;
}) {
	const [reportEval, setReportEval] = useState(defaultEval);
	const [recommendation, setRecommendation] = useState("");
	const [allReports, setAllReports] = useState<ReturnPromiseType<typeof API.Student.Enrollment.GetStudentReports>>();
	const [canViewReport, setCanViewReport] = useState(false);
	const [downloading, setDownloading] = useState(false);

	useEffect(() => {
		const prevEntries: [string, string][] | undefined = (props.target?.report?.data as any)?.entries;
		if (prevEntries !== undefined && prevEntries instanceof Array) {
			const prevEval = { ...defaultEval };
			const find = (x: string) => {
				return prevEntries.find(y => y[0] === x)?.[1];
			};

			dropdownList.forEach((x, i) => {
				(prevEval as any)[`dropdown${i}`] = (find(x.section) ?? "N/A").trimEnd();
			});
			checkList.forEach((x, i) => {
				(prevEval as any)[`check${i}`] = find(x.section)?.trimEnd()?.split("; ") ?? undefined;
			});

			prevEval.comments = (find("Comments") ?? "").trimEnd();

			setReportEval(prevEval);
		} else {
			setReportEval(defaultEval);
		}

		setRecommendation((props.target?.report?.data as any)?.recommendation ?? "");
		setCanViewReport(props.target?.report !== null);
		setAllReports(undefined);
	}, [props.target]);

	return (
		<Modal
			width={800}
			destroyOnClose
			open={!!props.target}
			okButtonProps={{ disabled: false }}
			onOk={async () => {
				if (!props.target) return;

				const doRest = async () => {
					if (!props.target) return;

					let entries: [string, string][] = [];
					dropdownList.forEach((x, i) => {
						entries.push([x.section, (reportEval as any)[`dropdown${i}`] as string]);
					});
					checkList.forEach((x, i) => {
						const list = (reportEval as any)[`check${i}`] as string[];
						entries.push([x.section, list.length > 0 ? list.join("; ") : "N/A"]);
					});
					entries.push(["Comments", reportEval.comments]);

					await API.Student.Enrollment.SetReport(props.target.auth, {
						uuid: props.target.uuid,
						entries,
						recommendation,
					});

					const twoWeeksLater = new Date();
					twoWeeksLater.setDate(twoWeeksLater.getDate() + 14);

					await API.Ticket.Create(props.target.auth, {
						type: "reminder",
						title: "Term report created",
						branch_uuid: props.target.auth.currentBranch.uuid,
						recipient_roles: ["reception"],
						recipients: [],
						date: twoWeeksLater.toISOString(),
						student_uuid: props.target.student.uuid,
						enrollment_uuid: props.target.uuid,
					});

					props.onSave(props.target.uuid);
				};

				if (props.target.report) {
					Modal.confirm({
						title: "You are about to update this entry. Please confirm.",
						onOk: async () => {
							await doRest();
						},
					});
				} else {
					await doRest();
				}
			}}
			onCancel={() => props.onCancel()}
		>
			<Popover
				placement="left"
				onOpenChange={async e => {
					if (props.target === undefined) return;

					if (e && allReports === undefined) {
						setAllReports(
							await API.Student.Enrollment.GetStudentReports(props.target.auth, {
								uuid: props.target.uuid,
							}),
						);
					}
				}}
				content={
					allReports && (
						<List
							size="small"
							dataSource={allReports
								.filter(x => new Date(x.section_session.datetime) < new Date())
								.slice()
								.reverse()}
							renderItem={item => {
								return (
									<Popover
										placement="left"
										content={<div>{(item.report?.data as any)?.remark ?? "No comments"}</div>}
									>
										<div key={item.uuid} className="hover-list-item" onClick={() => {}}>
											{(item.section_session.sequence_number ?? -1) + 1}.
											{` (Lesson ${item.report?.lesson_number ?? -1}) `}
											{new Date(item.section_session.datetime).toLocaleString(
												[],
												sessionFormatter,
											)}
											{item.is_makeup ? " (Makeup) " : " "}
											{item.is_cancelled ? " (Absent) " : " "}
											{bind(item.report?.attendance, x => (x ? "✔️" : "❌")) ?? ""}
										</div>
									</Popover>
								);
							}}
						/>
					)
				}
			>
				<Button style={{ marginBottom: 15 }}>
					<LeftOutlined />
					View lesson reports
				</Button>
			</Popover>

			{dropdownList.map((x, i) => (
				<Form.Item label={x.section} key={i} style={{ marginBottom: 5 }}>
					<AutoComplete
						value={(reportEval as any)[`dropdown${i}`]}
						options={[
							...x.values.map(y => ({
								value: y.replaceAll(
									"STUDENT",
									props.target?.student?.name?.split(" ")?.[0] ?? "Student",
								),
							})),
							{ value: "N/A" },
						]}
						onChange={data => {
							setReportEval({ ...reportEval, [`dropdown${i}`]: data });
							setCanViewReport(false);
						}}
					/>
				</Form.Item>
			))}
			{checkList.map((x, i) => (
				<Form.Item label={x.section} key={i} style={{ marginBottom: 5 }}>
					<Checkbox.Group
						options={x.values.map(y => ({ label: y, value: y }))}
						value={(reportEval as any)[`check${i}`]}
						onChange={e => {
							setReportEval({ ...reportEval, [`check${i}`]: e as string[] });
							setCanViewReport(false);
						}}
					/>
				</Form.Item>
			))}
			<Form.Item label="Comments">
				<TextArea
					rows={4}
					value={reportEval.comments}
					onChange={e => {
						setCanViewReport(false);
						setReportEval({ ...reportEval, comments: e.target.value });
					}}
				/>
			</Form.Item>
			<Form.Item label="Next program">
				<Input
					value={recommendation}
					onChange={e => {
						setCanViewReport(false);
						setRecommendation(e.target.value);
					}}
				/>
			</Form.Item>

			{props.target?.report != null && (
				<div>Updated at: {new Date(props.target.report.updated).toLocaleString([], sessionFormatter)}</div>
			)}
			{props.target && currentRoleAtLeast(props.target.auth, "reception") && (
				<>
					{!canViewReport && <div>You must save first in order to download or send report card.</div>}
					<Button
						loading={downloading}
						disabled={!canViewReport}
						onClick={async () => {
							if (!props.target) return;
							setDownloading(true);
							const blob = await API.Student.Enrollment.ExportReportCard(props.target.auth, {
								uuid: props.target.uuid,
							});
							download(blob, "report-card.pdf");
							setDownloading(false);
						}}
					>
						Download report card
					</Button>
					<Button
						disabled={!canViewReport}
						onClick={() => {
							if (!props.target) return;
							props.onCancel();

							window.open(
								`/students/${props.target.student.uuid}?${new URLSearchParams({
									mail: "1",
									mail_report_card_attach: props.target.uuid,
									mail_template: "report-en",
									param_course: props.target.section.course.name,
									param_next_program: (props.target.report?.data as any)?.recommendation ?? "",
								}).toString()}`,
								"_blank",
							);
						}}
					>
						Send report card
					</Button>
				</>
			)}
		</Modal>
	);
}
