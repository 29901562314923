import { Modal } from "antd";
import download from "downloadjs";
import React, { Context, createContext, useContext, useMemo, useState } from "react";
import { API } from "../../api-types";
import { AuthContextLoggedIn } from "../../auth";
import { ReturnPromiseType } from "../../util";
import { PaymentFormParams } from "../form/payment-form";
import { SectionSessionEditorModal } from "../misc/section-session-editor";
import { EnrollmentReportEditorModal } from "./enrollment-report-editor-modal";
import { MakeupCreatorModal } from "./makeup-creator-modal";
import { PaymentCreatorModal } from "./payment-creator-modal";
import { PromptModal } from "./prompt-modal";
import { SelectBranchModal } from "./select-branch-modal";
import { SessionReportEditorModal } from "./session-report-editor-modal";
import { TicketCreatorModalInner } from "./ticket-creator-modal";
import { TicketViewerModalInner } from "./ticket-viewer-modal";
import { ManagerSMSModal } from "../misc/manager-sms";
import { DisplayModal } from "./display-modal";

interface OnCancel {
	onCancel?: () => Promise<void> | void;
}
interface OnCreated<T> {
	onCreated?: (value: T) => Promise<void> | void;
}
interface OnUpdated {
	onUpdated?: () => Promise<void> | void;
}
interface OnOk<T> {
	onOk?: (value: T) => Promise<void> | void;
}
interface Auth {
	auth: AuthContextLoggedIn;
}

interface Cancel {
	action: "cancel";
}
interface Ok<T> {
	action: "ok";
	data: T;
}
interface Updated {
	action: "updated";
}
interface Created<T> {
	action: "created";
	data: T;
}

const CancelAction: Cancel = { action: "cancel" };
function OkAction<T>(data: T): Ok<T> {
	return { action: "ok", data };
}
function CreatedAction<T>(data: T): Created<T> {
	return { action: "created", data };
}
const UpdatedAction: Updated = { action: "updated" };

type OmitCallbacks<T extends (...args: never) => unknown> = Omit<Omit<Parameters<T>[0], `on${string}`>, "visible">;

const modalsContext = createContext({
	payment: (_props: OnCancel & OnCreated<string> & Auth & { params: PaymentFormParams }) => {},
	viewTicket: (_props: OnCancel & Auth & { id: string }) => {},
	createTicket: (_props: OnCancel & OnCreated<string> & OmitCallbacks<typeof TicketCreatorModalInner>) => {},
	managerSMS: (_props: OnCancel & OmitCallbacks<typeof ManagerSMSModal>) => {},
	editStudentSessionReport: (_props: OnCancel & OnUpdated & OmitCallbacks<typeof SessionReportEditorModal>) => {},
	editEnrollmentReport: (_props: OnCancel & OnUpdated & OmitCallbacks<typeof EnrollmentReportEditorModal>) => {},
	editSectionSession: (_props: Auth & OnCancel & OnUpdated & OmitCallbacks<typeof SectionSessionEditorModal>) => {},
	createMakeup: (
		_props: OnCancel &
			OnCreated<ReturnPromiseType<typeof API.Student.Enrollment.AddMakeup>> &
			OmitCallbacks<typeof MakeupCreatorModal>,
	) => {},
	selectBranch: (_props: OnOk<string> & OnCancel & OmitCallbacks<typeof SelectBranchModal>) => {},
	prompt(_: OmitCallbacks<typeof PromptModal>): Promise<Cancel | Ok<string>> {
		return Promise.resolve(CancelAction);
	},
	display: (_props: OnCancel & OmitCallbacks<typeof DisplayModal>) => {},
});

export type ModalsContext = typeof modalsContext extends Context<infer U> ? U : never;
type ShowModalParameters<T extends keyof ModalsContext> = Omit<Parameters<ModalsContext[T]>[0], "visible">;

function convertAsync<T extends Record<string, unknown>>(
	setState: React.Dispatch<React.SetStateAction<T | undefined>>,
) {
	return (arg: Omit<T, `on${string}`>) =>
		new Promise<any>(resolve =>
			setState({
				...arg,
				onOk(value: any) {
					resolve(OkAction(value));
				},
				onCancel() {
					resolve(CancelAction);
				},
				onCreated(value: any) {
					resolve(CreatedAction(value));
				},
				onUpdated() {
					resolve(UpdatedAction);
				},
			} as unknown as T),
		);
}

export function ModalsProvider(props: { children: React.ReactNode }) {
	const [paymentProps, setPaymentProps] = useState<ShowModalParameters<"payment">>();
	const [viewTicketProps, setViewTicketProps] = useState<ShowModalParameters<"viewTicket">>();
	const [createTicketProps, setCreateTicketProps] = useState<ShowModalParameters<"createTicket">>();
	const [editSectionSessionProps, setEditSectionSessionProps] = useState<ShowModalParameters<"editSectionSession">>();
	const [editStudentSessionReportProps, setEditStudentSessionReportProps] =
		useState<ShowModalParameters<"editStudentSessionReport">>();
	const [editEnrollmentReportProps, setEditEnrollmentReportProps] =
		useState<ShowModalParameters<"editEnrollmentReport">>();
	const [managerSMSProps, setManagerSMSProps] = useState<ShowModalParameters<"managerSMS">>();
	const [selectBranchProps, setSelectBranchProps] = useState<ShowModalParameters<"selectBranch">>();
	const [createMakeupProps, setCreateMakeupProps] = useState<ShowModalParameters<"createMakeup">>();
	const [promptProps, setPromptProps] = useState<Parameters<typeof PromptModal>[0]>();
	const [displayProps, setDisplayProps] = useState<ShowModalParameters<"display">>();

	return (
		<modalsContext.Provider
			value={useMemo(
				() => ({
					payment: setPaymentProps,
					createTicket: setCreateTicketProps,
					viewTicket: setViewTicketProps,
					editSectionSession: setEditSectionSessionProps,
					editStudentSessionReport: setEditStudentSessionReportProps,
					managerSMS: setManagerSMSProps,
					editEnrollmentReport: setEditEnrollmentReportProps,
					createMakeup: setCreateMakeupProps,
					selectBranch: setSelectBranchProps,
					prompt: convertAsync(setPromptProps),
					display: setDisplayProps,
				}),
				[],
			)}
		>
			{props.children}

			<PaymentCreatorModal
				target={paymentProps && { params: paymentProps.params, auth: paymentProps.auth }}
				onCreated={async (id: string) => {
					if (paymentProps === undefined) return;

					if (paymentProps.params.enrollment_uuid !== undefined) {
						Modal.confirm({
							title: "Download attendance sheet?",
							async onOk() {
								const blob = await API.Student.Enrollment.ExportAttendanceSheet(paymentProps.auth, {
									uuid: paymentProps.params.enrollment_uuid,
								});
								download(blob, "attendance.pdf");

								await paymentProps?.onCreated?.(id);
								setPaymentProps(undefined);
							},
							async onCancel() {
								await paymentProps?.onCreated?.(id);
								setPaymentProps(undefined);
							},
						});
					} else {
						await paymentProps?.onCreated?.(id);
						setPaymentProps(undefined);
					}
				}}
				onCancel={async () => {
					await paymentProps?.onCancel?.();
					setPaymentProps(undefined);
				}}
			/>

			<Modal
				destroyOnClose
				open={!!viewTicketProps}
				footer={<></>}
				onCancel={async () => {
					await viewTicketProps?.onCancel?.();
					setViewTicketProps(undefined);
				}}
			>
				{viewTicketProps && (
					<TicketViewerModalInner
						uuid={viewTicketProps.id}
						auth={viewTicketProps.auth}
						onDismiss={async () => {
							await viewTicketProps?.onCancel?.();
							setViewTicketProps(undefined);
						}}
					/>
				)}
			</Modal>

			<Modal
				destroyOnClose
				open={!!createTicketProps}
				footer={<></>}
				onCancel={async () => {
					setCreateTicketProps(undefined);
					await createTicketProps?.onCancel?.();
				}}
			>
				{createTicketProps && (
					<TicketCreatorModalInner
						{...createTicketProps}
						onCreated={async newid => {
							setCreateTicketProps(undefined);
							await createTicketProps.onCreated?.(newid);
						}}
					/>
				)}
			</Modal>

			<SessionReportEditorModal
				target={editStudentSessionReportProps?.target}
				onSave={async id => {
					if (!editStudentSessionReportProps) return;
					await editStudentSessionReportProps?.onUpdated?.();
					setEditStudentSessionReportProps(undefined);
				}}
				onCancel={async () => {
					await editStudentSessionReportProps?.onCancel?.();
					setEditStudentSessionReportProps(undefined);
				}}
			/>

			<EnrollmentReportEditorModal
				target={editEnrollmentReportProps?.target}
				onSave={async id => {
					if (!editEnrollmentReportProps) return;
					await editEnrollmentReportProps?.onUpdated?.();
					setEditEnrollmentReportProps(undefined);
				}}
				onCancel={async () => {
					await editEnrollmentReportProps?.onCancel?.();
					setEditEnrollmentReportProps(undefined);
				}}
			/>

			<SectionSessionEditorModal
				target={editSectionSessionProps?.target}
				onSave={async (id, values) => {
					if (!editSectionSessionProps) return;

					await API.Branch.Section.Session.SetReport(editSectionSessionProps.auth, {
						uuid: id,
						...values,
					});
					await editSectionSessionProps?.onUpdated?.();
					setEditSectionSessionProps(undefined);
				}}
				onCancel={async () => {
					await editSectionSessionProps?.onCancel?.();
					setEditSectionSessionProps(undefined);
				}}
			/>

			<MakeupCreatorModal
				{...createMakeupProps}
				target={createMakeupProps?.target}
				onCreated={async x => {
					await createMakeupProps?.onCreated?.(x);
					setCreateMakeupProps(undefined);
				}}
				onCancel={async () => {
					await createMakeupProps?.onCancel?.();
					setCreateMakeupProps(undefined);
				}}
			/>

			<SelectBranchModal
				{...selectBranchProps}
				visible={selectBranchProps !== undefined}
				onOk={async (val: string) => {
					await selectBranchProps?.onOk?.(val);
					setSelectBranchProps(undefined);
				}}
				onCancel={async () => {
					await selectBranchProps?.onCancel?.();
					setSelectBranchProps(undefined);
				}}
			/>

			<PromptModal
				{...promptProps}
				visible={promptProps !== undefined}
				onOk={async (val: string) => {
					await promptProps?.onOk?.(val);
					setPromptProps(undefined);
				}}
				onCancel={async () => {
					await promptProps?.onCancel?.();
					setPromptProps(undefined);
				}}
			/>

			<DisplayModal
				{...displayProps}
				visible={displayProps !== undefined}
				onCancel={async () => {
					await promptProps?.onCancel?.();
					setDisplayProps(undefined);
				}}
			/>

			<ManagerSMSModal
				target={managerSMSProps?.target}
				onCancel={async () => {
					await managerSMSProps?.onCancel?.();
					setManagerSMSProps(undefined);
				}}
			/>
		</modalsContext.Provider>
	);
}

export function useModals() {
	return useContext(modalsContext);
}
