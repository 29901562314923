import { LeftOutlined } from "@ant-design/icons";
import { Button, List, Popover } from "antd";
import { AuthContextLoggedIn } from "../../auth";
import { sessionFormatter } from "../../util";
import { useModals } from "../modal/modals-provider";

export function TicketsListPopover(props: Parameters<typeof TicketsList>[0]) {
	const tickets = props.tickets;

	return (
		<Popover placement={"left"} content={tickets && <TicketsList {...props} tickets={tickets} />}>
			<Button type={(tickets?.length ?? 0) === 0 ? "default" : "primary"}>
				<LeftOutlined />
				{tickets?.length ?? 0} notes
			</Button>
		</Popover>
	);
}

export function TicketsList(props: {
	auth: AuthContextLoggedIn;
	showCourseName?: boolean;
	tickets?: {
		uuid: string;
		title: string;
		datetime: string;
		closed: string | null;
		enrollment: { section: { course: { short_name: string } } } | null;
	}[];
	onTicketDismissed: () => Promise<void> | void;
}) {
	const modals = useModals();

	return (
		<>
			{props.tickets && (
				<List
					itemLayout="vertical"
					size="small"
					style={{ overflow: "auto" }}
					dataSource={props.tickets}
					renderItem={item => {
						return (
							<List.Item
								key={item.uuid}
								className="hover-list-item"
								style={{ cursor: "pointer" }}
								onClick={() => {
									modals.viewTicket({
										auth: props.auth,
										id: item.uuid,
										async onCancel() {
											await props.onTicketDismissed();
										},
									});
								}}
							>
								<div>
									{props.showCourseName === true && item.enrollment !== null && (
										<span>{item.enrollment.section.course.short_name}: </span>
									)}
									{item.title}
									{item.closed === null && (
										<span style={{ float: "right", color: "red", fontSize: "0.9em" }}>
											unsolved
										</span>
									)}
								</div>
								<div>{new Date(item.datetime).toLocaleString([], sessionFormatter)}</div>
							</List.Item>
						);
					}}
				/>
			)}
		</>
	);
}
