import Modal from "antd/lib/modal/Modal";
import { AuthContextLoggedIn } from "../../auth";
import Select from "antd/lib/select";
import Input from "antd/lib/input";
import { useEffect, useState } from "react";
import { API } from "../../api-types";
import { sessionFormatter } from "../../util";

export function ManagerSMSModal(props: {
	target:
		| {
				section_session: {
					uuid: string;
					datetime: string;
				};
				enrollment: {
					student: { name: string };
					section: { course: { name: string } };
					branch: { uuid: string };
				};
				auth: AuthContextLoggedIn;
		  }
		| undefined;
	onCancel: () => void;
}) {
	const [text, setText] = useState("");

	useEffect(() => {
		setText("");
	}, [props.target]);

	return (
		<Modal
			title="Send SMS to manager"
			destroyOnClose
			getContainer={false}
			open={!!props.target}
			okButtonProps={{ disabled: text.length < 3 }}
			onOk={async () => {
				if (!props.target) return;
				await API.Mail.SendInternalSMS(props.target.auth, {
					body: text,
					branch_uuid: props.target.enrollment.branch.uuid,
				});
				props.onCancel();
			}}
			onCancel={() => {
				props.onCancel();
			}}
		>
			Template:
			<Select
				style={{ width: "100%" }}
				onChange={value => {
					if (value === "1") {
						setText(
							`Student ${props.target?.enrollment?.student?.name} didn't arrive yet for course ${
								props.target?.enrollment?.section?.course?.name
							} starting at ${new Date(props.target?.section_session?.datetime ?? "").toLocaleTimeString(
								[],
								sessionFormatter,
							)}`,
						);
					}
				}}
				options={[{ value: "1", label: "Student didn't arrive yet" }]}
			/>
			Text:
			<Input.TextArea value={text} rows={4} onChange={e => setText(e.target.value)} />
		</Modal>
	);
}
